import {
  Checkbox,
  DatePicker,
  Divider,
  Input,
  Select,
  Tag,
  Form,
  Upload,
  Button
} from 'antd';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';
import PropTypes from 'prop-types';
import {
  CameraOutlined,
  PlaySquareOutlined,
  UploadOutlined
} from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { formItemLayout } from '../../../utils/constants/formLayout';
import { visibilitiesColors } from '../../../utils/constants/tagColors';
import 'react-quill/dist/quill.snow.css';
import { useErrorMessage } from '../../../utils/errorMessage';
import { draggerProps } from '../TabsContent/News/utils';

const { Dragger } = Upload;

/**
 * React component for creating or updating news items.
 * Offers fields for setting visibility, topic, title, content, and uploading media.
 * Utilizes ReactQuill for rich text editing and Ant Design's Upload for file handling.
 *
 * @component
 * @param {Object} props - Props for the component
 * @param {Object} props.enums - Enumerations for visibility and topic options
 * @param {Object} props.record - Data for initializing the form in edit mode
 * @param {Object} props.form - Form instance for setting field values
 * @param {Object} props.fileList - State for managing file lists for uploads
 * @param {Function} props.setFileList - State updater function for fileList
 * @param {String} props.purpose - Indicates the purpose of the form ('create' or 'edit')
 * @returns {ReactElement} A form component for creating or editing news items, including fields for visibility, topic, and content, as well as file upload capabilities.
 */
export const NewsForm = ({
  enums,
  record,
  form,
  fileList,
  setFileList,
  purpose
}) => {
  const { t } = useTranslation();
  const [editorState, setEditorState] = useState('');
  const { message } = useErrorMessage();

  useEffect(() => {
    if (record?.content) {
      setEditorState(record.content);
    }
  }, []);

  useEffect(() => {
    form.setFieldValue('content', editorState);
  }, [editorState]);

  const visibilityOptions = (enums?.visibility || []).map((enumItem) => ({
    label: (
      <Tag color={visibilitiesColors[enumItem]}>
        {t(`communication.documents.list.type.${enumItem}`)}
      </Tag>
    ),
    value: enumItem
  }));

  const topicOptions = (enums?.topic || []).map((topic) => ({
    label: t(`communication.news.list.type.${topic}`),
    value: topic
  }));

  return (
    <>
      <Divider orientation="left">
        {t('communication.news.modal.handle_publication')}
      </Divider>
      <Form.Item
        name={['start_visibility']}
        label={t('communication.news.modal.start_visibility')}
        rules={[{ required: true }]}
        {...formItemLayout}
      >
        <DatePicker format="DD/MM/YYYY" />
      </Form.Item>
      <Form.Item
        name={['end_visibility']}
        label={t('communication.news.modal.end_visibility')}
        {...formItemLayout}
      >
        <DatePicker format="DD/MM/YYYY" />
      </Form.Item>
      <Form.Item
        name={['visibility']}
        label={t('communication.news.modal.visibility')}
        rules={[{ required: true }]}
        {...formItemLayout}
      >
        <Checkbox.Group options={visibilityOptions} />
      </Form.Item>
      <Form.Item
        name={['topic']}
        label={t('communication.news.modal.topic')}
        rules={[{ required: true }]}
        {...formItemLayout}
      >
        <Select options={topicOptions} />
      </Form.Item>
      <Divider orientation="left">
        {t('communication.news.modal.content')}
      </Divider>
      <Form.Item
        name={['thumbnail']}
        label={t('communication.news.modal.thumbnail')}
        rules={[{ required: true }]}
      >
        <Upload
          {...draggerProps(
            'thumbnail',
            false,
            purpose,
            t,
            fileList,
            setFileList,
            message
          )}
        >
          <Button icon={<UploadOutlined />}>
            {t('communication.news.modal.thumbnail')}
          </Button>
        </Upload>
      </Form.Item>
      <Form.Item
        name={['title']}
        label={t('communication.news.modal.news_title')}
        {...formItemLayout}
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={['content']}
        label={t('communication.news.modal.news_content')}
        rules={[{ required: true }]}
      >
        <ReactQuill
          style={{ width: '100%', height: '100%' }}
          value={editorState}
          theme="snow"
          modules={{
            toolbar: {
              container: [
                ['bold', 'italic', 'underline', 'strike'],
                ['blockquote', 'code-block'],
                [{ list: 'ordered' }, { list: 'bullet' }],
                [{ indent: '-1' }, { indent: '+1' }],
                [{ direction: 'rtl' }],
                [{ size: ['small', false, 'large', 'huge'] }],
                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                [{ color: [] }, { background: [] }],
                [{ font: [] }],
                [{ align: [] }],
                ['clean']
              ]
            }
          }}
          onChange={(value) => setEditorState(value)}
        />
      </Form.Item>
      <Form.Item name={['images']} label={t('communication.news.modal.images')}>
        <Dragger
          {...draggerProps(
            'images',
            true,
            purpose,
            t,
            fileList,
            setFileList,
            message
          )}
        >
          <p className="ant-upload-drag-icon">
            <CameraOutlined style={{ color: 'var(--textColor)' }} />
          </p>
          <p className="ant-upload-text">{t('files.create.action')}</p>
        </Dragger>
      </Form.Item>
      <Form.Item name={['videos']} label={t('communication.news.modal.videos')}>
        <Dragger
          {...draggerProps(
            'videos',
            true,
            purpose,
            t,
            fileList,
            setFileList,
            message
          )}
        >
          <p className="ant-upload-drag-icon">
            <PlaySquareOutlined style={{ color: 'var(--textColor)' }} />
          </p>
          <p className="ant-upload-text">{t('files.create.action')}</p>
        </Dragger>
      </Form.Item>
    </>
  );
};

NewsForm.propTypes = {
  enums: PropTypes.shape({
    visibility: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string)
    ]),
    topic: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string)
    ])
  }),
  record: PropTypes.shape({
    content: PropTypes.string
  }),
  form: PropTypes.shape({
    setFieldValue: PropTypes.func
  }),
  fileList: PropTypes.shape({}),
  setFileList: PropTypes.func,
  purpose: PropTypes.string.isRequired
};

NewsForm.defaultProps = {
  enums: null,
  record: null,
  form: null,
  fileList: null,
  setFileList: null
};
