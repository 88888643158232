import { useTranslation } from 'react-i18next';
import { Select, Radio, Input } from 'antd';

const { Option } = Select;
const { Group } = Radio;
const { TextArea } = Input;

/**
 * Fields for the form
 * @hook
 * @param {Object} enums - The enums from the backend.
 * @returns {Array} The fields to use in the form.
 */
export const useDefaultSettingsFields = (enums) => {
  const { t } = useTranslation();

  const dateParts = ['DD', 'MM', 'YYYY'];

  const generateFormats = (parts, delimiters) => {
    const permutations = [];

    const generatePerms = (arr, prefix = []) => {
      if (prefix.length === parts.length) {
        delimiters.forEach((delimiter) => {
          permutations.push(prefix.join(delimiter));
        });
        return;
      }
      for (let i = 0; i < parts.length; i += 1) {
        if (!prefix.includes(parts[i])) {
          generatePerms(arr, [...prefix, parts[i]]);
        }
      }
    };

    generatePerms(parts);
    return permutations;
  };

  const dateFormatOptions = generateFormats(dateParts, ['-', '/']);
  const selectOptions = dateFormatOptions.map((option) => ({
    label: option,
    value: option
  }));

  return [
    {
      name: 'financial_method',
      label: t('financial_method'),
      input: (
        <Select>
          {enums?.financial_methods?.map((option) => (
            <Option value={option}>
              {t(`configurations.invoicing.form.enums.${option}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: 'bill_by',
      label: t('bill_by'),
      input: (
        <Select>
          {enums?.bill_by?.map((option) => (
            <Option value={option}>
              {t(`configurations.invoicing.form.enums.${option}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: 'contract_smoothing',
      label: t('contract_smoothing'),
      input: (
        <Select>
          {enums?.contract_smoothings?.map((option) => (
            <Option value={option}>
              {t(`configurations.invoicing.form.enums.${option}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: 'when_to_bill',
      label: t('when_to_bill'),
      input: (
        <Group>
          {enums?.when_to_bill?.map((option) => (
            <Radio value={option}>
              {t(`configurations.invoicing.form.enums.${option}`)}
            </Radio>
          ))}
        </Group>
      )
    },
    {
      name: 'invoice_payable_within',
      label: t('invoice_payable_within'),
      input: <Input type="number" addonAfter={t('days')} />
    },
    {
      name: 'invoice_numbering_prefix',
      label: t('invoice_numbering_prefix')
    },
    {
      name: 'invoice_numbering_date_format',
      label: t('invoice_numbering_date_format'),
      input: <Select options={selectOptions} />
    },
    {
      name: 'terms_and_conditions',
      label: t('terms_and_conditions'),
      input: <TextArea style={{ resize: 'none' }} />
    },
    {
      name: 'invoice_footer',
      label: t('invoice_footer'),
      input: <TextArea style={{ resize: 'none' }} />
    },
    {
      name: 'child_name_on_business_invoice',
      label: t('child_name_on_business_invoice'),
      input: (
        <Group>
          <Radio value>{t('yes')}</Radio>
          <Radio value={false}>{t('no')}</Radio>
        </Group>
      )
    }
  ];
};
