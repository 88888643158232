import PropTypes from 'prop-types';
import { PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Flex, Select, Tag, Button } from 'antd';
import { employeesContracts } from '../../utils/constants/tagColors';

const { Option } = Select;

/**
 * Renders extra button components for filtering and creating new entries.
 *
 * This component displays a set of Select dropdowns for filtering employees by contract type, position, and children group, along with a "Create" button
 * that opens a modal for adding a new entry. It leverages the Ant Design `Select` and `Tag` components to offer a user-friendly interface for selecting
 * multiple filters. The component is designed to be used in contexts where users need to filter and add new entries, such as in an employee management
 * system.
 *
 * @component
 *
 * @param {Object} props - Component properties.
 * @param {Function} props.onsearchContracts - Function to handle search/filter for contract types.
 * @param {Function} props.onsearchPositions - Function to handle search/filter for positions.
 * @param {Function} props.onsearchChildrenGroups - Function to handle search/filter for children groups.
 * @param {Function} props.setSetselectedContracts - Function to set selected contract types.
 * @param {Function} props.setSelectedPositions - Function to set selected positions.
 * @param {Function} props.setSelectedChildrenGroups - Function to set selected children groups.
 * @param {Array<string>} props.selectedContracts - Array of selected contract type values.
 * @param {Array<string>} props.selectedPositions - Array of selected position values.
 * @param {Array<string>} props.selectedChildrenGroups - Array of selected children group values.
 * @param {Function} props.onModalOpen - Function to handle opening the modal for creating a new entry.
 * @param {Object} props.enums - Object containing enum values for dropdowns.
 * @param {boolean} props.isLoading - Indicates if the dropdown data is loading.
 * @param {Array<Object>} props.teamPositions - Array of team position objects for the position filter dropdown.
 * @param {Array<Object>} props.childrenGroups - Array of children group objects for the children group filter dropdown.
 * @returns {JSX.Element} The ExtraButtons component.
 */
export const ExtraButtons = ({
  onsearchContracts,
  onsearchPositions,
  onsearchChildrenGroups,
  setSetselectedContracts,
  setSelectedPositions,
  setSelectedChildrenGroups,
  selectedContracts,
  selectedPositions,
  selectedChildrenGroups,
  onModalOpen,
  enums,
  isLoading,
  teamPositions,
  childrenGroups
}) => {
  const { t } = useTranslation();

  return (
    <Flex align="center" justify="space-between" gap="small">
      <Flex align="center" gap="small">
        <Select
          className="contains-tags"
          style={{ width: 240 }}
          showSearch
          mode="multiple"
          allowClear
          placeholder={t('employees.placeholders.filter_by_contract_type')}
          loading={isLoading}
          filterOption={(input, option) => onsearchContracts(input, option)}
          onSelect={(value) => {
            setSetselectedContracts([...selectedContracts, value]);
          }}
          onDeselect={(value) =>
            setSetselectedContracts((prevContract) =>
              prevContract.filter((item) => item !== value)
            )
          }
          onClear={() => {
            setSetselectedContracts([]);
          }}
        >
          {enums?.contract_types?.map((r) => (
            <Option key={r} value={r} data-label={t(`users.tags.${r}`)}>
              <Tag color={employeesContracts[r]}>
                {t(`employees.enums.contract_types.${r}`)}
              </Tag>
            </Option>
          ))}
        </Select>
        <Select
          className="contains-tags"
          style={{ width: 240 }}
          showSearch
          mode="multiple"
          allowClear
          placeholder={t('employees.placeholders.filter_by_position')}
          loading={isLoading}
          filterOption={(input, option) => onsearchPositions(input, option)}
          onSelect={(value) => {
            setSelectedPositions([...selectedPositions, value]);
          }}
          onDeselect={(value) =>
            setSelectedPositions((prevPosition) =>
              prevPosition.filter((item) => item !== value)
            )
          }
          onClear={() => {
            setSelectedPositions([]);
          }}
        >
          {teamPositions?.map((position) => (
            <Option key={position?._id} value={position?._id}>
              <Tag color={position?.color?.hex}>{position?.label}</Tag>
            </Option>
          ))}
        </Select>
        <Select
          className="contains-tags"
          style={{ width: 260 }}
          showSearch
          mode="multiple"
          allowClear
          placeholder={t('employees.placeholders.filter_by_children_group')}
          loading={isLoading}
          filterOption={(input, option) =>
            onsearchChildrenGroups(input, option)
          }
          onSelect={(value) => {
            setSelectedChildrenGroups([...selectedChildrenGroups, value]);
          }}
          onDeselect={(value) =>
            setSelectedChildrenGroups((prevGroup) =>
              prevGroup.filter((item) => item !== value)
            )
          }
          onClear={() => {
            setSelectedChildrenGroups([]);
          }}
        >
          {childrenGroups?.map((group) => (
            <Option key={group?._id} value={group?._id}>
              <Tag color={group?.tag_color}>{group?.label}</Tag>{' '}
            </Option>
          ))}
        </Select>
      </Flex>
      <Button onClick={() => onModalOpen({}, 'create')} type="primary">
        <PlusOutlined />
        {t('buttons.create')}
      </Button>
    </Flex>
  );
};

ExtraButtons.propTypes = {
  onsearchContracts: PropTypes.func.isRequired,
  onsearchPositions: PropTypes.func.isRequired,
  onsearchChildrenGroups: PropTypes.func.isRequired,
  setSetselectedContracts: PropTypes.func.isRequired,
  setSelectedPositions: PropTypes.func.isRequired,
  setSelectedChildrenGroups: PropTypes.func.isRequired,
  selectedContracts: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedPositions: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedChildrenGroups: PropTypes.arrayOf(PropTypes.string).isRequired,
  onModalOpen: PropTypes.func.isRequired,
  enums: PropTypes.shape({
    contract_types: PropTypes.arrayOf(PropTypes.string),
    positions: PropTypes.arrayOf(PropTypes.string),
    groups: PropTypes.arrayOf(PropTypes.string)
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  teamPositions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  childrenGroups: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};
