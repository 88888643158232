import { useEffect, useState } from 'react';
import { Tabs, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { useParentDocumentTabs } from './parentDocumentTabs';
import { ContentCustom, PageHeaderCustom } from '../../components';
import { DocumentTab } from '../home/parentAccess/DocumentTab';

/**
 * Component for displaying parent documents.
 *
 * @component
 *
 * @returns {JSX.Element} ParentDocuments component.
 */
export const ParentDocuments = () => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [parentDocuments, setParentDocuments] = useState([]);
  const [forceRefresh, setForceRefresh] = useState(false);
  const [screen, setScreen] = useState(0);

  const items = useParentDocumentTabs(
    parentDocuments,
    forceRefresh,
    setForceRefresh
  );

  const getParentDocuments = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `children/parent-documents`
      });
      setParentDocuments(data);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      await getParentDocuments();
    })();
  }, [screen, forceRefresh]);

  const screens = [
    <>
      <Tabs items={items} type="card" />
      <Button block onClick={() => setScreen(1)} style={{ margin: 0 }}>
        {t('parents.home.send_document')}
      </Button>
    </>,
    <DocumentTab parentsChildren={parentDocuments} setScreen={setScreen} />
  ];

  return (
    <>
      <PageHeaderCustom title={t('communication.tabs.documents')} />
      <ContentCustom>{screens[screen]}</ContentCustom>
    </>
  );
};
