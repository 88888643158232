import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Table, Button, Row, Form } from 'antd';
import { useEffect, useState } from 'react';
import { EditableCell, EditableRow } from './EditableCell';
import { useColumns } from './columns';
import { handleAdd, handleArrayFilter } from './utils';

const translations = {
  FIFTEEN_MINUTES: 15,
  THIRTY_MINUTES: 30,
  SIXTY_MINUTES: 60
};

const multiplierTranslations = {
  FIFTEEN_MINUTES: 1,
  THIRTY_MINUTES: 2,
  SIXTY_MINUTES: 4
};

/**
 * The ExtraHoursTable component.
 *
 * @param {object} props - The props.
 * @param {object} props.dataSource - The dataSource.
 * @param {function} setDataSource - Function to set dataSource state.
 * @param {string} props.target - The targeted array.
 * @param {Object} props.form - Form instance.
 * @param {string} arrayFilter - Round up rules state.
 * @returns {React.Component} The component.
 */
export const ExtraHoursTable = ({
  dataSource,
  setDataSource,
  target,
  form,
  arrayFilter
}) => {
  const { t } = useTranslation();
  const columns = useColumns();

  const [filteredData, setFilteredData] = useState([]);
  const [refreshFilter, setRefreshFilter] = useState(false);

  const translationKey =
    target === 'occasional_extra_fees'
      ? arrayFilter.occasional
      : arrayFilter.regulars;
  const translatedValue = translations[translationKey];
  const extraFeeMultiplier = multiplierTranslations[translationKey] || 1;

  useEffect(() => {
    if (arrayFilter) {
      handleArrayFilter(dataSource, translatedValue, setFilteredData);
    }
  }, [arrayFilter, dataSource, refreshFilter]);

  const columnsWithEditable = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record, rowIndex) => ({
        record,
        index: rowIndex,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        disabled: col.disabled,
        setDataSource,
        filteredData,
        target,
        setRefreshFilter
      })
    };
  });

  return (
    <>
      <Row style={{ flexDirection: 'column' }}>
        {t('configurations.invoicing.form.bill_excess_count_on_regular_care')}
        <Form.Item name={[target]} style={{ width: '300%' }}>
          <Table
            pagination={false}
            components={{
              body: {
                row: EditableRow,
                cell: EditableCell
              }
            }}
            rowClassName={() => 'editable-row'}
            bordered
            dataSource={filteredData}
            columns={columnsWithEditable}
          />
        </Form.Item>
      </Row>

      <Row justify="end" style={{ marginTop: 10 }}>
        <Button
          type="primary"
          onClick={() =>
            handleAdd(
              filteredData,
              translatedValue,
              setDataSource,
              target,
              form,
              dataSource,
              setRefreshFilter,
              extraFeeMultiplier
            )
          }
        >
          {t('configurations.invoicing.form.extra_duration.add')}
        </Button>
      </Row>
    </>
  );
};

ExtraHoursTable.propTypes = {
  dataSource: PropTypes.arrayOf(
    PropTypes.shape({ duration: PropTypes.number, extra_fee: PropTypes.number })
  ),
  setDataSource: PropTypes.func,
  target: PropTypes.string.isRequired,
  form: PropTypes.shape({
    setFields: PropTypes.func,
    setFieldValue: PropTypes.func
  }),
  arrayFilter: PropTypes.string
};

ExtraHoursTable.defaultProps = {
  dataSource: {},
  setDataSource: null,
  form: null,
  arrayFilter: undefined
};
