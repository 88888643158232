import { useTranslation } from 'react-i18next';
import { Divider, Popconfirm, Tag } from 'antd';
import dayjs from 'dayjs';
import {
  ContainerOutlined,
  CopyOutlined,
  EditOutlined,
  EyeOutlined,
  UploadOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import {
  contractStatus,
  contractTypes
} from '../../../../utils/constants/tagColors';
import { routes } from '../../../../utils/constants/adminRoutes';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { generateDocumentsOld } from '../Documents/components/generateDocumentsOld';

const iconSize = 18;

/**
 * Custom hook to define columns for a contract list table.
 *
 * @function
 * @hook
 *
 * @param {boolean} refresh - A state variable indicating if data needs to be refreshed.
 * @param {function} setRefresh - A function to set the refresh state.
 * @param {string} childID - The ID of the child associated with the contracts.
 * @returns {Array} An array of column definitions for the table.
 */
export const useColumns = (refresh, setRefresh, childID) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();

  const archiveResource = async (id) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/contracts/${id}`,
        body: {
          status: 'ARCHIVED',
          archived: true,
          archiving_date: dayjs()
        }
      });
      setRefresh(!refresh);
    } catch (e) {
      message(e);
    }
  };

  const duplicateResource = async (id) => {
    try {
      await dispatchAPI('POST', {
        url: `/contracts/duplicate/${id}/${childID}`
      });
      setRefresh(!refresh);
    } catch (e) {
      message(e);
    }
  };

  return [
    {
      title: t('contracts.list.number'),
      key: 'number',
      dataIndex: 'number',
      sorter: true
    },
    {
      title: t('contracts.list.type.title'),
      key: 'billing_type',
      dataIndex: 'billing_type',
      sorter: true,
      render: (type) => (
        <Tag color={contractTypes[type]}>
          {t(`contracts.list.type.${type}`)}
        </Tag>
      )
    },
    {
      title: t('contracts.list.status.status'),
      key: 'status',
      dataIndex: 'status',
      render: (status) => (
        <Tag color={contractStatus[status]}>
          {t(`contracts.tags.${status}`)}
        </Tag>
      ),
      sorter: true
    },
    {
      title: t('contracts.list.start_date'),
      key: 'start_date',
      dataIndex: 'start_date',
      sorter: true,
      render: (start_date) => dayjs(start_date).format('DD/MM/YYYY')
    },
    {
      title: t('contracts.list.end_date'),
      key: 'end_date',
      dataIndex: 'end_date',
      sorter: true,
      render: (end_date) => dayjs(end_date).format('DD/MM/YYYY')
    },
    {
      align: 'right',
      render: (record) => (
        <>
          <>
            <UploadOutlined
              onClick={() => {
                generateDocumentsOld(
                  dispatchAPI,
                  message,
                  'CONTRACT',
                  childID,
                  record._id,
                  t
                );
              }}
            />
            <Divider type="vertical" />
          </>
          <Link
            to={{
              pathname: `${routes.CHILDREN}/show/${childID}/contracts/show/${record?._id}`
            }}
          >
            <EyeOutlined style={{ fontSize: iconSize }} />
            <Divider type="vertical" />
          </Link>
          {record?.status === 'PENDING' && (
            <>
              <Link
                to={{
                  pathname: `${routes.CHILDREN}/contracts/edit/${childID}/${record?._id}`
                }}
              >
                <EditOutlined style={{ fontSize: iconSize }} />
              </Link>
              <Divider type="vertical" />
            </>
          )}

          {record?.status !== 'ARCHIVED' && (
            <>
              <CopyOutlined
                onClick={() => duplicateResource(record?._id)}
                style={{ fontSize: iconSize }}
              />
              <Divider type="vertical" />
              <Popconfirm
                title={t('datatable.column.action.delete.title')}
                okText={t('datatable.column.action.delete.ok')}
                okButtonProps={{ type: 'danger' }}
                cancelText={t('datatable.column.action.delete.cancel')}
                onConfirm={() => archiveResource(record?._id)}
                icon={<WarningOutlined />}
              >
                <ContainerOutlined
                  style={{ color: 'var(--errorColor', fontSize: iconSize }}
                  type="delete"
                />
              </Popconfirm>
            </>
          )}
        </>
      )
    }
  ];
};
