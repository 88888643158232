import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { PageHeader } from '@ant-design/pro-layout';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { ParentPlanningTabs } from './ParentPlanningTabs';
import { ContentCustom } from '../../../components';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { DocumentTab } from './DocumentTab';
import { EventForm } from './EventForm';

/**
 * ParentHome Component
 *
 * This component represents the parent's home page.
 * It displays planning-related content for parents and supports translation.
 *
 * @component
 * @returns {JSX.Element} The rendered ParentHome component.
 */
export const ParentHome = () => {
  const { t } = useTranslation();
  const { dispatchAPI, user } = useAuthContext();
  const { message } = useErrorMessage();
  const [parentsChildren, setChildren] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [screen, setScreen] = useState(0);

  const getChildren = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `children/parent-children/${user._id}`
      });
      setChildren(data);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      await getChildren();
    })();
  }, []);

  const screens = [
    <ParentPlanningTabs
      parentsChildren={parentsChildren}
      isLoading={isLoading}
      setIsLoading={setIsLoading}
      setScreen={setScreen}
      screen={screen}
    />,
    <DocumentTab parentsChildren={parentsChildren} setScreen={setScreen} />,
    <EventForm
      parentsChildren={parentsChildren}
      setScreen={setScreen}
      type="absences"
    />,
    <EventForm
      parentsChildren={parentsChildren}
      setScreen={setScreen}
      type="reservations"
    />
  ];

  const headersTitles = [
    t('parents.home.title'),
    t('parents.home.send_document'),
    t('parents.home.add_absence'),
    t('parents.home.add_occasional_reservation')
  ];

  return (
    <>
      <PageHeader
        title={headersTitles[screen]}
        onBack={() => setScreen(0)}
        backIcon={screen === 0 ? null : <ArrowLeftOutlined />}
      />
      <ContentCustom>{screens[screen]}</ContentCustom>
    </>
  );
};
