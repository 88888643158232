import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Button, Divider } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';

const iconSize = 18;

/**
 * Columns for the datatable
 * @hook
 *
 * @param {function} handleModalBehaviour - A function to handle modals.
 * @param {boolean} refresh - A boolean value indicating whether to force refresh the table.
 * @param {function} setRefresh - A function to set the forceRefresh value.
 * @returns {Array} columns for the datatable
 */
export const useColumns = (handleModalBehaviour, refresh, setRefresh) => {
  const { t } = useTranslation();
  const { dispatchAPI, daycare, user } = useAuthContext();
  const { message } = useErrorMessage();

  const deleteResource = async (id) => {
    try {
      await dispatchAPI('DELETE', {
        url: `/daycares/hours/${daycare}/${id}`
      });
      setRefresh(!refresh);
    } catch (e) {
      message(e);
    }
  };

  return [
    {
      title: t(''),
      key: 'opening_day',
      dataIndex: 'opening_day',
      render: (opening_day) =>
        opening_day ? t(`week_days.${opening_day.toLowerCase()}`) : '-'
    },
    {
      title: t('daycares.form.morning_opening'),
      key: 'morning_opening',
      dataIndex: 'morning_opening',
      render: (morning_opening) =>
        morning_opening ? dayjs.utc(morning_opening).format('HH:mm') : '-'
    },

    {
      title: t('daycares.form.morning_closing'),
      key: 'morning_closing',
      dataIndex: 'morning_closing',
      render: (morning_closing) =>
        morning_closing ? dayjs.utc(morning_closing).format('HH:mm') : '-'
    },
    {
      title: t('daycares.form.afternoon_opening'),
      key: 'afternoon_opening',
      dataIndex: 'afternoon_opening',
      render: (afternoon_opening) =>
        afternoon_opening ? dayjs.utc(afternoon_opening).format('HH:mm') : '-'
    },
    {
      title: t('daycares.form.evening_closing'),

      key: 'evening_closing',
      dataIndex: 'evening_closing',
      render: (evening_closing) =>
        evening_closing ? dayjs.utc(evening_closing).format('HH:mm') : '-'
    },
    ...(user.role !== 'users:MANAGER'
      ? [
          {
            width: 50,
            render: (record) => (
              <>
                <Button
                  type="link"
                  className="no-padding"
                  onClick={() => handleModalBehaviour(record, 'edit')}
                >
                  <EditOutlined style={{ fontSize: iconSize }} />
                </Button>
                <Divider type="vertical" />
                <Button
                  type="link"
                  className="no-padding"
                  onClick={() => deleteResource(record._id, 'edit')}
                >
                  <DeleteOutlined style={{ fontSize: iconSize }} />
                </Button>
              </>
            )
          }
        ]
      : [])
  ];
};
