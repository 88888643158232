import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { DatePicker, Switch } from 'antd';
import { useTranslation } from 'react-i18next';

/**
 * Custom hook for managing form fields related to diet information.
 *
 * @returns {Object} An object containing fields and loading state for diet-related form fields.
 */
export const useTerminatedFields = () => {
  const { t } = useTranslation();
  const [isFieldsLoading, setIsFieldsLoading] = useState(false);
  const [isTerminationConfirmed, setIsTerminationConfirmed] = useState(false);

  useEffect(() => {
    setIsFieldsLoading(false);
  }, []);

  const fields = [
    {
      name: ['confirm_termination'],
      valuePropName: 'checked',
      rules: [{ required: true }],
      input: (
        <Switch
          defaultChecked={isTerminationConfirmed}
          checkedChildren={t('yes')}
          unCheckedChildren={t('no')}
          onChange={() => setIsTerminationConfirmed(!isTerminationConfirmed)}
        />
      )
    },
    ...(isTerminationConfirmed
      ? [
          {
            name: ['terminated_date'],
            rules: [{ required: true }],
            input: (
              <DatePicker
                disabledDate={(current) =>
                  current && current <= dayjs().startOf('day')
                }
              />
            )
          },
          {
            name: ['terminated_reason'],
            rules: [{ required: true }]
          }
        ]
      : [])
  ];

  return {
    fields,
    isFieldsLoading
  };
};
