import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';

/**
 * Methods related to pre-registrations.
 * @function
 * @description PreRegistrationMethods contains methods related to pre-registrations.
 * @returns {Object} An object containing the methods.
 */
export const PreRegistrationsMethods = () => {
  const { dispatchAPI, daycare } = useAuthContext();
  const { message } = useErrorMessage();

  /**
   * Get a pre-registration by ID.
   * @function
   * @async
   * @param {string} id - The ID of the pre-registration. (ex: ObjectId)
   * @returns {Promise<Object|null>} A promise that resolves to the pre-registration data, or null if not found.
   */
  const getPreRegistration = async (id) => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/pre-registrations/finale-validation/${id}`
      });

      if (!data) {
        return null;
      }

      return data;
    } catch (error) {
      return message(error);
    }
  };

  /**
   * Get the mandatory documents for the daycare.
   * @function
   * @async
   * @returns {Promise<Array<Object>|null>} A promise that resolves to an array of mandatory documents, or null if not found.
   */
  const getMandatoryDocuments = async () => {
    try {
      const { data } = await dispatchAPI('POST', {
        url: `daycares/mandatory-documents`,
        body: {
          id: daycare
        }
      });

      if (
        data?.documents_dynamic_list &&
        data?.documents_dynamic_list.length > 0
      ) {
        return data.documents_dynamic_list;
      }

      return [];
    } catch (e) {
      return message(e);
    }
  };

  /**
   * Patch a pre-registration.
   * @function
   * @async
   * @param {string} id - The ID of the pre-registration. (ex: ObjectId)
   * @param {Object} body - The body of the pre-registration.
   * @param {Array} filesList - Array of files.
   * @param {Array} filesConfiguration - Array of file configurations.
   * @param {string} customUrl - Optional - A custom URL to use instead of the default one.
   * @returns {Promise<Object|null>} A promise that resolves to the patched pre-registration data, or null if not found.
   */
  const patchPreRegistration = async (
    id,
    body,
    filesList,
    filesConfiguration,
    customUrl = null
  ) => {
    try {
      if (id && body) {
        const formData = new FormData();

        if (filesList.length && filesConfiguration.length) {
          filesList.forEach((file) => {
            formData.append('documents', file);
          });
          formData.append(
            'filesConfiguration',
            JSON.stringify(filesConfiguration)
          );
        }

        formData.append('values', JSON.stringify(body));
        const { data } = await dispatchAPI('PATCH', {
          url: customUrl || `/pre-registrations/${id}`,
          body: formData
        });

        if (!data) {
          return null;
        }
        return data;
      }

      return null;
    } catch (error) {
      return message(error);
    }
  };

  /**
   * Patch the status of a pre-registration.
   * @function
   * @async
   * @param {string} id - The ID of the pre-registration. (ex: ObjectId)
   * @param {Object} body - The body of the pre-registration.
   * @returns {Promise<Object|null>} A promise that resolves to the patched pre-registration data, or null if not found.
   */
  const patchStatus = async (id, body) => {
    try {
      if (id && body) {
        const { data } = await dispatchAPI('PATCH', {
          url: `/pre-registrations/status-patch/${id}`,
          body
        });

        if (!data) {
          return null;
        }
        return data;
      }
      return null;
    } catch (error) {
      return message(error);
    }
  };

  /**
   * Patch a contract.
   * @function
   * @async
   * @param {string} id - The ID of the contract. (ex: ObjectId)
   * @param {Object} body - The body of the contract.
   * @param {Array} filesList - Array of files.
   * @param {Array} filesConfiguration - Array of file configurations.
   * @param {string} customUrl - Optional - A custom URL to use instead of the default one. (ex: '/contracts/finale-validation/ObjectId')
   * @returns {Promise<Object|null>} A promise that resolves to the patched contract data, or null if not found.
   */
  const patchContract = async (
    id,
    body,
    filesList,
    filesConfiguration,
    customUrl = null
  ) => {
    try {
      if (id && body) {
        const formData = new FormData();
        if (filesList.length && filesConfiguration.length) {
          filesList.forEach((file) => {
            formData.append('documents', file);
          });
          formData.append(
            'filesConfiguration',
            JSON.stringify(filesConfiguration)
          );
        }

        formData.append(
          'values',
          JSON.stringify({ ...body, filesConfiguration })
        );

        const { data } = await dispatchAPI('PATCH', {
          url: customUrl || `/contracts/${id}`,
          body: formData
        });

        if (!data) {
          return null;
        }
        return data;
      }

      return null;
    } catch (error) {
      return message(error);
    }
  };

  /**
   * Get the status of a daycare.
   * @function
   * @param {Array<Object>} daycares - The array of daycares.
   * @returns {string} The status of the daycare.
   */
  const getStatusByDaycare = (daycares) =>
    (daycares || []).find((d) => d._id === daycare)?.status || null;

  const isAlreadyAcceptedByAnotherDaycare = (daycares) =>
    (daycares || []).some(
      (d) => d.status === 'ALREADY_ACCEPTED_BY_ANOTHER_DAYCARE'
    );

  return {
    getPreRegistration,
    getMandatoryDocuments,
    patchPreRegistration,
    patchStatus,
    patchContract,
    getStatusByDaycare,
    isAlreadyAcceptedByAnotherDaycare
  };
};
