import PropTypes from 'prop-types';
import { Row, Col, Flex, Typography, Button, Dropdown, Badge } from 'antd';
import {
  PlusCircleFilled,
  CalendarFilled,
  WarningOutlined
} from '@ant-design/icons';
import { PlanningRangeBar } from '../PlanningRangeBar/PlanningRangeBar';
import { useAuthContext } from '../../../contexts/AuthContext';
import { calculateBirthDateDiff } from '../../children/utils/calculateBirthDateDiff';
import { generateOpenModalParameters } from './generateOpenModalParameters';
import { useMenuItems } from './menuItems';

const { Text } = Typography;

/**
 * A component for displaying a row in a child planning view.
 *
 * This component represents a single child's schedule in a planning interface. It shows the child's name,
 * a range bar for their events, and action buttons to modify their schedule. Depending on the user's role,
 * different action buttons are available for creating reservations, absences, adaptations, and pickup times.
 * The component uses the `PlanningRangeBar` for visualizing the events and `Dropdown` for additional actions.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.openModal - A function to open a modal for creating or editing events.
 * @param {Function} props.deleteEvent - A function to delete an event.
 * @param {Object} props.event - The event data for the child, including schedule details and child's information.
 * @param {Boolean} props.displayButtons - Boolean to display buttons of the PlanningRangeBar.
 * @returns {JSX.Element} The JSX rendering of a row representing a child's event in the planning.
 *
 * @example
 * const event = {
 *   childSchedule: { overtimes: [], pickUpAndPlanningMatch: [] },
 *   child: { first_name: 'John', last_name: 'Doe' },
 *   pickup_times: [{ _id: '1' }],
 *   _id: 'event1'
 * };
 * return (
 *   <ChildRow
 *     openModal={handleOpenModal}
 *     deleteEvent={handleDeleteEvent}
 *     event={event}
 *   />
 * )
 */
export const ChildRow = ({ event, openModal, deleteEvent, displayButtons }) => {
  // CAREFUL: Before deploying to production or staging, make sure to try on your machine with a docker container
  // with the same configuration as the server to avoid any issue with saved dates in databases.
  // You may not have any issue with your local machine but it may be different on the server.
  // Make sure to discuss with the team before deploying any changes.
  // We spent a lot of time debugging issues with dates and timezones, so please be careful.
  // Thanks !
  const { user } = useAuthContext();
  const { component } = calculateBirthDateDiff(event?.child?.birth_date);
  const items = useMenuItems(event, openModal, user);

  const nameColor = (gender) => {
    if (gender === 'BOY') {
      return 'var(--logoBlue)';
    }
    if (gender === 'GIRL') {
      return 'var(--logoPurple)';
    }
    return 'var(--mediumGray)';
  };

  const menu = {
    items
  };

  const { type, child, timesID, eventID, previsionalPlanning } =
    generateOpenModalParameters(event);

  const displayButtonsConditionUpdated =
    displayButtons && event.editAuthorization && user.role !== 'users:TABLET';

  return (
    <Row align="middle">
      <Col span={4} style={{ paddingRight: 8 }}>
        <Flex align="center" gap="small" justify="flex-end">
          {component}
          <Text
            style={{
              color: nameColor(event?.child?.gender),
              textAlign: 'right'
            }}
          >{`${event?.child?.first_name} ${event?.child?.last_name}`}</Text>
        </Flex>
      </Col>
      <Col span={18}>
        <PlanningRangeBar
          events={event}
          openModal={openModal}
          deleteEvent={deleteEvent}
          displayButtons={displayButtonsConditionUpdated}
          source="child"
        />
      </Col>
      <Col span={2}>
        {displayButtonsConditionUpdated && (
          <Flex align="center" gap="small" justify="flex-end">
            <Dropdown menu={menu} disabled={items.length === 0}>
              <Button type="link" className="no-padding">
                <PlusCircleFilled
                  style={{
                    fontSize: 24,
                    color: 'var(--primaryColor)'
                  }}
                />
              </Button>
            </Dropdown>
            <Badge
              count={
                event?.childSchedule?.incompletePickUpTimes?.length > 0 ? (
                  <WarningOutlined style={{ color: '#f5222d' }} />
                ) : null
              }
            >
              <Button
                type="link"
                className="no-padding"
                disabled={
                  !(
                    event?.childSchedule?.overtimes?.length > 0 ||
                    event?.childSchedule?.pickUpAndPlanningMatch?.length > 0 ||
                    event?.childSchedule?.incompletePickUpTimes?.length > 0
                  )
                }
                onClick={() =>
                  openModal(
                    type,
                    child,
                    'edit',
                    timesID,
                    eventID,
                    previsionalPlanning
                  )
                }
              >
                <CalendarFilled
                  style={{
                    fontSize: 24,
                    color: 'var(--primaryColor)'
                  }}
                />
              </Button>
            </Badge>
          </Flex>
        )}
      </Col>
    </Row>
  );
};

ChildRow.propTypes = {
  openModal: PropTypes.func.isRequired,
  deleteEvent: PropTypes.func.isRequired,
  event: PropTypes.shape({
    childSchedule: PropTypes.shape({
      overtimes: PropTypes.arrayOf(PropTypes.shape({})),
      pickUpAndPlanningMatch: PropTypes.arrayOf(PropTypes.shape({})),
      incompletePickUpTimes: PropTypes.arrayOf(PropTypes.shape({}))
    }).isRequired,
    child: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      gender: PropTypes.string,
      birth_date: PropTypes.string.isRequired
    }),
    pickup_times: PropTypes.arrayOf(PropTypes.shape({ _id: PropTypes.string })),
    reference_day: PropTypes.arrayOf(
      PropTypes.shape({ _id: PropTypes.string })
    ),
    editAuthorization: PropTypes.bool,
    _id: PropTypes.string
  }).isRequired,
  displayButtons: PropTypes.bool.isRequired
};
