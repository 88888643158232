import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Tabs, Flex } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../contexts/AuthContext';
import { ContentCustom, PageHeaderCustom, Exception } from '../../components';
import { ExtraButtons } from './ExtraButtons';
import { useTabsItems } from './useTabsItems';
import { PreRegistrationsMethods } from './methods';

const populateArray = [
  'authorizations',
  'diet',
  'diet.allergies',
  'health',
  'health.allergies childhood_illnesses',
  'contract',
  'contract.rib'
];

/**
 * Component for rendering the pre-registration show page.
 * This component is rendered when the user clicks on a pre-registration from the pre-registration list.
 * It renders the pre-registration information.
 *
 * @component
 * @returns {React.Component} Pre-registration show page component
 */
export const ShowPreRegistration = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { daycare } = useAuthContext();
  const { getPreRegistration, patchStatus } = PreRegistrationsMethods();
  const [isLoading, setIsLoading] = useState(false);
  const [preRegistration, setPreRegistration] = useState({});
  const [exception, setException] = useState(null);
  const items = useTabsItems(preRegistration, isLoading);
  const childName = `${preRegistration?.child?.first_name || ''} ${
    preRegistration?.child?.last_name || ''
  }`;
  const title = `${childName} - ${t('pre-registrations.show.title')}`;

  const getPreRegistrationData = async () => {
    setIsLoading(true);

    const data = await getPreRegistration(id, populateArray.join(','));

    if (data) {
      setPreRegistration(data);
    } else {
      setException(<Exception />);
    }

    setIsLoading(false);
  };

  const updatePreRegistrationStatus = async (status) => {
    setIsLoading(true);

    const body = {
      daycareId: daycare,
      status
    };

    const customURL = `/pre-registrations/status-patch/${id}`;
    const data = await patchStatus(id, body, null, null, customURL);
    if (data) setPreRegistration(data);
    setIsLoading(false);
  };

  useEffect(() => {
    (async () => {
      await getPreRegistrationData();
    })();
  }, [id]);

  return (
    exception || (
      <>
        <PageHeaderCustom
          withoutBreadCrums
          title={title}
          extra={
            preRegistration && (
              <Flex gap="small">
                <ExtraButtons
                  isLoading={isLoading}
                  preRegistration={preRegistration}
                  childName={childName}
                  updatePreRegistrationStatus={updatePreRegistrationStatus}
                />
              </Flex>
            )
          }
        />
        <ContentCustom>
          <Flex vertical gap="middle">
            <Tabs defaultActiveKey="pre-registration" items={items} />
          </Flex>
        </ContentCustom>
      </>
    )
  );
};
