import { useState, useEffect } from 'react';
import { Table, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { useColumns } from './columns';
import { EditableCell } from './EditableCell';

/**
 * @description ChildrenGroupsTable component. Displays the children groups table
 * @component
 * @returns {JSX} The children groups table
 */
export const ChildrenGroupsTable = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [colorPicked, setColorPicked] = useState('');
  const [ageRange, setAgeRange] = useState(null);
  const [editingKey, setEditingKey] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [groups, setGroups] = useState([]);

  const getChildrenGroups = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/children-groups'
      });
      setGroups(data);
    } catch (error) {
      message(error);
    }
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      await getChildrenGroups();
      setIsLoading(false);
    })();
  }, [refresh]);

  const edit = (record) => {
    form.setFieldsValue(record);
    setColorPicked(record.tag_color);
    setAgeRange(record.age_range);
    setEditingKey(record._id);
  };

  const save = async (_id) => {
    try {
      const body = form.getFieldsValue();
      await dispatchAPI('PATCH', {
        url: `/children-groups/${_id}`,
        body: {
          ...body,
          age_range: ageRange,
          tag_color: colorPicked
        }
      });
      setRefresh(!refresh);
      setEditingKey('');
      setAgeRange(null);
      setColorPicked('');
    } catch (e) {
      message.error(t('errors.message.save'));
    }
  };

  const del = async (_id) => {
    try {
      await dispatchAPI('DELETE', { url: `/children-groups/${_id}` });
      setRefresh(!refresh);
    } catch (e) {
      message.error(t('errors.message.delete'));
    }
  };

  const cancel = () => {
    setEditingKey('');
  };

  const isEditing = (record) => record._id === editingKey;

  const columns = useColumns({ isEditing, save, cancel, edit, del });
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex,
        dataIndex: col.dataIndex,
        tag_color: record.tag_color,
        editing: isEditing(record),
        colorPicked,
        setColorPicked,
        ageRange: record.age_range,
        setAgeRange
      })
    };
  });

  return (
    <Form form={form} component={false}>
      <Table
        components={{
          body: {
            cell: EditableCell
          }
        }}
        bordered={false}
        loading={isLoading}
        dataSource={groups}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={{
          onChange: cancel
        }}
      />
    </Form>
  );
};
