import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Input,
  Button,
  Form,
  message as antdMessage,
  Checkbox,
  Row
} from 'antd';
import { useLocation } from 'react-router-dom';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import {
  ContentCustom,
  PageHeaderCustom,
  BackButton
} from '../../../components';
import { formItemLayout } from '../../../utils/constants/formLayout';

/**
 * A component for recording time-related activities, such as arrivals and departures.
 *
 * This component provides a form for users to input a code, verify it, and then record time-related
 * activities for children based on the verified code. It uses a GET request to fetch children associated
 * with the input code and a POST request to record the activities. It also handles various error messages
 * and displays success messages upon successful submissions. The component includes a back button for navigation.
 *
 * @component
 * @returns {JSX.Element} The JSX rendering of the time recording form with verification and submission functionalities.
 *
 * @example
 * return (
 *   <TimeRecorder />
 * )
 */
export const TimeRecorder = () => {
  // CAREFUL: Before deploying to production or staging, make sure to try on your machine with a docker container
  // with the same configuration as the server to avoid any issue with saved dates in databases.
  // You may not have any issue with your local machine but it may be different on the server.
  // Make sure to discuss with the team before deploying any changes.
  // We spent a lot of time debugging issues with dates and timezones, so please be careful.
  // Thanks !
  const { t } = useTranslation();
  const location = useLocation();
  const [form] = Form.useForm();
  const { dispatchAPI, daycare } = useAuthContext();
  const { message: errorMessage } = useErrorMessage();
  const [code, setCode] = useState('');
  const [children, setChildren] = useState([]);

  useEffect(() => {
    form.setFieldsValue({
      code,
      children: children.map((child) => ({
        id: child._id,
        checked: true
      }))
    });
  }, [children]);

  const handleChange = (event) => {
    setCode(event.target.value);
  };

  const getChildren = async () => {
    if (code) {
      try {
        const { data } = await dispatchAPI('GET', {
          url: `/time-recorder/${code}/${daycare}`
        });
        setChildren(data);
      } catch (error) {
        const {
          response: {
            data: { message }
          }
        } = error;
        if (
          message === 'pickup_code_not_found' ||
          message === 'child_not_found' ||
          message === 'active_contract_not_found' ||
          message === 'child_already_picked_up_today' ||
          message === 'not_authorized'
        ) {
          errorMessage(t(message));
        }
        errorMessage(error);
      }
    }
  };

  const onFinish = async (body) => {
    if (children?.length > 0) {
      try {
        const { data } = await dispatchAPI('POST', {
          url: `/time-recorder?daycare=${daycare}`,
          body: {
            ...body,
            daycare
          }
        });
        if (data.success) {
          data.success.map((succ) =>
            antdMessage.success(
              `${succ.split(':')[0]} ${t(
                `success.messages.${succ.split(':')[1]}`
              )}`
            )
          );
        }
        if (data.error) {
          data.error.map((err) =>
            antdMessage.error(
              `${err.split(':')[0]} ${t(
                `success.messages.${err.split(':')[1]}`
              )}`
            )
          );
        }
        setCode('');
        setChildren([]);
      } catch (e) {
        if (e.response) errorMessage(e.response.status);
      }
    }
  };

  return (
    <ContentCustom>
      {location.pathname.startsWith('/time-recorder') && <BackButton />}
      <PageHeaderCustom
        title={t('time_recorder.title')}
        withoutBreadCrums
        withoutNavigateBack
      />
      <Form form={form} {...formItemLayout} onFinish={onFinish}>
        <Form.Item
          label={t('time_recorder.form.label')}
          name="code"
          rules={[{ required: true }]}
        >
          <Input
            placeholder={t('time_recorder.form.placeholder')}
            onChange={handleChange}
          />
        </Form.Item>
        <Row justify="center">
          <Form.Item>
            <Button type="primary" onClick={getChildren}>
              {t('buttons.verify_code')}
            </Button>
          </Form.Item>
        </Row>

        {children?.length > 0 && (
          <>
            <Form.List name="children">
              {(fields) => (
                <>
                  {fields.map((field, index) => (
                    <div key={field.key}>
                      <Form.Item
                        name={[field.name, 'id']}
                        initialValue={children[index]?._id}
                        hidden
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        label={children[index]?.first_name}
                        name={[field.name, 'checked']}
                        valuePropName="checked"
                      >
                        <Checkbox />
                      </Form.Item>
                    </div>
                  ))}
                </>
              )}
            </Form.List>
            <Row justify="center">
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  {t('buttons.validate')}
                </Button>
              </Form.Item>
            </Row>
          </>
        )}
      </Form>
    </ContentCustom>
  );
};
