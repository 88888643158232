import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Layout } from 'antd';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import Logo from '../../assets/images/logomaiavillage.png';
import LogoCollapsed from '../../assets/images/logomaiavillage_square.png';
import HeaderLayout from './HeaderLayout/HeaderLayout';
import { useHandleResize } from '../../utils/handleResize';
import { MercuryAppContextProvider } from '../../contexts/MercuryAppContext';
import packageJson from '../../../package.json';
import mainBg from '../../assets/images/mainBg.png';
import { ManagerNavMenu } from './ManagerNavMenu';

const { Content, Footer, Sider } = Layout;

const StyledLayout = styled.div`
  height: 100vh;
`;

const BgLayout = styled(Layout)`
  background-image: url(${mainBg});
`;

const StyledSider = styled.div`
  height: 100vh;
  z-index: 10;
  overflow: hidden;
  position: fixed;
  left: 0;
  padding-top: 12px;
`;

const LogoDiv = styled.div`
  position: relative;
  height: 64px;
  padding-left: 0;
  overflow: hidden;
  line-height: 64px;
  transition: all 0.3s;
  z-index: 900;
  display: flex;
  justify-content: center;
  img {
    display: inline-block;
    height: 52px;
    vertical-align: middle;
  }
`;

const StyledContent = styled.div`
  margin: 64px 0 0 0;
  min-height: calc(100vh - 64px);
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  @media (max-width: 992px) {
    margin-left: 0;
  }

  @media (max-width: 576px) {
    margin-left: 0px;
  }
`;

const StyledFooter = styled.footer`
  padding: 8px 24px;
  text-align: right;
  background: transparent;
  color: #fff;

  a {
    color: #fff !important;
    &:hover {
      color: var(--logoYellow) !important;
    }
  }
`;

/**
 * `ManagerLayout` is a React component that serves as the primary layout for the management interface.
 * It features a collapsible sidebar, a header, and a content area where child components are rendered through `Outlet`.
 * The component manages the state of the sidebar (collapsed or expanded) based on the browser window size.
 *
 * This component utilizes various styled components for layout and styling, and leverages `react-router-dom` for navigation,
 * `react-i18next` for internationalization, and `dayjs` for date operations.
 *
 * @component
 * @example
 * return (
 *   <ManagerLayout>
 *     <ChildComponent />
 *   </ManagerLayout>
 * )
 * @returns {React.ReactElement} A layout component for the manager interface
 */
export const ManagerLayout = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(window.innerWidth < 992);
  const { width, height } = useHandleResize();
  const [collapseWidth, setCollapseWidth] = useState(
    window.innerWidth < 576 ? 0 : 80
  );

  const onCollapse = (newCollapsed) => {
    setCollapsed(newCollapsed);
  };

  useEffect(() => {
    if (width < 576) {
      setCollapseWidth(0);
    } else {
      setCollapseWidth(80);
    }
  }, [width, height]);

  useEffect(() => {
    document
      .getElementById('mainContent')
      .scrollTo({ behavior: 'smooth', top: 0 });
  }, [location]);

  return (
    <MercuryAppContextProvider>
      <StyledLayout as={Layout}>
        {/* <UpdateAlert /> */}
        <StyledSider
          as={Sider}
          width={256}
          breakpoint="lg"
          trigger={null}
          collapsible
          collapsedWidth={collapseWidth}
          collapsed={collapsed}
          onCollapse={onCollapse}
        >
          <LogoDiv>
            <Link to="/">
              <img alt="Logo" src={collapsed ? LogoCollapsed : Logo} />
            </Link>
          </LogoDiv>
          <ManagerNavMenu setCollapsed={setCollapsed} />
        </StyledSider>
        <BgLayout>
          <HeaderLayout
            collapsed={collapsed}
            collapseSider={setCollapsed}
            collapseWidth={collapseWidth}
          />
          <StyledContent as={Content} id="mainContent">
            <Outlet />
            <StyledFooter as={Footer}>
              {`${t('global.title')} v${
                packageJson.version
              } ©${dayjs().year()} powered by `}
              <a href="https://strateg.in">Strateg.in</a>
            </StyledFooter>
          </StyledContent>
        </BgLayout>
      </StyledLayout>
    </MercuryAppContextProvider>
  );
};
