import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../utils/constants/adminRoutes';
import { ListResource } from '../../components';
import { ExtraButtons } from './ExtraButtons';
import { useColumns } from './columns';
import { EditChildModal } from './EditChildModal';
import { useAuthContext } from '../../contexts/AuthContext';
import { headers } from './headers';
import { useFormatter } from '../../utils/formatter';

/**
 * The ChildrenList component displays a list of children in a table format.
 *
 * @component
 * @returns {React.ReactNode} The ChildrenList component.
 */
export const ChildrenList = () => {
  const navigate = useNavigate();
  const { childrenDataFormatter } = useFormatter();
  const [refresh, setRefresh] = useState(false);
  const { daycare } = useAuthContext();
  const [filterStatusOptions, setFilterStatusOptions] = useState([]);
  const [modal, setModal] = useState();

  useEffect(() => {
    navigate(`${routes.CHILDREN}?p=1&pS=10&s=last_name`);
  }, []);

  const handleModalBehaviour = (record) => {
    const modalKey = Date.now();
    setModal(
      <EditChildModal
        key={modalKey}
        forceRefresh={refresh}
        setForceRefresh={setRefresh}
        record={record}
      />
    );
  };

  const columns = useColumns(refresh, setRefresh, handleModalBehaviour);

  const extraQuery = `contracts.status=${filterStatusOptions}&contracts.daycare=${daycare}`;

  return (
    <>
      <ListResource
        resourceName="children"
        customActionColumn
        columns={columns}
        populate="health,diet,authorizations"
        extraQuery={extraQuery}
        resourceModelName="Child"
        forceRefresh={refresh}
        setRefresh={setRefresh}
        withCreateButton={false}
        withImportButton={false}
        headers={headers}
        customExportUrl
        formatter={childrenDataFormatter}
        extraButtons={
          <ExtraButtons
            filterStatusOptions={filterStatusOptions}
            setFilterStatusOptions={setFilterStatusOptions}
          />
        }
      />
      {modal}
    </>
  );
};
