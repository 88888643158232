import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs } from 'antd';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { useParentInvoicesTabs } from './invoicesParentsTabs';
import { ContentCustom, PageHeaderCustom } from '../../../components';

/**
 * Component for displaying parent invoices.
 *
 * @component
 *
 * @returns {JSX.Element} ParentInvoices component.
 */
export const ParentInvoices = () => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [childrenInvoices, setChildrenInvoices] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const items = useParentInvoicesTabs(childrenInvoices, refresh, setRefresh);

  const getInvoices = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `invoices/parents`
      });
      setChildrenInvoices(data);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      await getInvoices();
    })();
  }, [refresh]);

  return (
    <>
      <PageHeaderCustom title={t('invoices.title')} />
      <ContentCustom>
        <Tabs type="card" items={items} />
      </ContentCustom>
    </>
  );
};
