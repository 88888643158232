/**
 * Generates parameters for opening a modal based on the type of event.
 *
 * @function
 *
 * @param {object} event - The event object containing information about the child's schedule and absences.
 * @returns {object} The parameters for opening the modal, including the type of event, child information, times ID, and event ID.
 */
const generateType = (event) => {
  switch (true) {
    case event.childSchedule.type === 'reference_day':
      return 'absences';
    default:
      return 'pickup_times';
  }
};

/**
 * Generates parameters for opening a modal based on the type of event.
 *
 * @function
 *
 * @param {object} event - The event object containing information about the child's schedule and absences.
 * @returns {object} The parameters for opening the modal, including the type of event, child information, times ID, and event ID.
 */
export const generateOpenModalParameters = (event) => {
  const type = generateType(event);

  const reservations = event?.reservations || [];
  const refDay = (event?.reference_day || []).filter((day) =>
    Object.keys(day).some((key) => key === 'start_time')
  );

  return {
    type,
    child: event?.child,
    timesID: event._id,
    eventID: event._id,
    previsionalPlanning: [...refDay, ...reservations]
  };
};
