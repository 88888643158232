import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  Modal,
  Radio,
  Row,
  Upload
} from 'antd';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  CheckOutlined,
  CloseOutlined,
  UploadOutlined
} from '@ant-design/icons';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { handleKeyPress } from '../../../../utils/formHandleKeyPress';

/**
 * A modal component for creating and updating documents.
 *
 * @component
 *
 * This component displays a form within a modal for the user to either create a new document
 * or update an existing one. It provides fields for uploading files, entering document details,
 * and setting visibility. The form data is submitted to the server, and the parent component's
 * state is updated accordingly.
 *
 * @param {Object} props - Component properties.
 * @param {string} props.purpose - The purpose of the modal ('create' or 'edit').
 * @param {Object} props.record - The document data record (for 'edit' mode).
 * @param {boolean} props.refresh - State indicating if a refresh is needed.
 * @param {Function} props.setRefresh - Function to toggle the refresh state.
 * @param {string} props.resourceName - The name of the resource for API endpoints.
 * @returns {JSX.Element} The modal component for creating or updating documents.
 */
export const CreateUpdateDocumentModal = ({
  purpose,
  record,
  setRefresh,
  refresh,
  resourceName
}) => {
  const { t } = useTranslation();
  const { dispatchAPI, daycare } = useAuthContext();
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [enums, setEnums] = useState();
  const { message } = useErrorMessage();
  const [isModalOpenedLocaly, setIsModalOpenedLocaly] = useState(true);

  const closeModal = () => {
    setIsModalOpenedLocaly(false);
  };

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `${resourceName}/enums`
      });
      setEnums(data);
    } catch (e) {
      message(e);
    }
  };

  const postDocument = async (body) => {
    setIsSubmitting(true);

    const formData = new FormData();

    fileList.forEach((file) => {
      formData.append('documents', file);
    });
    formData.append('values', JSON.stringify({ ...body, daycare }));

    try {
      await dispatchAPI('POST', {
        url: `${resourceName}`,
        body: formData
      });
      setIsSubmitting(false);
      setRefresh(!refresh);
      setIsModalOpenedLocaly(!isModalOpenedLocaly);
    } catch (e) {
      setIsSubmitting(false);

      message(e);
    }
  };

  const checkboxOptions = (enums?.visibility || []).map((option) => ({
    value: option,
    label: t(`communication.documents.list.type.${option}`)
  }));

  const radioOptions = (enums?.document_type || []).map((option) => ({
    value: option,
    label: t(`communication.documents.list.type.${option}`)
  }));

  const patchDocument = async (body) => {
    try {
      await dispatchAPI('PATCH', {
        url: `documents/${record._id}/`,
        body
      });
      setIsSubmitting(false);
      setRefresh(!refresh);
      setIsModalOpenedLocaly(!isModalOpenedLocaly);
    } catch (e) {
      setIsSubmitting(false);
      message(e);
    }
  };

  const handleSubmit = (body) => {
    (async () => {
      if (purpose === 'edit') {
        await patchDocument(body);
      } else {
        await postDocument(body);
      }
    })();
  };

  const draggerProps = {
    multiple: false,
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      const existingFileName = fileList.some(
        (document) => document.name === file.name
      );
      if (existingFileName) return message(t('existing_file_name'));
      const fileExtension = file.name.split('.').pop();
      if (['png', 'PNG', 'jpg', 'JPG', 'pdf', 'PDF'].includes(fileExtension)) {
        setFileList([...fileList, file]);
      } else {
        message('Not a PNG or JPG file.');
        return true;
      }
      return false;
    },
    fileList
  };

  useEffect(() => {
    (async () => {
      await getEnums();
    })();
  }, []);

  useEffect(() => {
    if (purpose === 'edit' && isModalOpenedLocaly && record) {
      form.setFieldsValue({
        ...record,
        start_visibility: dayjs(record?.start_visibility),
        ...(record?.end_visibility && {
          end_visibility: dayjs(record?.end_visibility)
        })
      });
    }
  }, [purpose, isModalOpenedLocaly, record]);

  return (
    <Modal
      title={t(`communication.documents.modal.title.${purpose}`)}
      open={isModalOpenedLocaly}
      onCancel={closeModal}
      footer={null}
      width={800}
    >
      <Form
        form={form}
        onFinish={handleSubmit}
        onKeyDown={handleKeyPress}
        scrollToFirstError
      >
        {purpose !== 'edit' && (
          <Form.Item
            name={['file']}
            label={t('children.documents.modal.file')}
            rules={[{ required: true }]}
          >
            <Upload {...draggerProps}>
              <Button icon={<UploadOutlined />}>
                {t('children.documents.modal.load_file')}
              </Button>
            </Upload>
          </Form.Item>
        )}
        <Form.Item
          name={['denomination']}
          rules={[{ required: true }]}
          label={t('communication.documents.modal.denomination')}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name={['document_type']}
          rules={[{ required: true }]}
          label={t('communication.documents.modal.document_type')}
        >
          <Radio.Group
            options={radioOptions}
            style={{ display: 'flex', flexDirection: 'column' }}
          />
        </Form.Item>
        <Form.Item
          name={['visibility']}
          rules={[{ required: true }]}
          label={t('communication.documents.modal.visibility')}
        >
          <Checkbox.Group
            options={checkboxOptions}
            style={{ display: 'flex', flexDirection: 'column' }}
          />
        </Form.Item>
        <Form.Item
          name={['start_visibility']}
          rules={[{ required: true }]}
          label={t('communication.documents.modal.start_visibility')}
        >
          <DatePicker format="DD/MM/YYYY" />
        </Form.Item>
        <Form.Item
          name={['end_visibility']}
          label={t('communication.documents.modal.end_visibility')}
        >
          <DatePicker format="DD/MM/YYYY" />
        </Form.Item>
        <Form.Item>
          <Row justify="end">
            <Button
              style={{ margin: '0 10px' }}
              type="link"
              danger
              onClick={closeModal}
            >
              {`${t('buttons.cancel')} `}
              <CloseOutlined />
            </Button>
            <Button type="primary" htmlType="submit" loading={isSubmitting}>
              {`${t('buttons.save')} `}
              <CheckOutlined />
            </Button>
          </Row>
        </Form.Item>
      </Form>
    </Modal>
  );
};

CreateUpdateDocumentModal.propTypes = {
  purpose: PropTypes.string.isRequired,
  resourceName: PropTypes.string.isRequired,
  refresh: PropTypes.bool,
  setRefresh: PropTypes.func,
  record: PropTypes.shape({
    _id: PropTypes.string,
    start_visibility: PropTypes.string,
    end_visibility: PropTypes.string
  })
};

CreateUpdateDocumentModal.defaultProps = {
  refresh: false,
  setRefresh: null,
  record: null
};
