import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Menu, Popover } from 'antd';
import { NavLink, useLocation } from 'react-router-dom';
import navMenuLogos from '../../utils/constants/navMenuLogos';
import { useHandleResize } from '../../utils/handleResize';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import {
  routes,
  subRoutes,
  pathSearches
} from '../../utils/constants/managerRoutes';

/**
 * `ManagerNavMenu` is a React component for rendering the navigation menu in the manager interface.
 * It dynamically generates menu items based on provided route configurations. This component is designed to work
 * within an Ant Design `Menu` component and uses `NavLink` for navigation.
 *
 * The menu auto-selects items based on the current route and can collapse itself on item selection
 * when the viewport width is below 992 pixels. It leverages `react-i18next` for internationalization.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.setCollapsed - A function to set the collapsed state of the navigation menu.
 * @example
 * return (
 *   <ManagerNavMenu setCollapsed={handleCollapse} />
 * )
 * @returns {React.ReactElement} The ManagerNavMenu component.
 */
export const ManagerNavMenu = ({ setCollapsed }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { daycare, dispatchAPI } = useAuthContext();
  const [configurationComplete, setConfigurationComplete] = useState(false);
  const { message } = useErrorMessage();
  const { width } = useHandleResize();
  const path = location?.pathname || '/';
  const activeKeys = path
    .replace('/', '')
    .split('/')
    .map((p) => `/${p}`);

  const getDaycareConfigurationComplete = async () => {
    try {
      setConfigurationComplete(false);
      const { data } = await dispatchAPI('GET', {
        url: `/daycares/${daycare}?fields=configuration_complete`
      });
      if (data.configuration_complete) {
        setConfigurationComplete(true);
      }
    } catch (error) {
      message(error);
    }
  };

  useEffect(() => {
    if (daycare)
      (async () => {
        await getDaycareConfigurationComplete();
      })();
  }, [daycare]);

  return (
    <Menu
      style={{
        padding: '16px 0',
        maxHeight: 'calc(100% - 191px)',
        overflowY: 'auto',
        overflowX: 'hidden'
      }}
      selectedKeys={activeKeys}
      defaultOpenKeys={activeKeys}
      mode="inline"
      onSelect={width < 992 ? () => setCollapsed(true) : () => {}}
      items={Object.entries(routes).map(([menuItem, p]) =>
        subRoutes[menuItem]
          ? {
              key: p,
              icon: navMenuLogos[menuItem],
              label: t(`basiclayout.menu.${menuItem.toLowerCase()}`),
              children: Object.entries(subRoutes[menuItem]).map(
                ([subMenuItem, subPath]) => ({
                  key: subPath,
                  disabled:
                    subPath === '' ||
                    (!configurationComplete &&
                      ![
                        '/users',
                        '/configurations',
                        '/global-settings'
                      ].includes(p)),
                  label:
                    configurationComplete ||
                    p === '/users' ||
                    p === '/configurations' ||
                    p === '/global-settings' ? (
                      <NavLink
                        to={`${p}${subPath}${
                          pathSearches[subMenuItem]
                            ? pathSearches[subMenuItem]
                            : ''
                        }`}
                      >
                        <span>
                          {t(
                            `basiclayout.submenu.${subMenuItem.toLowerCase()}`
                          )}
                        </span>
                      </NavLink>
                    ) : (
                      <Popover content="Pour accéder au menu, veuillez configurer la crèche">
                        <span>
                          {t(
                            `basiclayout.submenu.${subMenuItem.toLowerCase()}`
                          )}
                        </span>
                      </Popover>
                    )
                })
              )
            }
          : {
              key: p,
              disabled:
                (!configurationComplete &&
                  !['/users', '/configurations', '/global-settings'].includes(
                    p
                  )) ||
                p === '',
              label:
                configurationComplete ||
                p === '/users' ||
                p === '/configurations' ||
                p === '/global-settings' ? (
                  <NavLink
                    to={`${p}${
                      pathSearches[menuItem] ? pathSearches[menuItem] : ''
                    }`}
                  >
                    {navMenuLogos[menuItem]}
                    <span>
                      {t(`basiclayout.menu.${menuItem.toLowerCase()}`)}
                    </span>
                  </NavLink>
                ) : (
                  <Popover content="Pour accéder au menu, veuillez configurer la crèche">
                    <span>
                      {t(`basiclayout.menu.${menuItem.toLowerCase()}`)}
                    </span>
                  </Popover>
                )
            }
      )}
    />
  );
};

ManagerNavMenu.propTypes = {
  setCollapsed: PropTypes.func.isRequired
};
