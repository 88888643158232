import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { Modal, Divider } from 'antd';
import { CreateUpdateContainerModal } from '../../../components/CreateUpdateContainer/CreateUpdateContainerModal';
import { useFields } from './fields';

const CustomerAccountModal = ({
  isModalOpen,
  enums,
  closeModal,
  setRefresh,
  customerAccountId,
  purpose
}) => {
  const { t } = useTranslation();

  const { fields, isFieldsLoading } = useFields(customerAccountId, enums);

  const config = {
    onGetResource: {
      setFields: (data) => ({
        ...data,
        operation_date: data.operation_date && dayjs(data.operation_date)
      })
    }
  };

  return (
    <Modal
      centered
      open={isModalOpen}
      onCancel={closeModal}
      title={`${t(`customer-account.modal.title`)}`}
      width={700}
      footer={false}
    >
      <Divider />
      <CreateUpdateContainerModal
        fields={fields}
        loadingFields={isFieldsLoading}
        purpose={purpose}
        baseUrl="customer-accounts"
        resource="customer-account"
        closeModal={closeModal}
        id={customerAccountId}
        withGetResource
        config={config}
        setRefresh={setRefresh}
        withoutPageHeaderCustom
      />
      <Divider />
    </Modal>
  );
};

CustomerAccountModal.propTypes = {
  customerAccountId: PropTypes.string,
  isModalOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  purpose: PropTypes.string,
  enums: PropTypes.shape({}),
  setRefresh: PropTypes.func.isRequired
};

CustomerAccountModal.defaultProps = {
  customerAccountId: null,
  purpose: null,
  enums: {}
};

export default CustomerAccountModal;
