/* eslint-disable import/no-extraneous-dependencies */
// This rule is disabled because it is just a bug with eslint on MacOS
// The react-color is well installed in the dev dependencies
// and it is working good
import { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Form, Input, Popover, Row, Col } from 'antd';
import { CirclePicker } from 'react-color';

/**
 * @description EditableCell component. Displays the editable cell in the children groups table
 * @component
 * @param {Boolean} editing Whether the cell is in editing mode or not
 * @param {String} dataIndex The data index of the cell
 * @param {String} inputType The input type of the cell
 * @param {String} tag_color The tag color of the cell
 * @param {String} colorPicked The color picked by the user
 * @param {Function} setColorPicked The function to set the color picked by the user
 * @param {Object} ageRange The age range of the cell
 * @param {Function} setAgeRange The function to set the age range of the cell
 * @param {JSX} children The children of the cell
 * @returns {JSX} The editable cell
 */
export const EditableCell = ({
  editing,
  dataIndex,
  inputType,
  tag_color,
  colorPicked,
  setColorPicked,
  ageRange,
  setAgeRange,
  children,
  ...restProps
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  let inputNode;

  const hide = () => {
    setOpen(false);
  };

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  switch (inputType) {
    case 'age_range':
      inputNode = (
        <Input.Group>
          <Row gutter={8}>
            <Col span={5}>
              <Input
                allowClear
                addonAfter={t('year(s)')}
                defaultValue={ageRange?.min}
                onChange={({ target: { value } }) =>
                  setAgeRange({ ...ageRange, min: value })
                }
                type="number"
                placeholder={t(
                  'global_settings.children_groups.placeholders.min_age'
                )}
              />
            </Col>
            <Col span={5}>
              <Input
                allowClear
                addonAfter={t('year(s)')}
                defaultValue={ageRange?.max}
                onChange={({ target: { value } }) =>
                  setAgeRange({ ...ageRange, max: value })
                }
                type="number"
                placeholder={t(
                  'global_settings.children_groups.placeholders.max_age'
                )}
              />
            </Col>
          </Row>
        </Input.Group>
      );
      break;
    case 'tag_color':
      inputNode = (
        <Popover
          content={
            <CirclePicker
              onChange={(selectedColor) => {
                setColorPicked(selectedColor.hex);
                hide();
              }}
            />
          }
          trigger="click"
          open={open}
          onOpenChange={handleOpenChange}
        >
          <div
            style={{
              width: '20px',
              height: '20px',
              borderRadius: '50%',
              backgroundColor: colorPicked || tag_color
            }}
          />
        </Popover>
      );
      break;
    default:
      inputNode = <Input />;
      break;
  }

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0
          }}
          rules={[
            {
              required: true,
              message: t('warnings.message.fill')
            }
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

EditableCell.propTypes = {
  editing: PropTypes.bool,
  dataIndex: PropTypes.string,
  inputType: PropTypes.string,
  tag_color: PropTypes.string,
  colorPicked: PropTypes.string,
  setColorPicked: PropTypes.func,
  ageRange: PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number
  }),
  setAgeRange: PropTypes.func,
  children: PropTypes.node.isRequired
};

EditableCell.defaultProps = {
  editing: false,
  dataIndex: '',
  inputType: '',
  tag_color: '',
  colorPicked: '',
  ageRange: {},
  setColorPicked: () => {},
  setAgeRange: () => {}
};
