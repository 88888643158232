import { useTranslation } from 'react-i18next';
import { Button, Checkbox, Select, Form, Row, Upload } from 'antd';
import {
  CheckOutlined,
  CloseOutlined,
  UploadOutlined
} from '@ant-design/icons';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { draggerProps } from './utils';

const { Option } = Select;

/**
 * DocumentTab is a React component that provides a user interface for uploading documents.
 * It includes a form that allows users to select children and upload files.
 *
 * @component
 *
 * The component uses the `useTranslation` hook for internationalization, `useAuthContext` for dispatching API requests,
 * and `useErrorMessage` for error handling. It also manages the file list for the upload and the form submission process.
 *
 * @param {Object} props - The props for the component.
 * @param {Function} props.setScreen - Function to set the current screen or view in the parent component.
 * @param {Array} props.parentsChildren - List of children related to the parent, used for selecting document recipients.
 *
 * @returns {JSX.Element} A form component for uploading documents.
 *
 * @propTypes {Object} - Prop types for the component.
 * @propTypes.setScreen {Function} - Function to set the current screen or view in the parent component.
 * @propTypes.parentsChildren {Array} - List of children related to the parent, used for selecting document recipients.
 */
export const DocumentTab = ({ setScreen, parentsChildren }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [fileList, setFileList] = useState([]);

  const postDocument = async (body) => {
    setIsSubmitting(true);

    const formData = new FormData();

    fileList.forEach((file) => {
      formData.append('documents', file);
    });
    formData.append('values', JSON.stringify(body));

    try {
      await dispatchAPI('PATCH', {
        url: `children/post-documents`,
        body: formData
      });
      setIsSubmitting(false);
      setScreen(0);
    } catch (e) {
      setIsSubmitting(false);
      message(e);
    }
  };

  const options = (parentsChildren || []).map((child) => ({
    label: child.first_name,
    value: child._id
  }));

  return (
    <Form form={form} onFinish={postDocument}>
      <Form.Item
        name={['children']}
        label={t('parents.home.children')}
        required
      >
        <Checkbox.Group options={options} />
      </Form.Item>
      <Form.Item name={['type']} label={t('parents.home.type')} required>
        <Select>
          <Option value="ATTESTATION">
            {t('parents.home.options.proof-of-absence')}
          </Option>
          <Option value="OTHERS">
            {t('parents.home.options.other-document')}
          </Option>
        </Select>
      </Form.Item>
      <Form.Item
        name={['file']}
        label={t('children.documents.modal.file')}
        required
      >
        <Upload {...draggerProps(fileList, setFileList, message, t)}>
          <Button icon={<UploadOutlined />}>
            {t('children.documents.modal.load_file')}
          </Button>
        </Upload>
      </Form.Item>
      <Form.Item>
        <Row justify="end">
          <Button
            style={{ margin: '0 10px' }}
            type="link"
            danger
            onClick={() => setScreen(0)}
          >
            {`${t('buttons.cancel')} `}
            <CloseOutlined />
          </Button>
          <Button type="primary" htmlType="submit" loading={isSubmitting}>
            {`${t('buttons.save')} `}
            <CheckOutlined />
          </Button>
        </Row>
      </Form.Item>
    </Form>
  );
};

DocumentTab.propTypes = {
  setScreen: PropTypes.func,
  parentsChildren: PropTypes.arrayOf(
    PropTypes.shape({
      first_name: PropTypes.string
    })
  )
};

DocumentTab.defaultProps = {
  setScreen: null,
  parentsChildren: null
};
