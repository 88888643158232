import { useTranslation } from 'react-i18next';
import { Tabs } from 'antd';
import { PageHeaderCustom } from '../../components/PageHeader/PageHeader';
import { ContentCustom } from '../../components/ContentCustom/ContentCustom';
import { useTabItems } from './utils/useTabItems';
import { useCommunicationContext } from '../../contexts/CommunicationContext';

/**
 * Communication component for rendering a tabbed communication interface.
 *
 * @component
 *
 * This component utilizes the `useTranslation` hook for localization and the `useTabItems` custom hook
 * to generate tab items for different communication aspects. It renders a page header and tabbed content.
 *
 * @function Communication
 * @returns {JSX.Element} The communication section with tabbed navigation.
 */
export const Communication = () => {
  const { t } = useTranslation();
  const { activeTab, setActiveTab } = useCommunicationContext();

  const items = useTabItems(t);

  return (
    <>
      <PageHeaderCustom title={t('communication.title')} />
      <ContentCustom>
        <Tabs
          type="card"
          items={items}
          defaultActiveKey={activeTab}
          onChange={(tabKey) => setActiveTab(tabKey)}
        />
      </ContentCustom>
    </>
  );
};
