import React, { useState } from 'react';
import { Flex, Switch } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../contexts/AuthContext';
import useColumns from './columns';
import { ListResource } from '../../../components';
import InvoicingContext from '../InvoicingContext';
import { ImportXlsxButton } from '../../../components/ImportXlsxButton';
import { AccountingExportButton } from '../../../components/AccountingExportButton/AccountingExportButton';

const Rejections = () => {
  const { daycare } = useAuthContext();
  const { t } = useTranslation();
  const { deleteRejection } = InvoicingContext();
  const [forceRefresh, setForceRefresh] = useState(false);
  const [isArchived, setIsArchived] = useState(false);

  const columns = useColumns(deleteRejection, setForceRefresh);

  return (
    <ListResource
      resourceName="rejections"
      resourceModelName="Rejections"
      extraQuery={`daycare=${daycare}&status=${
        isArchived ? 'DELETED' : 'ACTIVE'
      }`}
      withUploadButton={false}
      withCreateButton={false}
      archivedButton={false}
      columns={columns}
      customActionColumn
      forceRefresh={forceRefresh}
      extraButtons={
        <Flex align="center" gap="small">
          <Switch
            checkedChildren={t('buttons.archived')}
            unCheckedChildren={t('buttons.archived')}
            onChange={() => setIsArchived(!isArchived)}
          />
          <AccountingExportButton objective="rejections" />
          <ImportXlsxButton
            importUrl="/rejection"
            daycare={daycare}
            setForceRefresh={setForceRefresh}
            refresh={forceRefresh}
          />
        </Flex>
      }
    />
  );
};

export default Rejections;
