import { useTranslation } from 'react-i18next';

/**
 * @description
 * useListContent is a hook that returns the content of the parent card.
 * It is used in the ParentCard component.
 *
 * @param {Object} data - The data of the parent
 * @returns {Array} The content of the parent card
 */
export const useListContent = (data = {}) => {
  const {
    first_name,
    last_name,
    email,
    mobile_phone_number,
    landline_phone_number,
    address,
    social_security_system,
    employment_contract,
    company
  } = data;
  const { t } = useTranslation();

  const headerListContent = [
    {
      label: '',
      content: `${first_name} ${last_name}`,
      span: 3
    },
    {
      label: 'pre-registrations.show.parent.email',
      content: email,
      span: 1
    },
    {
      label: 'pre-registrations.show.parent.mobile_phone_number',
      content: mobile_phone_number?.number,
      span: 1
    }
  ];

  const listContent = [
    {
      label: 'pre-registrations.show.parent.address',
      content: `${address?.number} ${address?.street} ${address?.postal_code} ${address?.city}`,
      span: 1
    },
    {
      label: 'pre-registrations.show.parent.state',
      content: address?.state,
      span: 1
    },
    {
      label: 'pre-registrations.show.parent.landline_phone_number',
      content: landline_phone_number?.number,
      span: 1
    },
    {
      label: 'pre-registrations.show.parent.social_security_system',
      content: social_security_system
        ? t(`pre-registrations.form.security_systems.${social_security_system}`)
        : '-',
      span: 1
    }
  ];

  if (employment_contract && company) {
    const {
      name,
      address: companyAddress,
      phone_number,
      email: companyEmail
    } = company;

    listContent.push(
      {
        label: 'pre-registrations.show.parent.employment_contract',
        content: t('yes'),
        span: 1
      },
      {
        label: 'pre-registrations.show.parent.company.name',
        content: name || '-',
        span: 1
      },
      {
        label: 'pre-registrations.show.parent.company.address',
        content: `${companyAddress?.number || ''} ${
          companyAddress?.street || ''
        } ${companyAddress?.postal_code || ''} ${companyAddress?.city || ''}`,
        span: 1
      },
      {
        label: 'pre-registrations.show.parent.company.state',
        content: companyAddress?.state || '-',
        span: 1
      },
      {
        label: 'pre-registrations.show.parent.company.phone_number',
        content: phone_number?.number || '-',
        span: 1
      },
      {
        label: 'pre-registrations.show.parent.company.email',
        content: companyEmail || '-',
        span: 1
      }
    );
  }

  return { headerListContent, listContent };
};
