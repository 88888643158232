import { Button, Flex, Popconfirm, Space, Timeline, Table } from 'antd';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import dayjs from 'dayjs';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useFollowUpContext } from '../../contexts/FollowUpContext';
import { activities as activitiesArray } from '../Configuration/Activities/activitiesArray';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';

/**
 * A component for displaying a timeline of follow-up activities.
 *
 * This component renders a list of follow-up activities in a timeline format.
 * Each activity is displayed with its time and an edit icon, which when clicked,
 * triggers the provided `handleModalBehaviour` function with the activity details.
 * The component also updates the current group in the FollowUpContext when the `groupID` changes.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.handleModalBehaviour - A function to handle modal interactions, such as editing an activity.
 * @param {string} props.groupID - The ID of the group for which follow-up activities are displayed.
 * @param {Object[]} props.activities - An array of activity objects to display in the timeline.
 * @returns {JSX.Element} The JSX rendering of the follow-up activities timeline.
 *
 * @example
 * const handleEdit = (activity, mode) => {
 *   // handle edit action
 * };
 * return (
 *   <FollowUp
 *     handleModalBehaviour={handleEdit}
 *     groupID="1"
 *     activities={[{ date: '2021-01-01', activity: { type: 'type1', label: 'Activity 1' } }]}
 *   />
 * )
 */
export const FollowUp = ({ handleModalBehaviour, groupID, activities }) => {
  const { setGroup, refresh, setRefresh } = useFollowUpContext();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();

  const deleteActivity = async (id) => {
    try {
      await dispatchAPI('DELETE', {
        url: `follow-ups/${id}`
      });
      setRefresh(!refresh);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    if (groupID) {
      setGroup(groupID);
    }
  }, [groupID]);

  const items = (activities || []).map((activity) => {
    const activityChildren = activitiesArray.find(
      (activityItem) => activityItem.resourceName === activity.activity.type
    );

    return {
      label: <Flex vertical>{dayjs(activity.date).format('HH:mm')}</Flex>,
      dot: <Button shape="circle">{activityChildren?.icon}</Button>,
      children: (
        <Flex align="center" gap="small" justify="space-between">
          <Space>{activity.activity.label}</Space>
          <Flex>
            <Button
              type="link"
              onClick={() => handleModalBehaviour(activity, 'edit')}
            >
              <EditOutlined />
            </Button>
            <Popconfirm
              title={t('datatable.column.action.delete.title')}
              okText={t('datatable.column.action.delete.ok')}
              okButtonProps={{ type: 'danger' }}
              cancelText={t('datatable.column.action.delete.cancel')}
              onConfirm={() => deleteActivity(activity?._id)}
              icon={<DeleteOutlined />}
            >
              <Button type="link" danger>
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </Flex>
        </Flex>
      )
    };
  });

  return items.length > 0 ? (
    <Timeline items={items} mode="left" className="followup-timeline" />
  ) : (
    <Table dataSource={[]} />
  );
};

FollowUp.propTypes = {
  handleModalBehaviour: PropTypes.func,
  groupID: PropTypes.string,
  activities: PropTypes.arrayOf(PropTypes.shape({}))
};

FollowUp.defaultProps = {
  handleModalBehaviour: null,
  groupID: undefined,
  activities: []
};
