import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Flex, Button, Typography } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import { ContentCustom } from '../../components';

const { Text, Title } = Typography;

/**
 * Renders the success page for signatures.
 *
 * @returns {JSX.Element} The success page component.
 */
export const Success = ({ data }) => {
  const { t } = useTranslation();
  const { contract_number, start_date } = data;

  return (
    <ContentCustom>
      <Flex align="center" justify="center" vertical style={{ padding: 32 }}>
        <Flex align="center" justify="center" gap="large">
          <CheckOutlined style={{ fontSize: 48, color: 'var(--addColor)' }} />
          <Title>{t('signatures.success')}</Title>
        </Flex>
        <Text>{t('signatures.success_message', { contract_number })}</Text>
        <Text>{t('signatures.start_date_message', { start_date })}</Text>
        <Button style={{ marginTop: 16 }} onClick={() => window.close()}>
          {t('buttons.close_page')}
        </Button>
      </Flex>
    </ContentCustom>
  );
};

Success.propTypes = {
  data: PropTypes.shape({
    contract_number: PropTypes.string,
    start_date: PropTypes.string
  })
};

Success.defaultProps = {
  data: {
    contract_number: '',
    start_date: ''
  }
};
