import { Col, Modal, Row, Spin, message as antDMessage } from 'antd';

/**
 * Generates a PDF document of a specified type for a pensioner and initiates its download.
 *
 * @async
 * @param {function} dispatchAPI - The function used for making API requests.
 * @param {function} message - A function to handle error messages or notifications.
 * @param {string} documentType - The type of document to generate.
 * @param {string} recordID - The ID of the supervision for which the document is generated.
 * @param {string} filterID - ID allowing to filter arrays in API process.
 * @param {function} t - Translation function.
 * @param {number} selectedMonth - Month selected for PAJE Attestation.
 * @returns {Promise<void>} A Promise that resolves once the document is generated and downloaded.
 * @throws {Error} If an error occurs during the generation or download process.
 *
 * @example
 * // Usage example:
 * generateDocumentsOld(dispatchAPI, showMessage, 'someDocumentType', '12345')
 *   .catch((error) => {
 *     console.error('Error:', error);
 *   });
 */
export const generateDocumentsOld = async (
  dispatchAPI,
  message,
  documentType,
  recordID,
  filterID,
  t,
  selectedMonth
) => {
  const modal = Modal.info({
    title: null,
    content: (
      <Row style={{ flexDirection: 'column' }} gutter={[8, 8]}>
        <Col align="center" justify="center" span={24}>
          <Spin />
        </Col>
        <Col align="center" justify="center" span={24}>
          {t('children.documents.modal.document_being_generated')}
        </Col>
      </Row>
    ),
    footer: null,
    maskClosable: false
  });
  try {
    const { headers, data } = await dispatchAPI('POST', {
      url: `template-documents/generate/${documentType}/${recordID}/${filterID}/${selectedMonth}`,
      responseType: 'arraybuffer'
    });

    // Create a Blob object from the ArrayBuffer
    const blob = new Blob([data], { type: 'application/pdf' });

    // Create a URL from the Blob object²
    const url = URL.createObjectURL(blob);

    // Use the URL to create a download link or display the PDF in the browser
    const link = document.createElement('a');
    link.href = url;
    link.download = headers['content-disposition'];
    link.click();

    // Clean up the URL object

    antDMessage.success(t('children.documents.modal.success'));
    return URL.revokeObjectURL(url);
  } catch (e) {
    if (e.response.status === 400 && documentType === 'MEALS_MENU') {
      return message('NO_MEALS_CREATED_ON_THIS_WEEK');
    }
    return message(e);
  } finally {
    modal.destroy();
  }
};
