import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from './AuthContext';
import { useErrorMessage } from '../utils/errorMessage';
import { CafEstimation } from '../models/CafEstimation';
import { Hypotheses } from '../models/Hypotheses';
import { generateTableData } from '../routes/analysis/TabsContent/Indicators/utils';

export const AnalysisContext = createContext({ isValid: false });

/**
 * Provides a context for managing and accessing analysis data related to daycare operations.
 *
 * This context provider component fetches and stores various pieces of analysis data, such as carried out hours, billed hours, and occupation rate, related to a specific daycare. It also calculates additional information like total working days and theoretical capacity based on the daycare's operating schedule, closure periods, and bank holidays. The context facilitates the sharing of this analysis data and functions to update it across components that consume this context.
 *
 * @param {Object} props - Component properties.
 * @param {ReactNode} props.children - Child components that can access the context.
 * @returns {JSX.Element} A provider component that allows child components to access and update analysis data related to daycare operations.
 */

export const AnalysisContextProvider = ({ children }) => {
  const { dispatchAPI, daycare } = useAuthContext();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const [refresh, setRefresh] = useState(false);
  const [dateFilter, setDateFilter] = useState(dayjs());
  const [hypothesesBody, setHypothesesBody] = useState();
  const [daycareInfos, setDaycareInfos] = useState();
  const [tabsActiveKey, setTabsActiveKey] = useState('1');
  const [CAFStatement, setCAFStatement] = useState();
  const [CAFEstimation, setCAFEstimation] = useState();
  const [CAFStatementMode, setCAFStatementMode] = useState('previsional');
  const [tablesData, setTablesData] = useState({
    incomes: [],
    expenses: []
  });
  const [dataLoading, setDataLoading] = useState({
    indicators: true,
    hypotheses: true,
    caf_estimation: true,
    caf_statement: true
  });
  const [graphsData, setGraphsData] = useState({
    expensesData: {
      observed: [],
      prevision: []
    },
    incomesData: {
      observed: [],
      prevision: []
    },
    carriedOutHours: {
      month: {
        objective: [],
        real: []
      },
      cumulate: []
    },
    billedHours: {
      month: {
        objective: [],
        real: []
      },
      cumulate: []
    },
    occupationRate: {
      objective: [],
      observed: []
    }
  });
  const [open, setOpen] = useState(true);

  const updateDataLoadingState = (key, boolean) => {
    setDataLoading((prevState) => {
      const updatedState = { ...prevState };
      updatedState[key] = boolean;

      return updatedState;
    });
  };

  const fetchAnalysisDatas = useCallback(async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `daycares/${daycare}/analysis-data${
          dateFilter ? `?date=${dateFilter}` : ''
        }`
      });
      setGraphsData(data);
    } catch (e) {
      message(e);
    } finally {
      updateDataLoadingState('indicators', false);
    }
  }, [refresh, dateFilter, daycare]);

  const fetchCAFEstimation = useCallback(async () => {
    updateDataLoadingState('caf_estimation', true);
    updateDataLoadingState('caf_statement', true);
    try {
      const { data } = await CafEstimation.getCafEstimation(
        daycare,
        dateFilter
      );
      setCAFEstimation(data?.estimation);
      setTablesData((prev) => {
        const updatedTableData = { ...prev };
        updatedTableData.expenses = generateTableData(
          t,
          'expenses',
          data?.graphTable
        );
        updatedTableData.incomes = generateTableData(
          t,
          'incomes',
          data?.graphTable
        );

        return updatedTableData;
      });
      updateDataLoadingState('caf_estimation', false);
      setCAFStatement(data?.statement);
      updateDataLoadingState('caf_statement', false);
    } catch (e) {
      message(e);
      updateDataLoadingState('caf_estimation', false);
      updateDataLoadingState('caf_statement', false);
    }
  }, [refresh, dateFilter, daycare]);

  const fetchDaycareInfos = async () => {
    try {
      const { data } = await Hypotheses.getOne(daycare);
      setDaycareInfos(data);
    } catch (e) {
      message(e);
    } finally {
      updateDataLoadingState('hypotheses', false);
    }
  };

  useEffect(() => {
    (async () => {
      await fetchAnalysisDatas();
    })();
  }, [fetchAnalysisDatas]);

  useEffect(() => {
    (async () => {
      await fetchCAFEstimation();
    })();
  }, [fetchCAFEstimation]);

  useEffect(() => {
    (async () => {
      await fetchDaycareInfos();
    })();
  }, [daycare, refresh]);

  useEffect(() => {
    const allValuesFalse = Object.values(dataLoading).every(
      (value) => value === false
    );
    const someValuesTrue = Object.values(dataLoading).some(
      (value) => value === true
    );
    if (someValuesTrue) {
      setOpen(true);
    }
    if (allValuesFalse) {
      setOpen(false);
    }
  }, [dataLoading]);

  return (
    <AnalysisContext.Provider
      value={{
        hypothesesBody,
        setHypothesesBody,
        refresh,
        setRefresh,
        dateFilter,
        setDateFilter,
        daycareInfos,
        tabsActiveKey,
        setTabsActiveKey,
        graphsData,
        CAFStatement,
        setCAFStatement,
        CAFStatementMode,
        setCAFStatementMode,
        CAFEstimation,
        setCAFEstimation,
        dataLoading,
        open,
        setOpen,
        tablesData
      }}
    >
      {children}
    </AnalysisContext.Provider>
  );
};

export const useAnalysisContext = () => {
  const context = useContext(AnalysisContext);
  if (context === undefined)
    throw new Error('Context must be used within a context provider');
  return context;
};
