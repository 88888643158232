import React, { useState, createContext, useContext, useEffect } from 'react';
import dayjs from 'dayjs';
import { useAuthContext } from './AuthContext';
import { useErrorMessage } from '../utils/errorMessage';

const dateFormat = 'DD/MM/YYYY';
const monthFormat = 'MM-YYYY';

/**
 * @function getYearRange
 * @description Get the year range for the initial values of the RangePicker used in the Selects component.
 * @returns {array} Array with the start and end months of the year.
 */
const getYearRange = () => {
  const start = dayjs().startOf('year');
  const end = dayjs().endOf('year');
  return [start, end];
};

export const HomeContext = createContext();

export const HomeContextProvider = ({ children }) => {
  const { dispatchAPI, daycare, user } = useAuthContext();
  const { message } = useErrorMessage();

  const [staffRatioCardLoading, setStaffRatioCardLoading] = useState(false);
  const [attendanceCardLoading, setAttendanceCardLoading] = useState(false);
  const [childrenCardLoading, setChildrenCardLoading] = useState(false);

  const [daycareInfos, setDaycareInfos] = useState({});
  const [daycares, setDaycares] = useState([]);
  const [rangeMonths, setRangeMonths] = useState(getYearRange());
  const [selectedDaycare, setSelectedDaycare] = useState(
    user?.role === 'users:MANAGER' ? { _id: daycare } : 'all'
  );
  const [turnoverData, setTurnoverData] = useState(null);
  const [totalTurnoverAmount, setTotalTurnoverAmount] = useState(0);
  const [invoicesData, setInvoicesData] = useState(null);
  const [closedDays, setClosedDays] = useState();
  const [selectedDayStaffRatio, setSelectedDayStaffRatio] = useState(dayjs());
  const [selectedDayChildrenCard, setSelectedDayChildrenCard] = useState(
    dayjs()
  );
  const [timeSlots, setTimeSlots] = useState([]);
  const [statsPerWeek, setStatsPerWeek] = useState();
  const [pickupTimesErrors, setPickupTimesErrors] = useState();
  const [selectedWeek, setSelectedWeek] = useState(
    Array.from({ length: 5 }, (_, i) => dayjs().startOf('week').add(i, 'day'))
  );
  const [mealsSelectedWeek, setMealsSelectedWeek] = useState(
    Array.from({ length: 5 }, (_, i) => dayjs().startOf('week').add(i, 'day'))
  );

  const [dataLoading, setDataLoading] = useState({
    mealsCard: true,
    attendance: true,
    staff_ratio: true,
    pickupTimes: true,
    invoice: true,
    turnover: true
  });

  const [startOfWeekDate, setStartOfWeek] = useState(dayjs().startOf('week'));
  const [endOfWeekDate, setEndOfWeek] = useState(dayjs().endOf('week'));
  const [mealsCardLoading, setMealsCardLoading] = useState(false);
  const [selectedGroup] = useState('all');

  const onChange = (date) => {
    const startOfWeek = dayjs(date)
      .startOf('week')
      .utcOffset(0)
      .year(date.year())
      .month(date.month())
      .date(date.date());

    setStartOfWeek(startOfWeek);

    const endOfWeek = dayjs(date)
      .endOf('week')
      .utcOffset(0)
      .year(date.year())
      .month(date.month())
      .date(date.date());

    setEndOfWeek(endOfWeek);
    setMealsSelectedWeek(
      Array.from({ length: 5 }, (_, i) => startOfWeek.add(i, 'day'))
    );
  };

  const [counters, setCounters] = useState({
    countersCollation: undefined,
    countersLunch: undefined,
    counterSnack: undefined
  });

  const [open, setOpen] = useState(true);

  const updateDataLoadingState = (key, boolean) => {
    setDataLoading((prevState) => {
      const updatedState = { ...prevState };
      updatedState[key] = boolean;

      return updatedState;
    });
  };

  const getClosedDays = (d) => {
    const days = [];
    if (d?.bank_holidays?.length) {
      d?.bank_holidays.forEach((holiday) =>
        days.push(dayjs(holiday.date).format('DD/MM/YYYY'))
      );
    }
    if (d?.closure_period?.length) {
      d?.closure_period.forEach((period) => {
        const start = dayjs(period.start_date);
        const end = dayjs(period.end_date);
        let currentDay = start.clone();
        while (currentDay.isBefore(end)) {
          days.push(currentDay.format('DD/MM/YYYY'));
          currentDay = currentDay.clone().add(1, 'day');
        }
      });
    }
    setClosedDays(days);
  };

  const getDaycares = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/daycares?fields=-logo,-stamp,-manager_signature,-photo'
      });
      setDaycares(data);
      setDaycareInfos(data.find((d) => d._id === daycare));
    } catch (e) {
      message(e);
    }
  };

  const getChildren = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/time-recorder/events?daycare=${daycare}&group=all&status=ACTIVE&archived=false&${
          selectedDayStaffRatio
            ? `&date>=${dayjs(selectedDayStaffRatio)
                .startOf('day')
                .toISOString()}&date<=${dayjs(selectedDayStaffRatio)
                .endOf('day')
                .toISOString()}`
            : ''
        }&populate=contracts`
      });
      setTimeSlots(data.totalChildrenByTimeslot);
    } catch (error) {
      message(error);
    } finally {
      updateDataLoadingState('staff_ratio', false);
    }
  };

  const getChildrenPerWeek = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/time-recorder/events_week?daycare=${daycare}&group=all&status=ACTIVE&archived=false&${
          selectedWeek.length
            ? `&date=${dayjs(selectedWeek[0]).add(1, 'hour').toISOString()}`
            : ''
        }&populate=contracts`
      });
      setStatsPerWeek(data);
    } catch (error) {
      message(error);
    } finally {
      updateDataLoadingState('attendance', false);
    }
  };

  const getPickupTimesErrors = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/pickup-times/events/errors?daycare=${daycare}&group=all&status=ACTIVE&archived=false&${
          selectedDayChildrenCard
            ? `&date>=${dayjs(selectedDayChildrenCard)
                .startOf('day')
                .toISOString()}&date<=${dayjs(selectedDayChildrenCard)
                .endOf('day')
                .toISOString()}`
            : ''
        }&populate=contracts`
      });
      setPickupTimesErrors(data);
    } catch (error) {
      message(error);
    } finally {
      updateDataLoadingState('pickupTimes', false);
    }
  };

  const getTurnoverData = async () => {
    try {
      setTurnoverData(null);
      const { data } = await dispatchAPI('GET', {
        url: `invoices/turnover?daycare=${
          selectedDaycare._id || 'all'
        }&start_date=${dayjs(rangeMonths[0])
          .add(1, 'day')
          .toISOString()}&end_date=${dayjs(rangeMonths[1])
          .endOf('month')
          .toISOString()}`
      });
      if (data.listPaymentsByMonth) setTurnoverData(data.listPaymentsByMonth);
      if (data.totalAmount) setTotalTurnoverAmount(data.totalAmount);
    } catch (error) {
      message(error);
    } finally {
      updateDataLoadingState('turnover', false);
    }
  };

  const getInvoicesData = async () => {
    try {
      setInvoicesData(null);
      const { data } = await dispatchAPI('GET', {
        url: `invoices/amounts_by_status?daycare=${
          selectedDaycare._id || 'all'
        }&start_date=${dayjs(rangeMonths[0])
          .add(1, 'day')
          .toISOString()}&end_date=${dayjs(rangeMonths[1])
          .endOf('month')
          .toISOString()}`
      });
      setInvoicesData(data);
    } catch (error) {
      message(error);
    } finally {
      updateDataLoadingState('invoice', false);
    }
  };

  const fetchMeals = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/daycares/meals?daycare=${daycare}&group=${selectedGroup}&status=ACTIVE&archived=false&date>=${startOfWeekDate.toISOString()}&date<=${endOfWeekDate.toISOString()}&populate=contracts`
      });

      setCounters({
        countersCollation: data.counterCollation,
        countersLunch: data.counterLunch,
        countersSnack: data.counterSnack
      });
    } catch (e) {
      message(e.message);
    } finally {
      updateDataLoadingState('mealsCard', false);
    }
  };

  useEffect(() => {
    (async () => {
      setMealsCardLoading(true);
      await fetchMeals();
      setMealsCardLoading(false);
    })();
  }, [daycare, mealsSelectedWeek]);

  useEffect(() => {
    (async () => {
      await getDaycares();
    })();
  }, []);

  useEffect(() => {
    (async () => {
      setStaffRatioCardLoading(true);
      await getChildren();
      setStaffRatioCardLoading(false);
    })();
  }, [daycare, selectedDayStaffRatio]);

  useEffect(() => {
    const allValuesTrue = Object.values(dataLoading).every(
      (value) => value === false
    );
    const someValuesFalse = Object.values(dataLoading).some(
      (value) => value === true
    );
    if (someValuesFalse) {
      setOpen(true);
    }
    if (allValuesTrue) {
      setOpen(false);
    }
  }, [dataLoading]);

  useEffect(() => {
    (async () => {
      setAttendanceCardLoading(true);
      await getChildrenPerWeek();
      getClosedDays(selectedDaycare);
      setAttendanceCardLoading(false);
    })();
  }, [daycare, selectedWeek]);

  useEffect(() => {
    (async () => {
      setChildrenCardLoading(true);
      await getPickupTimesErrors();
      setChildrenCardLoading(false);
    })();
  }, [daycare, selectedDayChildrenCard]);

  useEffect(() => {
    if (daycare && daycares.length) {
      setDaycareInfos(daycares.find((d) => d._id === daycare));
    }
  }, [daycare, daycares]);

  useEffect(() => {
    (async () => {
      await Promise.all([getTurnoverData(), getInvoicesData()]);
    })();
  }, [selectedDaycare, rangeMonths]);

  return (
    <HomeContext.Provider
      value={{
        daycareInfos,
        daycares,
        selectedDaycare,
        setSelectedDaycare,
        closedDays,
        rangeMonths,
        setRangeMonths,
        dateFormat,
        monthFormat,
        selectedDayStaffRatio,
        setSelectedDayStaffRatio,
        timeSlots,
        selectedWeek,
        setSelectedWeek,
        mealsSelectedWeek,
        setMealsSelectedWeek,
        statsPerWeek,
        staffRatioCardLoading,
        attendanceCardLoading,
        childrenCardLoading,
        selectedDayChildrenCard,
        setSelectedDayChildrenCard,
        pickupTimesErrors,
        turnoverData,
        totalTurnoverAmount,
        dataLoading,
        invoicesData,
        open,
        updateDataLoadingState,
        setInvoicesData,
        userRole: user?.role,
        counters,
        onChange,
        mealsCardLoading
      }}
    >
      {children}
    </HomeContext.Provider>
  );
};

export const useHomeContext = () => {
  const context = useContext(HomeContext);
  if (context === undefined) {
    throw new Error('useHomeContext must be used within a HomeContextProvider');
  }
  return context;
};
