import PropTypes from 'prop-types';
import {
  Card,
  Form,
  Input,
  Checkbox,
  Button,
  Divider,
  Select,
  Flex
} from 'antd';
import { useTranslation } from 'react-i18next';
import { CloseOutlined } from '@ant-design/icons';
import { formItemLayout } from '../../../utils/constants/formLayout';
import { handleDepartment } from '../../../utils/handleDepartment';

const { Option } = Select;

/**
 * Component for creating Form List for customer-account.
 *
 * @component
 *
 * @param {Object} form - Form instance.
 *
 * @returns {JSX.Element} FormList component inside a Form
 */
export const FormListAccountAndContact = ({ form }) => {
  const { t } = useTranslation();

  return (
    <>
      <Form.List name="billing_address">
        {(fields, { add, remove }) => (
          <Flex vertical gap="middle">
            {fields.map((field, index) => (
              <Card key={field.name}>
                <Form.Item
                  {...field}
                  key={[field.name, 'address_label']}
                  name={[field.name, 'address_label']}
                  rules={[{ required: true }]}
                  label={t(
                    'customer-account.form.billing_address.address_label'
                  )}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  {...field}
                  key={[field.name, 'number']}
                  name={[field.name, 'number']}
                  rules={[{ required: true }]}
                  label={t('customer-account.form.billing_address.number')}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  {...field}
                  key={[field.name, 'street']}
                  name={[field.name, 'street']}
                  rules={[{ required: true }]}
                  label={t('customer-account.form.billing_address.street')}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  {...field}
                  key={[field.name, 'additional']}
                  name={[field.name, 'additional']}
                  label={t('customer-account.form.billing_address.additional')}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  {...field}
                  key={[field.name, 'postal_code']}
                  rules={[{ required: true }]}
                  name={[field.name, 'postal_code']}
                  label={t('customer-account.form.billing_address.postal_code')}
                >
                  <Input
                    type="number"
                    onChange={(e) =>
                      handleDepartment(e, form, [field.name, 'state'])
                    }
                  />
                </Form.Item>
                <Form.Item
                  {...field}
                  key={[field.name, 'city']}
                  name={[field.name, 'city']}
                  label={t('customer-account.form.billing_address.city')}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  {...field}
                  key={[field.name, 'state']}
                  name={[field.name, 'state']}
                  rules={[{ required: true }]}
                  label={t('customer-account.form.billing_address.state')}
                >
                  <Input />
                </Form.Item>
                {index > 0 && (
                  <CloseOutlined
                    style={{
                      position: 'absolute',
                      top: '5px',
                      right: '5px',
                      fontSize: '16px',
                      color: 'red',
                      cursor: 'pointer'
                    }}
                    onClick={() => remove(field.name)}
                  />
                )}
              </Card>
            ))}
            <Form.Item>
              <Button type="primary" onClick={() => add()}>
                {t('buttons.add_address')}
              </Button>
            </Form.Item>
          </Flex>
        )}
      </Form.List>
      <Divider orientation="left">{t('contract.form.titles.contacts')}</Divider>
      <Form.List {...formItemLayout} name="contacts">
        {(fields, { add, remove }) => (
          <Flex vertical gap="middle">
            {fields.map((field, index) => (
              <Card key={field.name}>
                <Form.Item
                  {...field}
                  key={[field.name, 'is_principal']}
                  name={[field.name, 'is_principal']}
                  label={t('customer-account.form.contacts.is_principal')}
                  valuePropName="checked"
                >
                  <Checkbox />
                </Form.Item>
                <Form.Item
                  {...field}
                  key={[field.name, 'contact_label']}
                  name={[field.name, 'contact_label']}
                  rules={[{ required: true }]}
                  label={t('customer-account.form.contacts.contact_label')}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  {...field}
                  key={[field.name, 'email']}
                  name={[field.name, 'email']}
                  rules={[{ required: true }, { type: 'email' }]}
                  label={t('customer-account.form.contacts.email')}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  {...field}
                  name={[field.name, 'mobile_phone_number']}
                  label={t(
                    'customer-account.form.contacts.mobile_phone_number'
                  )}
                >
                  <Input.Group compact>
                    <Form.Item
                      noStyle
                      name={[field.name, 'mobile_phone_number', 'country_code']}
                      initialValue="+33"
                    >
                      <Select style={{ width: '25%' }}>
                        <Option value="+33">+33</Option>
                      </Select>
                    </Form.Item>
                    <Form.Item
                      noStyle
                      name={[field.name, 'mobile_phone_number', 'number']}
                    >
                      <Input type="number" style={{ width: '75%' }} />
                    </Form.Item>
                  </Input.Group>
                </Form.Item>
                <Form.Item
                  {...field}
                  name={[field.name, 'landline_phone_number']}
                  label={t(
                    'customer-account.form.contacts.landline_phone_number'
                  )}
                >
                  <Input.Group compact>
                    <Form.Item
                      noStyle
                      name={[
                        field.name,
                        'landline_phone_number',
                        'country_code'
                      ]}
                      initialValue="+33"
                    >
                      <Select style={{ width: '25%' }}>
                        <Option value="+33">+33</Option>
                      </Select>
                    </Form.Item>
                    <Form.Item
                      noStyle
                      name={[field.name, 'landline_phone_number', 'number']}
                    >
                      <Input type="number" style={{ width: '75%' }} />
                    </Form.Item>
                  </Input.Group>
                </Form.Item>
                {index > 0 && (
                  <CloseOutlined
                    style={{
                      position: 'absolute',
                      top: '5px',
                      right: '5px',
                      fontSize: '16px',
                      color: 'red',
                      cursor: 'pointer'
                    }}
                    onClick={() => remove(field.name)}
                  />
                )}
              </Card>
            ))}
            <Form.Item>
              <Button type="primary" onClick={() => add()}>
                {t('buttons.add_contact')}
              </Button>
            </Form.Item>
          </Flex>
        )}
      </Form.List>
    </>
  );
};

FormListAccountAndContact.propTypes = {
  form: PropTypes.shape({
    setFieldsValue: PropTypes.func
  }).isRequired
};
