import { TermsAndConditionsForm } from './Forms/TermsAndConditionsForm';
import { PlanningForm } from './Forms/PlanningForm';
import { BillingForm } from './Forms/BillingForm';
import { AdditionalInfo } from './Forms/AdditionalInfoForm';
import { CustomerAccountForm } from './Forms/CustomerAccountForm';
import { PaymentMethodForm } from './Forms/PaymentMethodForm';
import { DocumentForm } from './Forms/DocumentForm';

/**
 * Custom hook that generates an array of contract forms based on the provided parameters.
 *
 * @hook
 * @param {string} origin - The origin of the contract.
 * @param {string} purpose - The purpose of the contract.
 * @param {function} onFinishTermsForm - Callback function called when the terms form is finished.
 * @param {object} termAndConditions - Initial values for the terms form.
 * @param {function} setIsFlexible - Setter function for the isFlexible state.
 * @param {boolean} isFlexible - Flag indicating if the contract is flexible.
 * @param {object} child - Child object containing contracts.
 * @param {boolean} isLoading - Flag indicating if the form is loading.
 * @param {function} onFinishPlanningForm - Callback function called when the planning form is finished.
 * @param {function} handlePrevious - Callback function called when the previous button is clicked.
 * @param {object} planning - Initial values for the planning form.
 * @param {function} handleSubmit - Callback function called when the form is submitted.
 * @param {function} onFinishBilling - Callback function called when the billing form is finished.
 * @param {object} billing - Initial values for the billing form.
 * @param {object} additionalInfo - Initial values for the additional info form.
 * @param {function} onFinishInfoForm - Callback function called when the additional info form is finished.
 * @param {object} customerAccount - Initial values for the customer account form.
 * @param {function} onFinishCustomerForm - Callback function called when the customer account form is finished.
 * @param {string} selectedRadio - The selected radio value.
 * @param {function} setSelectedRadio - Setter function for the selectedRadio state.
 * @param {object} paymentMethod - Initial values for the payment method form.
 * @param {function} onFinishPaymentForm - Callback function called when the payment method form is finished.
 * @param {function} setDocuments - Setter function for the documents state.
 * @param {object} documents - Initial values for the documents form.
 * @param {object} filesConfiguration - Configuration for the files.
 * @param {function} setFilesConfiguration - Setter function for the filesConfiguration state.
 * @param {object} filesList - List of files.
 * @param {function} setFilesList - Setter function for the filesList state.
 * @param {string} contractID - The ID of the contract.
 * @param {object} form - Form instance.
 * @param {boolean} directDebit - Direct Debit state.
 * @param {function} setDirectDebit - Function to set Direct Debit State.
 * @returns {object} - An object containing the contract forms.
 */
export const useContractForms = (
  origin,
  purpose,
  onFinishTermsForm,
  termAndConditions,
  setIsFlexible,
  isFlexible,
  child,
  isLoading,
  onFinishPlanningForm,
  handlePrevious,
  planning,
  handleSubmit,
  onFinishBilling,
  billing,
  additionalInfo,
  onFinishInfoForm,
  customerAccount,
  onFinishCustomerForm,
  selectedRadio,
  setSelectedRadio,
  paymentMethod,
  onFinishPaymentForm,
  setDocuments,
  documents,
  filesConfiguration,
  setFilesConfiguration,
  filesList,
  setFilesList,
  contractID,
  form,
  directDebit,
  setDirectDebit
) => {
  let forms = [];
  if (origin === 'CHILD') {
    forms = [
      <TermsAndConditionsForm
        onFinish={onFinishTermsForm}
        initialValues={termAndConditions}
        setIsFlexible={setIsFlexible}
        isFlexible={isFlexible}
        contracts={child?.contracts || []}
        purpose={purpose}
        isLoading={isLoading}
        form={form}
      />,
      !isFlexible && (
        <PlanningForm
          onFinish={onFinishPlanningForm}
          handlePrevious={handlePrevious}
          initialValues={planning}
          submitForm={(values) => handleSubmit(values)}
          purpose={purpose}
          origin={origin}
          form={form}
        />
      ),
      purpose === 'create' && (
        <BillingForm
          onFinish={onFinishBilling}
          handlePrevious={handlePrevious}
          initialValues={billing}
          contracts={child?.contracts}
          submitForm={(values) => handleSubmit(values)}
          form={form}
        />
      )
    ];
  } else {
    forms = [
      <AdditionalInfo onFinish={onFinishInfoForm} />,
      origin !== 'PARENTS' && (
        <CustomerAccountForm
          onFinish={onFinishCustomerForm}
          initialValues={customerAccount}
          handlePrevious={handlePrevious}
          selectedRadio={selectedRadio}
          setSelectedRadio={setSelectedRadio}
          form={form}
        />
      ),
      <PaymentMethodForm
        onFinish={onFinishPaymentForm}
        initialValues={paymentMethod}
        handlePrevious={handlePrevious}
        directDebit={directDebit}
        setDirectDebit={setDirectDebit}
        form={form}
      />,
      <DocumentForm
        setDocuments={setDocuments}
        documents={documents}
        handlePrevious={handlePrevious}
        submitForm={(values) => handleSubmit(values)}
        filesConfiguration={filesConfiguration}
        setFilesConfiguration={setFilesConfiguration}
        filesList={filesList}
        setFilesList={setFilesList}
        contractID={contractID}
        loading={isLoading}
        form={form}
      />
    ];
  }

  return { contractForms: forms.filter(Boolean) };
};
