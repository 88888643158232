import { useTranslation } from 'react-i18next';
import { Divider } from 'antd';
import { useGenerateFormItem } from '../../../utils/generateFormItem';
import { useCustomerAccountFields } from '../Fields/customerAccountFields';
import { FormListAccountAndContact } from './FormListAccountAndContact';

/**
 * Renders the customer account fields based on the selected radio option.
 *
 * @function
 * @param {string} selectedRadio - The selected radio option. (existingCustomerAccount|newCustomerAccount)
 * @param {object} form - The form object.
 * @returns {JSX.Element|null} The rendered customer account fields.
 */
export const renderCustomerAccountFields = (selectedRadio, form) => {
  const { t } = useTranslation();
  const generateFields = useGenerateFormItem();
  const { existingCustomerAccountFields, newCustomerAccountFields } =
    useCustomerAccountFields();

  if (selectedRadio === 'existingCustomerAccount') {
    return existingCustomerAccountFields.map((field) =>
      generateFields('customer-account', field)
    );
  }
  if (selectedRadio === 'newCustomerAccount') {
    return (
      <>
        {newCustomerAccountFields.map((field) =>
          generateFields('customer-account', field)
        )}
        <Divider orientation="left">
          {t('contract.form.titles.billing_address')}
        </Divider>
        <FormListAccountAndContact form={form} />
      </>
    );
  }
  return null;
};
