import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import {
  ClockCircleFilled,
  PauseCircleFilled,
  RightSquareFilled,
  FileAddFilled,
  EuroOutlined,
  RightCircleFilled
} from '@ant-design/icons';

const iconSize = 18;

/**
 * Custom hook that provides column configuration for the invoice table.
 *
 * @function
 * @param {Object} child - The child object containing first and last names.
 * @param {string} invoiceType - The type of the invoice, either 'FROM_SCRATCH' or other types.
 * @param {Object} invoice - The invoice object.
 * @param {string} resource - The resource if payment or invoice
 * @returns {Array} An array of column configurations for the invoice table.
 */
export const useColumns = (child, invoiceType, invoice, resource) => {
  const { t } = useTranslation();

  const getTranslation = (data) => {
    switch (data.type) {
      case 'regulars_already_paid':
        return `du ${dayjs.utc(data?.range_concerned?.[0]).format('DD/MM')} au
                          ${dayjs
                            .utc(data?.range_concerned?.[1])
                            .format('DD/MM')}`;
      case 'regulars_regul':
        return `du ${dayjs.utc(data?.range_concerned?.[0]).format('DD/MM')} au
                          ${dayjs
                            .utc(data?.range_concerned?.[1])
                            .format('DD/MM')}`;
      case 'regular_already_paid':
        return `du ${dayjs
          .utc(invoice?.range_concerned?.[0])
          .format('DD/MM')} au
                          ${dayjs
                            .utc(invoice?.range_concerned?.[1])
                            .format('DD/MM')}`;
      case 'overtime':
        return `de ${data.total_minutes?.toFixed(0)} mins le ${dayjs
          .utc(data.start_time)
          .format('DD/MM')} de 
            ${dayjs.utc(data.start_time).format('HH:mm')} à ${dayjs
          .utc(data.end_time)
          .format('HH:mm')}
            `;
      case 'absence':
        return `${dayjs.utc(data.start_time).format('DD/MM')} de 
                      ${dayjs.utc(data.start_time).format('HH:mm')} à ${dayjs
          .utc(data.end_time)
          .format('HH:mm')}`;
      case 'adaptation':
        return `${dayjs.utc(data.start_time).format('DD/MM')} de 
                          ${dayjs
                            .utc(data.start_time)
                            .format('HH:mm')} à ${dayjs
          .utc(data.end_time)
          .format('HH:mm')}`;
      case 'adaptation_reduction':
        return `${dayjs.utc(data.start_time).format('DD/MM')}`;
      case 'reservation':
        return `${dayjs.utc(data.start_time).format('DD/MM')} de 
                          ${dayjs
                            .utc(data.start_time)
                            .format('HH:mm')} à ${dayjs
          .utc(data.end_time)
          .format('HH:mm')}`;
      default:
        return '';
    }
  };

  const icons = {
    unpaid_fees: (
      <EuroOutlined
        style={{ color: 'red', fontSize: iconSize, marginRight: 4 }}
      />
    ),
    fees: (
      <FileAddFilled
        style={{ color: '#7f00ff', fontSize: iconSize, marginRight: 4 }}
      />
    ),
    regular: (
      <ClockCircleFilled
        style={{ color: '#49C301', fontSize: iconSize, marginRight: 4 }}
      />
    ),
    regular_already_paid: (
      <ClockCircleFilled
        style={{ color: '#49C301', fontSize: iconSize, marginRight: 4 }}
      />
    ),
    overtime: (
      <RightSquareFilled
        style={{ color: '#3892FF', fontSize: iconSize, marginRight: 4 }}
      />
    ),
    adaptation: (
      <ClockCircleFilled
        style={{ color: '#FAB225', fontSize: iconSize, marginRight: 4 }}
      />
    ),
    adaptation_reduction: (
      <ClockCircleFilled
        style={{ color: '#49C301', fontSize: iconSize, marginRight: 4 }}
      />
    ),
    absence: (
      <PauseCircleFilled
        style={{ color: '#F54B4B', fontSize: iconSize, marginRight: 4 }}
      />
    ),
    reservation: (
      <RightCircleFilled
        style={{ color: '#ffc9ce', fontSize: iconSize, marginRight: 4 }}
      />
    )
  };

  return [
    {
      title:
        resource === 'payment'
          ? 'Label'
          : `${t('invoices.list.child')} ${child?.first_name} ${
              child?.last_name
            }`,
      key: 'type',
      dataIndex: 'type',
      render: (type, record) =>
        type && (
          <>
            {icons[type]}
            <span>
              {` ${t(`invoices.list.${type}`)} ${getTranslation(record)}`}
            </span>
          </>
        )
    },
    ...(invoiceType === 'FROM_SCRATCH'
      ? [
          {
            title: t('invoices.list.label'),
            key: 'label',
            dataIndex: 'label'
          }
        ]
      : [
          {
            title: t('invoices.list.amount_ttc'),
            key: 'hourly_rate',
            dataIndex: 'hourly_rate',
            render: (hourly_rate) =>
              hourly_rate && `${Number(hourly_rate).toFixed(2)} €`
          }
        ]),
    ...(invoiceType === 'FROM_SCRATCH'
      ? [
          {
            title: t('invoices.list.amount'),
            key: 'amount',
            dataIndex: 'amount',
            render: (amount) => amount && `${Number(amount).toFixed(2)} €`
          }
        ]
      : [
          {
            title: t('invoices.list.quantity'),
            key: 'total_hours',
            dataIndex: 'total_hours',
            render: (total_hours, data) => {
              if (total_hours) {
                if (data.type === 'overtime') {
                  return `${Number(total_hours / 60)?.toFixed(1)} ${t(
                    'common.hours'
                  )}`;
                }
                return `${Number(total_hours)?.toFixed(1)} ${t(
                  'common.hours'
                )}`;
              }
              return '';
            },
            sorter: true
          }
        ]),
    ...(invoiceType === 'FROM_SCRATCH'
      ? [
          {
            title: t('invoices.list.total_ttc'),
            key: 'amount',
            dataIndex: 'amount',
            render: (amount) =>
              amount !== undefined && `${Number(amount).toFixed(2)} €`,
            sorter: true
          }
        ]
      : [
          {
            title: t('invoices.list.total_ttc'),
            key: 'total',
            dataIndex: 'total',
            render: (total) =>
              total !== undefined && `${Number(total).toFixed(2)} €`,
            sorter: true
          }
        ])
  ];
};
