import { Flex, Form, Modal, Radio, message as antDmessage } from 'antd';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useParentsFields } from './fields';
import { CreateUpdateContainerModal } from '../../../../components/CreateUpdateContainer/CreateUpdateContainerModal';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { CreateAddParentModalContainer } from './CreateAddParentModalContainer';
import { useFields as useChildFields } from '../../fields';
import { config } from './utils/config';
import { modalTitle } from './utils/modalTitle';

/**
 * CreateEditParentModal is a component for creating or editing a parent associated with a child.
 * It allows for either adding an existing parent or creating a new one based on the purpose and resource name.
 *
 * @component
 * @param {boolean} refresh - A boolean indicating whether to refresh the component.
 * @param {function} setRefresh - A function to set the refresh state.
 * @param {function} closeModal - A function to handle the modal's visibility.
 * @param {boolean} open - A boolean indicating whether the modal is open.
 * @param {function} setOpen - A function to set the modal's open state.
 * @param {Object} record - The record object associated with the parent.
 * @param {string} purpose - The purpose of the modal ('create' or 'edit').
 * @param {string} childName - The name of the child associated with the parent.
 * @param {string} resourceName - The name of the resource ('children' or 'parents').
 * @returns {JSX.Element} The rendered component.
 * @example
 * // Usage example:
 * <CreateEditParentModal
 *   refresh={false}
 *   setRefresh={setRefreshFunction}
 *   handleModal={handleModalFunction}
 *   open={true}
 *   setOpen={setOpenFunction}
 *   record={parentRecord}
 *   purpose="create"
 *   childName="Child Name"
 *   resourceName="parents"
 * />
 */
export const CreateEditParentModal = ({
  refresh,
  setRefresh,
  record,
  purpose,
  childName,
  resourceName
}) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [form] = Form.useForm();
  const { parentsFields, isParentsFieldsLoading } = useParentsFields(form);
  const [isModalOpenedLocaly, setIsModalOpenedLocaly] = useState(true);
  const { fields, isFieldsLoading } = useChildFields();
  const { dispatchAPI, daycare } = useAuthContext();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { message } = useErrorMessage();
  const [isExistingParent, setIsExistingParent] = useState(true);

  const title = modalTitle(purpose, childName, resourceName, t, record);
  const formConfig = config(resourceName, record);

  const closeModal = () => {
    setIsModalOpenedLocaly(false);
  };

  const customSubmit = async (objective, body) => {
    if (resourceName === 'children') {
      try {
        setIsSubmitting(true);
        const url = `children/${record._id}`;
        await dispatchAPI('PATCH', {
          url,
          body: {
            ...body,
            weight: {
              value: body.value,
              unit: body.unit
            }
          }
        });
        closeModal();
        form.resetFields();
        setRefresh(!refresh);
      } catch (error) {
        message(error);
      } finally {
        setIsSubmitting(false);
      }
    } else {
      try {
        setIsSubmitting(true);
        const url =
          objective === 'create'
            ? `children/post-parent/not-existing/${id}`
            : `children/patch-parent/${record._id}/${id}`;
        await dispatchAPI('PATCH', {
          url,
          body: {
            ...body,
            daycare
          }
        });
        closeModal();
        setRefresh(!refresh);
        antDmessage.success(t('children.show.parents.success'));
      } catch (error) {
        message(error);
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const radioOptions = [
    {
      label: t('children.show.parents.modal.add_existing_parent'),
      value: true
    },
    {
      label: t('children.show.parents.modal.create_parent'),
      value: false
    }
  ];

  const modalContent = () => {
    switch (true) {
      case resourceName === 'children':
        return (
          <CreateUpdateContainerModal
            fields={fields}
            loadingFields={isFieldsLoading}
            purpose="edit"
            baseUrl="children"
            resource="children"
            config={formConfig}
            customSubmit={customSubmit}
            isCustomSubmitting={isSubmitting}
            withoutPageHeaderCustom
            closeModal={closeModal}
            record={record}
            withGetResource={false}
          />
        );
      case purpose === 'create' && isExistingParent:
        return (
          <Flex vertical gap="small">
            <Radio.Group
              options={radioOptions}
              onChange={(e) => setIsExistingParent(e.target.value)}
              optionType="button"
              buttonStyle="solid"
            />
            <CreateAddParentModalContainer
              isSubmitting={isSubmitting}
              setIsSubmitting={setIsSubmitting}
              closeModal={closeModal}
              refresh={refresh}
              setRefresh={setRefresh}
              record={record}
            />
          </Flex>
        );
      case purpose === 'create' && !isExistingParent:
        return (
          <Flex vertical gap="small">
            <Radio.Group
              options={radioOptions}
              onChange={(e) => setIsExistingParent(e.target.value)}
              optionType="button"
              buttonStyle="solid"
              defaultValue
            />
            <CreateUpdateContainerModal
              fields={parentsFields}
              loadingFields={isParentsFieldsLoading}
              purpose="create"
              baseUrl="parents"
              resource="parents"
              config={formConfig}
              customSubmit={customSubmit}
              isCustomSubmitting={isSubmitting}
              withoutPageHeaderCustom
              closeModal={closeModal}
              record={record}
              withGetResource={false}
              customFormInstance={form}
            />
          </Flex>
        );
      default:
        return (
          <CreateUpdateContainerModal
            fields={parentsFields}
            loadingFields={isParentsFieldsLoading}
            purpose="edit"
            baseUrl="parents"
            resource="parents"
            config={formConfig}
            customSubmit={customSubmit}
            isCustomSubmitting={isSubmitting}
            withoutPageHeaderCustom
            closeModal={closeModal}
            record={record}
            withGetResource={false}
          />
        );
    }
  };

  return (
    <Modal
      title={title}
      open={isModalOpenedLocaly}
      onCancel={closeModal}
      footer={null}
      width={800}
    >
      {modalContent()}
    </Modal>
  );
};

CreateEditParentModal.propTypes = {
  refresh: PropTypes.bool,
  setRefresh: PropTypes.func,
  record: PropTypes.shape({
    _id: PropTypes.string,
    user: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      email: PropTypes.string
    })
  }),
  purpose: PropTypes.string,
  childName: PropTypes.string,
  resourceName: PropTypes.string
};

CreateEditParentModal.defaultProps = {
  refresh: false,
  setRefresh: null,
  record: null,
  purpose: undefined,
  childName: undefined,
  resourceName: undefined
};
