import { Button, Divider, Popconfirm } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  DeleteOutlined,
  EditOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';

const iconSize = 18;

/**
 * Columns for the datatable
 * @hook
 * @param {boolean} refresh The state to refresh the table.
 * @param {function} setRefresh The function to refresh the table.
 * @param {function} handleModal The function to handle modal behaviour.
 * @returns {Array} columns for the datatable
 */
export const useVaccinesColumns = (refresh, setRefresh, handleModal) => {
  const { t } = useTranslation();
  const { daycare, dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();

  const deleteDocument = (id) => {
    try {
      dispatchAPI('DELETE', {
        url: `/daycares/dynamic_list/${daycare}/${id}/documents`
      });
      setTimeout(() => {
        setRefresh(!refresh);
      }, 500);
    } catch (error) {
      message(error);
    }
  };

  return [
    {
      title: t('daycares.form.vaccine_denomination'),
      key: 'name',
      dataIndex: 'name',
      sorter: true
    },
    {
      title: t('daycares.form.second_dose_interval'),
      key: 'second_dose_interval',
      dataIndex: 'second_dose_interval',
      sorter: true,
      render: (interval) =>
        interval ? `${interval} ${t('daycares.form.months')}` : '-'
    },
    {
      title: t('daycares.form.third_dose_interval'),
      key: 'third_dose_interval',
      dataIndex: 'third_dose_interval',
      sorter: true,
      render: (interval) =>
        interval ? `${interval} ${t('daycares.form.months')}` : '-'
    },
    {
      title: t('daycares.form.fourth_dose_interval'),
      key: 'fourth_dose_interval',
      dataIndex: 'fourth_dose_interval',
      sorter: true,
      render: (interval) =>
        interval ? `${interval} ${t('daycares.form.months')}` : '-'
    },
    {
      align: 'right',
      render: (record) => (
        <>
          <Button
            type="link"
            onClick={() => handleModal(record, 'edit', 'vaccines')}
            style={{ padding: 0 }}
          >
            <EditOutlined style={{ fontSize: iconSize }} />
          </Button>
          <>
            <Divider type="vertical" />
            <Popconfirm
              title={t('datatable.column.action.delete.title')}
              okText={t('datatable.column.action.delete.ok')}
              okButtonProps={{ type: 'danger' }}
              cancelText={t('datatable.column.action.delete.cancel')}
              onConfirm={() => deleteDocument(record?._id)}
              icon={<WarningOutlined />}
            >
              <DeleteOutlined
                style={{ color: 'var(--errorColor)', fontSize: iconSize }}
                type="delete"
              />
            </Popconfirm>
          </>
        </>
      )
    }
  ];
};
