import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Divider, Popconfirm, Tag, Flex } from 'antd';
import {
  ContainerOutlined,
  EditOutlined,
  EyeOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { calculateBirthDateDiff } from './utils/calculateBirthDateDiff';
import { childrenGender } from '../../utils/constants/tagColors';
import IsWalking from '../../assets/images/icons/mingcute_walk-fill.svg';
import DontWalk from '../../assets/images/icons/healthicons_baby-0306m.svg';
import { getAge } from './utils/getAge';

const iconSize = 18;

/**
 * Custom hook for generating columns configuration for a table.
 *
 * @param {boolean} forceRefresh - A boolean value indicating whether to force refresh the table.
 * @param {function} setForceRefresh - A function to set the forceRefresh value.
 * @param {function} handleModalBehaviour - A function to handle modals.
 * @returns {array} An array of column configuration objects for a table.
 */
export const useColumns = (
  forceRefresh,
  setForceRefresh,
  handleModalBehaviour
) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [enums, setEnums] = useState();
  const [groups, setGroups] = useState();

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/children/enums' });
      setEnums(data);
    } catch (e) {
      message(e);
    }
  };

  const getGroups = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/children-groups' });
      setGroups(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const archiveResource = async (id) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/children/${id}`,
        body: {
          archived: true,
          archiving_date: new Date()
        }
      });
      setForceRefresh(!forceRefresh);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      await getEnums();
      await getGroups();
    })();
  }, []);

  return [
    {
      key: 'birth_date',
      dataIndex: 'birth_date',
      render: (date) => {
        const { component } = calculateBirthDateDiff(date);
        return component;
      }
    },
    {
      title: t('children.list.last_name'),
      key: 'last_name',
      dataIndex: 'last_name',
      sorter: true
    },
    {
      title: t('children.list.first_name'),
      key: 'first_name',
      dataIndex: 'first_name',
      sorter: true
    },
    {
      title: t('children.list.gender'),
      key: 'gender',
      dataIndex: 'gender',
      filters: enums?.genders?.map((r) => ({
        text: t(`children.genders.${r}`),
        value: r
      })),
      sorter: true,
      render: (gender) => (
        <Tag color={childrenGender[gender]}>
          {t(`children.genders.${gender}`)}
        </Tag>
      )
    },
    {
      title: t('children.list.group'),
      key: 'group',
      dataIndex: 'group',
      filters: groups?.map((r) => ({
        text: r.label,
        value: r._id
      })),
      sorter: true,
      render: (group) =>
        group && <Tag color={group?.tag_color}>{group?.label}</Tag>
    },
    {
      title: t('children.list.is_walking.title'),
      key: 'is_walking',
      dataIndex: 'is_walking',
      sorter: true,
      filters: [
        {
          text: t('children.list.is_walking.yes'),
          value: true
        },
        {
          text: t('children.list.is_walking.no'),
          value: false
        }
      ],
      render: (bool) => {
        if (bool) {
          return (
            <Flex align="center" gap="small">
              <img src={IsWalking} alt="is_walking" />
              {t('children.list.is_walking.yes')}
            </Flex>
          );
        }
        return (
          <Flex align="center" gap="small">
            <img src={DontWalk} alt="dont-walk" />
            {t('children.list.is_walking.no')}
          </Flex>
        );
      }
    },
    {
      title: t('children.list.age'),
      key: 'birth_date',
      dataIndex: 'birth_date',
      sorter: true,
      render: (date) => getAge(date, t)
    },
    {
      align: 'right',
      render: (record) => (
        <>
          <Link
            to={{
              pathname: `/children/show/${record?._id}`
            }}
            style={{ marginRight: 15 }}
          >
            <EyeOutlined style={{ fontSize: iconSize }} />
          </Link>
          <Button
            type="link"
            onClick={() => handleModalBehaviour(record)}
            style={{ padding: 0 }}
          >
            <EditOutlined style={{ fontSize: iconSize }} />
          </Button>
          <>
            <Divider type="vertical" />
            <Popconfirm
              title={t('datatable.column.action.archive.title')}
              okText={t('datatable.column.action.archive.ok')}
              okButtonProps={{ type: 'danger' }}
              cancelText={t('datatable.column.action.archive.cancel')}
              onConfirm={() => archiveResource(record?._id)}
              icon={<WarningOutlined />}
            >
              <ContainerOutlined
                style={{ color: 'var(--errorColor', fontSize: iconSize }}
                type="delete"
              />
            </Popconfirm>
          </>
        </>
      )
    }
  ];
};
