import { Route, Routes } from 'react-router-dom';
import { Exception } from '../../components';
import { Signatures } from './Signatures';

export const SignaturesRouter = () => (
  <Routes>
    <Route path="/:token" element={<Signatures />} />
    <Route path="*" element={<Exception />} />
  </Routes>
);
