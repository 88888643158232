import { useEffect, useState } from 'react';
import { Input, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';

const { Option } = Select;
const { TextArea } = Input;

/**
 * `useFields` is a custom React hook designed to manage form fields, particularly for a messaging system.
 * It handles the loading and displaying of recipients and content fields. The hook uses Ant Design's `Select`
 * and `Input` components for user interface.
 *
 * This hook integrates with `useAuthContext` for API requests and `useErrorMessage` for error handling.
 * It fetches the list of recipients based on specific roles and sets up form fields with validation rules.
 *
 * @returns {Object} An object containing the fields configuration and a loading state.
 * - fields: Array of field configurations, each with label, name, rules, and input component.
 * - isFieldsLoading: Boolean indicating the loading state of the fields.
 */
export const useFields = () => {
  const { t } = useTranslation();
  const { dispatchAPI, daycare } = useAuthContext();
  const { message } = useErrorMessage();
  const [isFieldsLoading, setIsFieldsLoading] = useState(false);
  const [recipients, setRecipients] = useState([]);

  const getRecipients = async () => {
    try {
      const includedRoles = ['users:TABLET', 'users:MANAGER'];
      const { data } = await dispatchAPI('GET', {
        url: `users?role=${includedRoles}&daycare=${daycare}`
      });
      setRecipients(data);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    setIsFieldsLoading(true);
    (async () => {
      await getRecipients();
    })();
    setIsFieldsLoading(false);
  }, []);

  const fields = [
    {
      label: 'select_recipient',
      name: ['to'],
      rules: [{ required: true }],
      input: (
        <Select loading={isFieldsLoading}>
          {(recipients || []).map((recipient) => (
            <Option key={recipient._id} value={recipient._id}>
              {`${recipient.first_name} ${recipient.last_name}`}
            </Option>
          ))}
        </Select>
      )
    },
    {
      label: 'content',
      name: ['content'],
      rules: [{ required: true }],
      input: (
        <TextArea
          placeholder={t('messages_and_notes.placeholder')}
          autoSize={{ minRows: 8, maxRows: 12 }}
        />
      )
    }
  ];

  return {
    fields,
    isFieldsLoading
  };
};
