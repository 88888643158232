import PropTypes from 'prop-types';
import { Card, Collapse, Skeleton } from 'antd';
import { DescriptionList } from '../../../../components/DescriptionList/DescriptionList';
import { useListContent } from './useListContent';
import { PanelHeader } from './PanelHeader';

const { Panel } = Collapse;

/**
 * @description
 * ParentCard is a component that displays the parent information
 * of a pre-registration.
 * It is displayed in the PreRegistrationShow component.
 *
 * @component
 * @param {Object} data - The data of the parent
 * @param {number} index - The index of the parent
 * @param {boolean} isLoading - If the data is loading
 * @returns {React.Component} ParentCard component
 */
export const ParentCard = ({ isLoading, data, index }) => {
  const { headerListContent, listContent } = useListContent(data);

  return (
    <Card>
      <Collapse bordered={false} className="no-padding">
        <Panel
          header={
            <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
              <DescriptionList
                data={headerListContent}
                translate
                layout="vertical"
              />
              <PanelHeader data={data} index={index + 1} />
            </Skeleton>
          }
        >
          <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
            <DescriptionList data={listContent} translate layout="vertical" />
          </Skeleton>
        </Panel>
      </Collapse>
    </Card>
  );
};
ParentCard.propTypes = {
  data: PropTypes.shape({
    parents: PropTypes.arrayOf(PropTypes.shape({}))
  }),
  index: PropTypes.number.isRequired,
  isLoading: PropTypes.bool
};

ParentCard.defaultProps = {
  data: {
    parents: []
  },
  isLoading: false
};
