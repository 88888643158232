import { PropTypes } from 'prop-types';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Card, List, Button, Popconfirm } from 'antd';
import {
  EditOutlined,
  ContainerOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { useAuthContext } from '../../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../../utils/errorMessage';
import { AddressesCardModal } from './AddressesCardModal/AddressesCardModal';

const { Item } = List;

/**
 * ContactsCard component
 * @component
 * @param {Object} data The data of the customer account
 * @param {Function} setRefresh The function to refresh the data
 * @returns {JSX.Element} The component
 */
export const AdressesCard = ({ data, setRefresh }) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [isLoading, setIsLoading] = useState(false);
  const [modal, setModal] = useState(null);
  const { billing_address } = data;

  const formattedAddress = ({ number, street, postal_code, city, state }) =>
    `${number} ${street} ${postal_code} ${city}, ${state}`;

  const openModal = (record, add) => {
    const modalKey = record?.index || Date.now(); // Use a unique key, or current timestamp
    setModal(
      <AddressesCardModal
        key={modalKey}
        purpose="edit"
        record={record}
        customerAccount={data}
        add={add}
        setRefresh={setRefresh}
      />
    );
  };

  const removeAdress = async (addressIndex) => {
    try {
      setIsLoading(true);
      await dispatchAPI('PATCH', {
        url: `/customer-accounts/remove-address/${id}`,
        body: { addressIndex }
      });
      setRefresh((prev) => !prev);
    } catch (error) {
      if (
        error.response.data.message === 'AT_LEAST_ONE_ADDRESS_REQUIRED' ||
        error.response.data.message === 'ADDRESS_INDEX_NOT_FOUND'
      ) {
        message(t(error.response.data.message));
      }
      message(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {modal}
      <Card
        title={t('customer-account.show.addresses.title')}
        loading={isLoading}
        extra={
          <Button onClick={() => openModal({}, true)}>{`+ ${t(
            'buttons.add'
          )}`}</Button>
        }
      >
        <List
          dataSource={billing_address}
          className="account-list"
          renderItem={(item, index) => (
            <Item
              key={item._id}
              actions={[
                <Button
                  type="link"
                  onClick={() => openModal({ ...item, index }, false)}
                  style={{ padding: 4 }}
                >
                  <EditOutlined />
                </Button>,
                <Popconfirm
                  title={t('datatable.column.action.delete.title')}
                  okText={t('datatable.column.action.delete.ok')}
                  okButtonProps={{ type: 'danger' }}
                  cancelText={t('datatable.column.action.delete.cancel')}
                  icon={<WarningOutlined />}
                  onConfirm={() => removeAdress(index)}
                >
                  <ContainerOutlined style={{ color: 'var(--errorColor' }} />
                </Popconfirm>
              ]}
            >
              {item.is_principal && <div className="main-dot" />}
              <p>{item.address_label}</p>
              <p>{formattedAddress(item)}</p>
            </Item>
          )}
        />
      </Card>
    </>
  );
};

AdressesCard.propTypes = {
  data: PropTypes.shape({
    billing_address: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string,
        address_label: PropTypes.string,
        number: PropTypes.string,
        street: PropTypes.string,
        postal_code: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string
      })
    )
  }),
  setRefresh: PropTypes.func.isRequired
};

AdressesCard.defaultProps = {
  data: {}
};
