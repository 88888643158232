import { AdditionalInfo } from '../Contracts/Forms/AdditionalInfoForm';
import { CustomerAccountForm } from '../Contracts/Forms/CustomerAccountForm';
import { PaymentMethodForm } from '../Contracts/Forms/PaymentMethodForm';
import { DocumentForm } from '../Contracts/Forms/DocumentForm';

/**
 * The PreRegistrationValidationForms component is a component that renders the forms for the pre-registration validation.
 *
 * @component
 * @param {Object} props - The props object.
 * @param {any} props.props - Additional props to be passed to the forms.
 * @param {string} props.selectedRadio - The selected radio value. (ex: 'existingCustomerAccount')
 * @param {function} props.setSelectedRadio - The function to set the selected radio value.
 * @param {function} props.setMandatoryDocuments - The function to set the mandatory documents.
 * @param {Array} props.mandatoryDocuments - The array of mandatory documents.
 * @param {function} props.setCurrent - The function to set the current form index.
 * @param {function} props.handleSubmit - The function to handle form submission.
 * @param {Object} props.preRegistration - The pre-registration object.
 * @param {Array} props.filesList - The list of files.
 * @param {function} props.setFilesList - The function to set the files list.
 * @param {boolean} props.loading - The loading state.
 * @param {Object} props.form - The form instance.
 * @param {function} props.setDirectDebit - The function to set the direct debit state.
 * @param {boolean} props.directDebit - The direct debit state.
 * @returns {ReactElement} The forms for the pre-registration validation.
 */
export const PreRegistrationValidationForms = ({
  props,
  selectedRadio,
  setSelectedRadio,
  setMandatoryDocuments,
  mandatoryDocuments,
  setCurrent,
  handleSubmit,
  preRegistration,
  filesConfiguration,
  setFilesConfiguration,
  filesList,
  setFilesList,
  loading,
  form,
  setDirectDebit,
  directDebit
}) => {
  const forms = [
    <AdditionalInfo {...props} form={form} />,
    <CustomerAccountForm
      {...props}
      selectedRadio={selectedRadio}
      setSelectedRadio={setSelectedRadio}
      form={form}
    />,
    <PaymentMethodForm
      {...props}
      setDirectDebit={setDirectDebit}
      directDebit={directDebit}
      form={form}
    />,
    <DocumentForm
      setDocuments={setMandatoryDocuments}
      documents={mandatoryDocuments}
      handlePrevious={() => setCurrent((prev) => prev - 1)}
      submitForm={handleSubmit}
      filesConfiguration={filesConfiguration}
      setFilesConfiguration={setFilesConfiguration}
      filesList={filesList}
      setFilesList={setFilesList}
      contractID={preRegistration?.contract?._id}
      loading={loading}
      form={form}
    />
  ];

  return forms;
};
