import React from 'react';
import PropTypes from 'prop-types';
import { Button, Radio, Flex } from 'antd';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { renderCustomerAccountFields } from './useRenderCustomerAccountFields';

/**
 * Component for rendering the customer account form.
 * This component is rendered inside the contract form.
 *
 * @component
 * @prop {Function} onFinish Function to be called when the form is submitted.
 * @prop {Object} initialValues Object containing the initial values for the form.
 * @prop {Function} handlePrevious Function to be called when the previous button is clicked.
 * @returns {React.Component} Customer account form component
 */
export const CustomerAccountForm = ({
  onFinish,
  handlePrevious,
  selectedRadio,
  setSelectedRadio,
  form
}) => {
  const { t } = useTranslation();
  const options = ['existingCustomerAccount', 'newCustomerAccount'];

  const handleRadioChange = ({ target: { value } }) => {
    setSelectedRadio(value);
    form.setFields([
      {
        name: ['billing_address'],
        value: [{}]
      },
      {
        name: ['contacts'],
        value: [
          {
            mobile_phone_number: {
              country_code: '+33'
            },
            landline_phone_number: {
              country_code: '+33'
            }
          }
        ]
      },
      {
        name: ['customer_nature'],
        value: undefined
      },
      {
        name: ['label'],
        value: undefined
      }
    ]);
  };

  return (
    <>
      <Flex justify="center" style={{ marginBottom: 16 }}>
        <Radio.Group
          value={selectedRadio}
          onChange={handleRadioChange}
          buttonStyle="solid"
        >
          {options.map((option) => (
            <Radio.Button key={option} value={option}>
              {t(`contract.form.${option}`)}
            </Radio.Button>
          ))}
        </Radio.Group>
      </Flex>
      {renderCustomerAccountFields(selectedRadio, form)}
      <Flex justify="flex-end" gap="small">
        <Button type="primary" onClick={handlePrevious}>
          <ArrowLeftOutlined />
          {t('buttons.previous')}
        </Button>
        <Button type="primary" onClick={onFinish}>
          {t('buttons.next')}
          <ArrowRightOutlined />
        </Button>
      </Flex>
    </>
  );
};

CustomerAccountForm.propTypes = {
  onFinish: PropTypes.func.isRequired,
  handlePrevious: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({}),
  selectedRadio: PropTypes.bool.isRequired,
  setSelectedRadio: PropTypes.func.isRequired,
  form: PropTypes.shape({
    setFields: PropTypes.func
  })
};

CustomerAccountForm.defaultProps = {
  initialValues: {},
  form: null
};
