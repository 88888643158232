import { useTranslation } from 'react-i18next';
import { Button, Col, Divider, Table, Flex } from 'antd';
import PropTypes from 'prop-types';
import { PlusOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { useColumns } from './columns';
import { CreateEditParentModal } from './CreateEditParentModal';
import { useSiblingColumns } from './siblingsColumns';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';

/**
 * ParentsTab is a React component for displaying and managing parents and siblings of a child.
 *
 * @component
 * @param {object} props - The component's props.
 * @param {object} props.child - Information about the child, including their parents and siblings.
 * @param {boolean} props.refresh - A boolean indicating whether the component should refresh its data.
 * @param {function} props.setRefresh - A function to set the refresh state of the component.
 * @returns {JSX.Element} The rendered component.
 * @example
 * // Usage example:
 * <ParentsTab child={childData} refresh={isRefreshing} setRefresh={setRefreshState} />
 */
export const ParentsTab = ({ child, refresh, setRefresh }) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();

  const [modal, setModal] = useState();

  const handleModalBehaviour = (record, purpose, resourceName) => {
    const modalKey = Date.now();
    setModal(
      <CreateEditParentModal
        key={modalKey}
        refresh={refresh}
        setRefresh={setRefresh}
        record={record}
        purpose={purpose}
        childName={`${child?.first_name} ${child?.last_name}`}
        resourceName={resourceName}
      />
    );
  };

  const discriminateParents = (arrayTypes) => {
    const array = [];
    (child?.parents || []).forEach((parent) => {
      if (parent.parent) {
        if (arrayTypes.includes(parent.relationship)) {
          array.push(parent);
        }
      }
    });
    return array;
  };

  const removeFromParents = async (recordID, origin) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/children/remove-parent/${recordID}/${child._id}/${origin}`
      });
      setRefresh(!refresh);
    } catch (e) {
      message(e);
    }
  };

  const parentsColumns = useColumns(handleModalBehaviour, removeFromParents);
  const siblingsColumns = useSiblingColumns(
    handleModalBehaviour,
    removeFromParents
  );

  const parentsData = discriminateParents([
    'MOTHER',
    'FATHER',
    'PARENT-IN-LAW'
  ]);

  const othersData = discriminateParents([
    'AUNT/UNCLE',
    'COUSIN',
    'TUTOR',
    'NANNY',
    'GRAND-PARENT',
    'OTHER'
  ]);

  const siblingsOut = discriminateParents(['BROTHER/SISTER']);
  const siblingsIn = child?.siblings;

  return (
    <>
      <Flex justify="flex-end">
        <Button
          onClick={() => handleModalBehaviour(undefined, 'create', 'parent')}
          type="primary"
        >
          <PlusOutlined />
          {t('buttons.create')}
        </Button>
      </Flex>
      <Col span={24}>
        <Divider orientation="left">
          {t('children.show.parents.dividers.parents')}
        </Divider>
        <Table
          dataSource={parentsData}
          columns={parentsColumns}
          pagination={false}
          scroll={{ x: 1000 }}
        />
      </Col>
      <Col span={24}>
        <Divider orientation="left">
          {t('children.show.parents.dividers.close')}
        </Divider>
        <Table
          dataSource={othersData}
          columns={parentsColumns}
          pagination={false}
          scroll={{ x: 1000 }}
        />
      </Col>
      <Col span={24}>
        <Divider orientation="left">
          {t('children.show.parents.dividers.bro_sis_out')}
        </Divider>
        <Table
          dataSource={siblingsOut}
          columns={parentsColumns}
          pagination={false}
          scroll={{ x: 1000 }}
        />
      </Col>
      <Col span={24}>
        <Divider orientation="left">
          {t('children.show.parents.dividers.bro_sis_in')}
        </Divider>
        <Table
          dataSource={siblingsIn}
          columns={siblingsColumns}
          pagination={false}
          scroll={{ x: 1000 }}
        />
      </Col>
      {modal}
    </>
  );
};

ParentsTab.propTypes = {
  child: PropTypes.shape({
    _id: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    parents: PropTypes.arrayOf(PropTypes.shape({})),
    siblings: PropTypes.arrayOf(PropTypes.shape({}))
  }),
  refresh: PropTypes.bool,
  setRefresh: PropTypes.func
};

ParentsTab.defaultProps = {
  child: null,
  refresh: false,
  setRefresh: null
};
