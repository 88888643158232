import { Form, Modal, Select, Switch, message as antDmessage } from 'antd';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TextArea from 'antd/es/input/TextArea';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { ModalTail } from '../../../../components/ModalTail/ModalTail';

/**
 * EditDietModal component for editing diet information.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} props.forceRefresh - A boolean flag indicating whether the component should force a refresh.
 * @param {function} props.setForceRefresh - A function to set the refresh state.
 * @param {Object} props.record - The diet record to be edited.
 * @param {string} props.childName - The name of the child associated with the diet information.
 * @returns {React.Element} The rendered EditDietModal component.
 */
export const EditHealthModal = ({
  forceRefresh,
  setForceRefresh,
  record,
  childName
}) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [allergies, setAllergies] = useState([]);
  const [illnesses, setIllnesses] = useState([]);
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [form] = Form.useForm();
  const { message } = useErrorMessage();

  const [isModalOpenedLocaly, setIsModalOpenedLocaly] = useState(true);
  const closeModal = () => {
    setIsModalOpenedLocaly(false);
  };

  const getAllergies = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/allergies'
      });
      setAllergies(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getIllnesses = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/childhood-illnesses'
      });
      setIllnesses(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getAllergies();
    await getIllnesses();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  const handleSubmit = async (body) => {
    setIsSubmitting(true);
    try {
      const url = `health/${record._id}`;
      await dispatchAPI('PATCH', {
        url,
        body
      });
      closeModal();
      setForceRefresh(!forceRefresh);
      antDmessage.success(t('children.show.health.success'));
    } catch (error) {
      message(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const illnessesOptions = [
    ...illnesses.map((illness) => ({
      key: illness._id,
      label: illness.label,
      value: illness._id
    }))
  ];

  const allergiesOptions = [
    ...allergies.map((allergy) => ({
      key: allergy._id,
      label: allergy.label,
      value: allergy._id
    }))
  ];
  useEffect(() => {
    form.setFieldsValue({
      ...record,
      childhood_illnesses: record.childhood_illnesses.map(
        (illness) => illness._id
      ),
      allergies: record.allergies.map((allergy) => allergy._id)
    });
  }, [record, form]);

  return (
    <Modal
      title={childName}
      open={isModalOpenedLocaly}
      onCancel={closeModal}
      footer={null}
      width={800}
    >
      <Form form={form} onFinish={handleSubmit}>
        <Form.Item
          name={['allergies']}
          label={t('contract.form.health.allergies')}
        >
          <Select
            options={allergiesOptions}
            mode="multiple"
            allowClear
            loading={isFieldsLoading}
          />
        </Form.Item>
        <Form.Item
          name={['childhood_illnesses']}
          label={t('contract.form.health.childhood_illnesses')}
        >
          <Select
            options={illnessesOptions}
            mode="multiple"
            allowClear
            loading={isFieldsLoading}
          />
        </Form.Item>
        <Form.Item
          name={['disability']}
          label={t('contract.form.health.disability')}
          valuePropName="checked"
        >
          <Switch checkedChildren={t('yes')} unCheckedChildren={t('no')} />
        </Form.Item>
        <Form.Item
          name={['personalized_care_plan']}
          label={t('contract.form.health.personalized_care_plan')}
        >
          <TextArea rows={4} />
        </Form.Item>
        <Form.Item name={['comment']} label={t('contract.form.health.comment')}>
          <TextArea rows={4} />
        </Form.Item>
        <ModalTail isSubmitting={isSubmitting} handleModal={closeModal} />
      </Form>
    </Modal>
  );
};

EditHealthModal.propTypes = {
  forceRefresh: PropTypes.bool,
  setForceRefresh: PropTypes.func,
  record: PropTypes.shape({
    _id: PropTypes.string,
    childhood_illnesses: PropTypes.arrayOf(PropTypes.shape({})),
    allergies: PropTypes.arrayOf(PropTypes.shape({}))
  }),
  childName: PropTypes.string
};

EditHealthModal.defaultProps = {
  forceRefresh: false,
  setForceRefresh: null,
  record: null,
  childName: undefined
};
