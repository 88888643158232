import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import {
  Modal,
  Select,
  Radio,
  Button,
  Form,
  Input,
  Flex,
  message as AntDmessage
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useSocketContext } from '../../../contexts/SocketContext';
import { useConversationContext } from '../../../contexts/ConversationContext';
import { useErrorMessage } from '../../../utils/errorMessage';

const { Option } = Select;
const { TextArea } = Input;

/**
 * @component
 * @name NewMessageModal
 * @description A component that displays a modal to start a new conversation
 * @param {boolean} open The state of the modal
 * @param {function} onCancel The function to close the modal
 * @returns {jsx} jsx - NewMessageModal component
 */
export const NewMessageModal = ({ open, onCancel }) => {
  const { t } = useTranslation();
  const {
    user: { _id, role },
    dispatchAPI,
    daycare
  } = useAuthContext();
  const { socket } = useSocketContext();
  const { setRefreshConversation, refreshConversation } =
    useConversationContext();
  const { message } = useErrorMessage();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [parents, setParents] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [filter, setFilter] = useState('PARENTS');

  const onChange = ({ target: { value } }) => setFilter(value);

  const getUsers = async () => {
    try {
      setLoading(true);
      const { data } = await dispatchAPI('GET', {
        url: `/users?daycare=${daycare}`
      });

      const parentsList = data.filter((item) => item.role.includes('PARENT'));
      const employeesList = data
        .filter((item) => !item.role.includes('PARENT'))
        .filter((item) => item._id !== _id);

      setParents(parentsList);
      setEmployees(employeesList);
    } catch (error) {
      message(error);
    } finally {
      setLoading(false);
    }
  };

  const onFinish = async (values) => {
    if (!role.includes('TABLET')) {
      try {
        setLoading(true);
        const body = {
          ...values,
          from: _id,
          category: filter,
          daycare
        };

        await dispatchAPI('POST', {
          url: '/conversations',
          body
        });

        if (socket) socket.emit('newMessage', { to: body.to });
        setRefreshConversation(!refreshConversation);
        form.resetFields();
        onCancel();
      } catch (error) {
        message(error);
      } finally {
        setLoading(false);
      }
    } else {
      AntDmessage.error(t('errors.messages.cannot_send_message_from_tablet'));
    }
  };

  useEffect(() => {
    getUsers();
  }, [daycare]);

  const parentOptions = parents.map((parent) => (
    <Option key={parent._id} value={parent._id}>
      {`${parent?.first_name} ${parent?.last_name}`}
    </Option>
  ));

  const employeeOptions = employees.map((employee) => (
    <Option key={employee._id} value={employee._id}>
      {`${employee?.first_name} ${employee?.last_name}`}
    </Option>
  ));

  return (
    <Modal
      title={t('messages_and_notes.titles.start_new_conversation')}
      width={640}
      open={open}
      onCancel={onCancel}
      footer={null}
    >
      <Flex vertical gap="middle">
        <Radio.Group defaultValue={filter} onChange={onChange}>
          <Radio.Button value="PARENTS">
            {t('messages_and_notes.form.with_parent')}
          </Radio.Button>
          <Radio.Button value="EMPLOYEES">
            {t('messages_and_notes.form.with_employee')}
          </Radio.Button>
        </Radio.Group>
        <Form form={form} onFinish={onFinish}>
          <Form.Item
            name="to"
            label={t('messages_and_notes.form.select_recipient')}
            rules={[
              {
                required: true,
                message: t('messages_and_notes.errors.missing_recipient')
              }
            ]}
          >
            <Select
              showSearch
              loading={loading}
              placeholder={t('placeholder.research')}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {filter === 'PARENTS' ? parentOptions : employeeOptions}
            </Select>
          </Form.Item>
          <Form.Item
            name="content"
            rules={[
              {
                required: true,
                message: t('messages_and_notes.errors.missing_content')
              }
            ]}
          >
            <TextArea style={{ resize: 'none' }} rows={8} />
          </Form.Item>
          <Flex justify="flex-end" gap="small">
            <Button
              onClick={() => {
                onCancel();
                form.resetFields();
              }}
            >
              {t('buttons.cancel')}
            </Button>
            <Button type="primary" htmlType="submit">
              {t('buttons.send')}
            </Button>
          </Flex>
        </Form>
      </Flex>
    </Modal>
  );
};

NewMessageModal.propTypes = {
  open: PropTypes.bool,
  onCancel: PropTypes.func
};

NewMessageModal.defaultProps = {
  open: false,
  onCancel: () => {}
};
