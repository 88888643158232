import React, { useState, useEffect } from 'react';
import { DatePicker, Flex, Select, Tag } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { ListResource } from '../../../components';
import { useColumns } from './columns';
import { headers } from './headers';
import { paymentStatuses } from '../../../utils/constants/tagColors';
import { useFormatter } from '../../../utils/formatter';
import PaymentModal from './PaymentModal';
import DetailPaymentModal from './DetailPaymentModal';

const { Option } = Select;
const { RangePicker } = DatePicker;

/**
 * @component
 * @name ListPayments
 * @description Displays the list of payments
 * @returns {component} ListPayments component
 */
export const ListPayments = () => {
  const { paymentsDataFormatter } = useFormatter();
  const { t } = useTranslation();
  const { dispatchAPI, daycare } = useAuthContext();
  const { message } = useErrorMessage();
  const [isLoading, setIsLoading] = useState(false);
  const [enums, setEnums] = useState();
  const [customerAccounts, setCustomerAccounts] = useState([]);
  const [filterDateRange, setFilterDateRange] = useState([]);
  const [status, setStatus] = useState([]);
  const [customerAccountIds, setCustomerAccountIds] = useState([]);
  const [bankAccounts, setBankAccounts] = useState([]);
  const [selectedBankAccount, setSelectedBankAccount] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [paymentId, setPaymentId] = useState('');
  const [purpose, setPurpose] = useState('create');
  const [refresh, setRefresh] = useState(false);
  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
  const [paymentDetails, setPaymentDetails] = useState([]);

  const onEditAction = (id) => {
    setPurpose('edit');
    setPaymentId(id);
    setIsModalOpen(true);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const onDeleteAction = async (id) => {
    try {
      await dispatchAPI('DELETE', {
        url: `/payments/${id}`
      });
      setRefresh(!refresh);
    } catch (e) {
      message(e);
    }
  };

  const openDetailsModal = (data) => {
    setPaymentDetails({ ...data, type: 'REGUL' });
    setIsDetailModalOpen(true);
  };

  const closeDetailsModal = () => {
    setPaymentDetails([]);
    setIsDetailModalOpen(false);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const columns = useColumns(
    enums,
    onEditAction,
    onDeleteAction,
    openDetailsModal
  );

  const onsearchStatus = (input, option) =>
    option.props['data-label'].toLowerCase().indexOf(input.toLowerCase()) >= 0;

  const onsearchCustomerAccount = (input, option) =>
    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  const getBankAccounts = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/daycares/${daycare}?populate=invoicing_configuration`
      });
      setSelectedBankAccount(
        data?.invoicing_configuration?.bank_accounts?.find(
          (account) => account.is_principal_account === true
        )?.bank_name
      );
      setBankAccounts(data?.invoicing_configuration?.bank_accounts);
    } catch (e) {
      message(e);
    }
  };

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/payments/enums' });
      setEnums(data);
    } catch (e) {
      message(e);
    }
  };

  const getCustomerAccount = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/customer-account' });
      setCustomerAccounts(data);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      await getEnums();
      await getCustomerAccount();
      await getBankAccounts();
      setIsLoading(false);
    })();
  }, [daycare]);

  return (
    <>
      {isDetailModalOpen && (
        <DetailPaymentModal
          isDetailModalOpen={isDetailModalOpen}
          closeDetailsModal={closeDetailsModal}
          paymentDetails={paymentDetails}
        />
      )}
      {isModalOpen && (
        <PaymentModal
          isModalOpen={isModalOpen}
          closeModal={closeModal}
          purpose={purpose}
          paymentId={paymentId}
          daycare={daycare}
          setRefresh={setRefresh}
        />
      )}
      <ListResource
        resourceName="payments"
        populate="customer_account,linked_payment,invoice"
        columns={columns}
        headers={headers}
        formatter={paymentsDataFormatter}
        resourceModelName="Payments"
        withImportButton={false}
        archivedButton={false}
        openModal
        setIsModalOpen={setIsModalOpen}
        showModal={showModal}
        withoutBreadCrums
        customActionColumn
        onDoubleClickAction={null}
        forceRefresh={refresh}
        extraQuery={`daycare=${daycare}${
          selectedBankAccount ? `&bank_account=${selectedBankAccount}` : ''
        }${
          filterDateRange && filterDateRange.length
            ? `&operation_date>=${dayjs(
                filterDateRange[0]
              ).toISOString()}&operation_date<=${dayjs(
                filterDateRange[1]
              ).toISOString()}`
            : ''
        }${status.length ? `&status=${status}` : ''}${
          customerAccountIds.length
            ? `&customer_account=${customerAccountIds}`
            : ''
        }`}
        extraFilters={
          <Select
            allowClear
            style={{ width: 240 }}
            placeholder={t('payments.select_bank_account')}
            loading={isLoading}
            value={selectedBankAccount}
            onSelect={(value) => {
              setSelectedBankAccount(value);
            }}
            onClear={() => {
              setSelectedBankAccount('');
            }}
          >
            {bankAccounts?.map((account) => (
              <Option key={account._id} value={account.bank_name}>
                {account.bank_name}
              </Option>
            ))}
          </Select>
        }
        extraButtons={
          <Flex gap="small">
            <RangePicker
              format="DD/MM/YYYY"
              onChange={(dateRange) => setFilterDateRange(dateRange)}
            />
            <Select
              style={{ width: 240 }}
              showSearch
              mode="multiple"
              allowClear
              placeholder="Statut(s)"
              loading={isLoading}
              filterOption={(input, option) => onsearchStatus(input, option)}
              onSelect={(value) => {
                setStatus([...status, value]);
              }}
              onDeselect={(value) =>
                setStatus((prevStatus) =>
                  prevStatus.filter((item) => item !== value)
                )
              }
              onClear={() => {
                setStatus([]);
              }}
            >
              {enums?.status?.map((s) => (
                <Option key={s} value={s} data-label={t(`invoices.tags.${s}`)}>
                  <Tag color={paymentStatuses[s]}>
                    {t(`invoices.tags.${s}`)}
                  </Tag>
                </Option>
              ))}
            </Select>
            <Select
              style={{ width: 240 }}
              showSearch
              mode="multiple"
              allowClear
              placeholder={t('payments.customer_accounts')}
              loading={isLoading}
              filterOption={(input, option) =>
                onsearchCustomerAccount(input, option)
              }
              onSelect={(value) => {
                setCustomerAccountIds([...customerAccountIds, value]);
              }}
              onDeselect={(value) =>
                setCustomerAccountIds((prevStatus) =>
                  prevStatus.filter((item) => item !== value)
                )
              }
              onClear={() => {
                setCustomerAccountIds([]);
              }}
            >
              {customerAccounts?.map((c) => (
                <Option key={c._id} value={c._id}>
                  {c.label}
                </Option>
              ))}
            </Select>
          </Flex>
        }
      />
    </>
  );
};
