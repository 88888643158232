import { useTranslation } from 'react-i18next';
import { Col, Flex, Row, Select } from 'antd';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { ContentCustom } from '../../../components/ContentCustom/ContentCustom';
import { PageHeaderCustom } from '../../../components/PageHeader/PageHeader';
import { PlanningHeader } from '../PlanningHeader';
import { HoursBar } from '../../../components/HoursBar/HoursBar';
import { CustomSpin } from '../../../components/CustomSpin/CustomSpin';
import { TimeSlots } from '../children/TimeSlots';
import { usePlanningContext } from '../../../contexts/PlanningContext';
import { EmployeeRow } from './EmployeeRow';
import { useAuthContext } from '../../../contexts/AuthContext';
import EventModal from '../../employee/planning/EventModal';

const { Option } = Select;

/**
 * EmployeesPlanning component displays the planning for employees, including time slots and events.
 *
 * @returns {JSX.Element} EmployeesPlanning component JSX.
 */
export const EmployeesPlanning = () => {
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const {
    isSelectLoading,
    selectOptions,
    setSelectedGroup,
    isPlanningLoading,
    timeSlots,
    employees,
    deleteEvent,
    selectedDay,
    refresh,
    setRefresh
  } = usePlanningContext();
  const [selectedEmployee, setSelectedEmployee] = useState();
  const [selectedType, setSelectedType] = useState();
  const [open, setOpen] = useState(false);
  const [eventEditId, setEventEditId] = useState();
  const [recorderId, setRecorderId] = useState('');
  const [purpose, setPurpose] = useState();
  const [referenceDay, setReferenceDay] = useState([]);
  const [displayPlanningRangeBarButtons, setDisplayPlanningRangeBarButtons] =
    useState(true);

  useEffect(() => {
    const relativeHeaderMonth = dayjs(selectedDay).month();
    const relativeHeaderYear = dayjs(selectedDay).year();
    const todayMonth = dayjs().month();
    const todayYear = dayjs().year();

    const boolean =
      relativeHeaderMonth >= todayMonth - 1 && todayYear === relativeHeaderYear;

    const userBoolean = user.role === 'users:TABLET';

    setDisplayPlanningRangeBarButtons(boolean && !userBoolean);
  }, [selectedDay]);

  const openModal = (type, employee, purp, eventId, timeRecorderId, refDay) => {
    setSelectedEmployee(employee);
    setPurpose(purp);
    setSelectedType(type);
    setEventEditId(eventId);
    setRecorderId(timeRecorderId);
    setOpen(true);
    setReferenceDay(refDay);
  };

  const closeModal = () => {
    setPurpose('');
    setSelectedType('');
    setEventEditId('');
    setRecorderId('');
    setReferenceDay([]);
    setOpen(false);
  };

  return (
    <ContentCustom>
      <PageHeaderCustom
        withoutBreadCrums
        withoutNavigateBack
        title={t('plannings.employees.title')}
      />
      {open && (
        <EventModal
          isModalOpen={open}
          selectedEmployee={selectedEmployee}
          closeModal={closeModal}
          purpose={purpose}
          type={selectedType}
          eventId={eventEditId}
          recorderId={recorderId}
          selectedDay={selectedDay}
          refresh={refresh}
          setRefresh={setRefresh}
          referenceDay={referenceDay}
        />
      )}
      <PlanningHeader />
      <Flex vertical gap="small">
        <Row align="middle">
          <Col span={4}>
            <Select
              style={{ width: '95%' }}
              loading={isSelectLoading}
              defaultValue={selectOptions[0]}
              onChange={(value) => setSelectedGroup(value)}
            >
              {selectOptions.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </Col>
          <Col span={18}>
            <table>
              <HoursBar />
            </table>
          </Col>
        </Row>
        {isPlanningLoading ? (
          <CustomSpin />
        ) : (
          <Flex vertical gap="small">
            {timeSlots?.map((slot) => (
              <TimeSlots
                key={slot?.isWalking}
                group={slot?.isWalking}
                slotEvents={slot?.slotEvents}
              />
            ))}
            {(employees || []).map((event) => (
              <EmployeeRow
                key={event._id}
                event={event}
                openModal={openModal}
                deleteEvent={deleteEvent}
                displayButtons={displayPlanningRangeBarButtons}
              />
            ))}
          </Flex>
        )}
      </Flex>
    </ContentCustom>
  );
};
