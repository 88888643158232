import { useTranslation } from 'react-i18next';
import { Button, DatePicker, Skeleton, Tabs, Row } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useState } from 'react';
import dayjs from 'dayjs';
import { PageHeaderCustom } from '../../components/PageHeader/PageHeader';
import { ContentCustom } from '../../components/ContentCustom/ContentCustom';
import { CreateUpdateActivityModal } from './CreateUpdateActivityModal';
import { useFollowUpContext } from '../../contexts/FollowUpContext';
import { tabletItems } from './utils/tabletItems';
import { useAuthContext } from '../../contexts/AuthContext';
import { parentItems } from './utils/parentItems';

/**
 * A component for managing and displaying children's follow-up information.
 *
 * This component renders a page header, date picker, and tabs for navigating between different groups
 * or children for follow-up. It utilizes the `useFollowUpContext` for accessing follow-up related data
 * and `useAuthContext` for user information. Depending on the user's role, it displays either tablet items
 * or parent items. It also includes a modal for creating or updating activities, and a button for adding new
 * activities if the selected date is today.
 *
 * @component
 * @returns {JSX.Element} The JSX rendering of the children follow-up page.
 *
 * @example
 * return (
 *   <ChildrenFollowUp />
 * )
 */
export const ChildrenFollowUp = () => {
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const { groups, setDate, daysChildren, date, activities, setChild } =
    useFollowUpContext();
  const [modal, setModal] = useState();

  const handleModalBehaviour = (record, purpose) => {
    const modalKey = Date.now();
    setModal(
      <CreateUpdateActivityModal
        key={modalKey}
        record={record}
        purpose={purpose}
      />
    );
  };

  const items =
    user?.role === 'users:TABLET'
      ? tabletItems(groups, handleModalBehaviour, activities, t)
      : parentItems(daysChildren, activities);

  const isToday = date === dayjs().format('YYYY-MM-DD');

  const handleTabChange = (value) => {
    if (user.role === 'users:TABLET') {
      setChild({});
    } else {
      const pickedChild = daysChildren.find((child) => child._id === value);
      setChild(pickedChild);
    }
  };

  return (
    <>
      <PageHeaderCustom
        title={t('children-follow-up.title')}
        extra={
          <DatePicker
            format="DD/MM/YYYY"
            onChange={(value) => {
              if (value) {
                setDate(value.format('YYYY-MM-DD'));
              } else setDate(dayjs().format('YYYY-MM-DD'));
            }}
          />
        }
      />

      <ContentCustom>
        <Skeleton loading={!daysChildren}>
          {items.length ? (
            <Tabs
              items={items}
              defaultActiveKey={
                user.role === 'users:TABLET' ? '0' : daysChildren?.[0]?._id
              }
              onChange={(value) => handleTabChange(value)}
              type="card"
            />
          ) : (
            <Row justify="center" align="center" style={{ padding: 16 }}>
              {t('children-follow-up.form.no_children')}
            </Row>
          )}
        </Skeleton>
        {isToday && user.role === 'users:TABLET' && (
          <Button
            onClick={() => handleModalBehaviour(null, 'create')}
            shape="circle"
            type="primary"
            className="add-followup-btn"
          >
            <PlusOutlined />
          </Button>
        )}
      </ContentCustom>
      {modal}
    </>
  );
};
