import { useState } from 'react';
import { DatePicker, Form, Input, Select, Upload } from 'antd';
import { useTranslation } from 'react-i18next';
import { CameraOutlined } from '@ant-design/icons/lib';
import { useErrorMessage } from '../../utils/errorMessage';
import { handleDepartment } from '../../utils/handleDepartment';

const { Option } = Select;
const { Dragger } = Upload;

/**
 * Converts a File object to a base64 encoded string.
 *
 * @function
 *
 * @param {File} file - The File object to convert.
 * @returns {Promise<string>} A promise that resolves with the base64 encoded string representation of the file.
 */
export const fileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (e) => reject(e);
  });

/**
 * Custom hook for managing form fields and file uploads.
 *
 * @hook
 *
 * @param {Object} enums - Enumerations for form fields.
 * @param {Object} form - Ant Design form instance.
 * @returns {Object} Object containing form fields and base64 representations of uploaded files.
 */
export const useFields = (enums, form) => {
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const [fileList, setFileList] = useState([]);
  const [base64List, setBase64List] = useState({});

  const handleFileChange = (field, file) => {
    setFileList((prevFileList) => ({
      ...prevFileList,
      [field]: file
    }));
  };

  const handleFileRemove = (field) => {
    setFileList((prevFileList) => {
      const updatedFileList = { ...prevFileList };
      delete updatedFileList[field];
      return updatedFileList;
    });
    setBase64List((prevBase64List) => {
      const updatedBase64List = { ...prevBase64List };
      delete updatedBase64List[field];
      return updatedBase64List;
    });
  };

  const handleBeforeUpload = async (field, file) => {
    const fileExtension = file.name.split('.').pop();
    if (
      fileExtension === 'png' ||
      fileExtension === 'PNG' ||
      fileExtension === 'jpg' ||
      fileExtension === 'JPG'
    ) {
      handleFileChange(field, file);
      const base64 = await fileToBase64(file);
      setBase64List((prevBase64List) => ({
        ...prevBase64List,
        [field]: base64
      }));
      return false;
    }
    message('Not a PNG or JPG file.');
    return true;
  };

  const stateOptions = [
    {
      label: t('daycares.form.Guadeloupe'),
      value: 'Guadeloupe'
    },
    {
      label: t('daycares.form.Guyane'),
      value: 'Guyane'
    }
  ];

  const fields = [
    {
      name: ['name'],
      rules: [{ required: true }]
    },
    {
      name: ['opening_date'],
      rules: [{ required: true }],
      input: <DatePicker />
    },
    {
      label: 'phone_number.number',
      name: ['phone_number'],
      rules: [{ required: true }],
      input: (
        <Input.Group compact>
          <Form.Item
            noStyle
            name={['phone_number', 'country_code']}
            initialValue="+33"
          >
            <Select style={{ width: '25%' }}>
              <Option value="+33">+33</Option>
            </Select>
          </Form.Item>
          <Form.Item noStyle name={['phone_number', 'number']}>
            <Input type="number" style={{ width: '75%' }} />
          </Form.Item>
        </Input.Group>
      )
    },
    {
      name: ['email'],
      rules: [{ required: true }, { type: 'email' }]
    },
    {
      label: 'address.number',
      name: ['address', 'number'],
      rules: [{ required: true }],
      input: <Input type="number" />
    },
    {
      label: 'address.street',
      rules: [{ required: true }],
      name: ['address', 'street']
    },
    {
      label: 'address.additional',
      name: ['address', 'additional']
    },
    {
      label: 'address.postcode',
      rules: [{ required: true }],
      name: ['address', 'postal_code'],
      input: (
        <Input
          type="number"
          onChange={(e) => handleDepartment(e, form, ['address', 'state'])}
        />
      )
    },
    {
      label: 'address.city',
      rules: [{ required: true }],
      name: ['address', 'city']
    },
    {
      label: 'address.state',
      rules: [{ required: true }],
      name: ['address', 'state'],
      input: <Select options={stateOptions} />
    },
    {
      label: 'timezone',
      rules: [{ required: true }],
      name: ['timezone'],
      input: (
        <Select>
          {(enums?.timezones || []).map((timezone) => (
            <Option key={timezone} value={timezone}>
              {timezone}
            </Option>
          ))}
        </Select>
      )
    },
    {
      label: 'capacity',
      rules: [{ required: true }],
      name: ['capacity'],
      input: <Input type="number" />
    },
    {
      label: 'siret',
      rules: [{ required: true }],
      name: ['siret']
    },
    {
      label: 'ape',
      rules: [{ required: true }],
      name: ['ape']
    },
    {
      rules: [{ required: true }],
      name: ['ics']
    },
    {
      rules: [{ required: true }],
      name: ['new_place_bonus'],
      input: <Input type="number" />
    },
    {
      label: 'capital',
      rules: [{ required: true }],
      name: ['capital'],
      input: <Input type="number" />
    },
    {
      name: ['logo'],
      input: (
        <Dragger
          onRemove={() => handleFileRemove('logo')}
          beforeUpload={(file) => handleBeforeUpload('logo', file)}
          fileList={fileList.logo ? [fileList.logo] : []}
        >
          <p className="ant-upload-drag-icon">
            <CameraOutlined style={{ color: 'var(--textColor)' }} />
          </p>
          <p className="ant-upload-text">{t('files.create.action')}</p>
        </Dragger>
      )
    },
    {
      name: ['manager_signature'],
      input: (
        <Dragger
          onRemove={() => handleFileRemove('manager_signature')}
          beforeUpload={(file) => handleBeforeUpload('manager_signature', file)}
          fileList={
            fileList.manager_signature ? [fileList.manager_signature] : []
          }
        >
          <p className="ant-upload-drag-icon">
            <CameraOutlined style={{ color: 'var(--textColor)' }} />
          </p>
          <p className="ant-upload-text">{t('files.create.action')}</p>
        </Dragger>
      )
    },
    {
      name: ['stamp'],
      input: (
        <Dragger
          onRemove={() => handleFileRemove('stamp')}
          beforeUpload={(file) => handleBeforeUpload('stamp', file)}
          fileList={fileList.stamp ? [fileList.stamp] : []}
        >
          <p className="ant-upload-drag-icon">
            <CameraOutlined style={{ color: 'var(--textColor)' }} />
          </p>
          <p className="ant-upload-text">{t('files.create.action')}</p>
        </Dragger>
      )
    }
  ];

  return {
    fields,
    base64List
  };
};
