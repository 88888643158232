import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

/**
 * Calculate a person's age based on their birthdate and format it as a string.
 *
 * @param {string} date - The birthdate in a valid date string format (e.g., 'YYYY-MM-DD').
 * @param {function} t - A translation function for localization.
 * @returns {string} A formatted string representing the person's age, or an empty string if `date` is falsy.
 */
export const getAge = (date, t) => {
  if (date) {
    const birthdayDate = dayjs(date);
    const today = dayjs();

    const ageInYears = today.diff(birthdayDate, 'year');

    birthdayDate.add(ageInYears, 'year');
    const ageInMonths = today.diff(birthdayDate, 'month');

    birthdayDate.add(ageInMonths, 'month');
    const ageInWeeks = today.diff(birthdayDate, 'week');

    birthdayDate.add(ageInWeeks, 'week');
    const ageInDays = today.diff(birthdayDate, 'day');

    switch (true) {
      case ageInDays < 0:
        return '';
      case ageInYears > 0:
        return (
          <div>{`${ageInYears} ${t('pre-registrations.form.years')}`}</div>
        );
      case ageInMonths > 0:
        return (
          <div>{`${ageInMonths} ${t('pre-registrations.form.months')}`}</div>
        );
      case ageInWeeks > 0:
        return (
          <div>{`${ageInWeeks} ${t('pre-registrations.form.weeks')}`}</div>
        );
      default:
        return (
          <div>{`${ageInDays} ${t('pre-registrations.form.date_days')}`}</div>
        );
    }
  }
  return '';
};
