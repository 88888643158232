import {
  Checkbox,
  DatePicker,
  Input,
  Tag,
  Form,
  Upload,
  Button,
  TimePicker
} from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import { formItemLayout } from '../../../utils/constants/formLayout';
import { visibilitiesColors } from '../../../utils/constants/tagColors';
import { useErrorMessage } from '../../../utils/errorMessage';
import { useAuthContext } from '../../../contexts/AuthContext';
import { draggerProps } from '../TabsContent/News/utils';

const { TextArea } = Input;

/**
 * A React component for creating and managing event details within a communication platform.
 * This form allows users to input and modify various details related to events, such as title,
 * dates, target groups, visibility period, and descriptions. It also includes options for attaching images
 * and other files.
 *
 * @component
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Object} props.enums - Enumerated values for the form fields, like visibility and topic.
 * @param {Array} props.fileList - An array of file objects to be attached to the event.
 * @param {Function} props.setFileList - A function to update the state of fileList.
 * @param {Object} props.purpose - Purpose of the form.
 *
 * @returns {React.Component} A form component for creating or editing event details.
 */
export const EventsForm = ({ enums, fileList, setFileList, purpose }) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [groups, setGroups] = useState([]);

  const getGroups = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `children-groups`
      });
      setGroups(data);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      await getGroups();
    })();
  }, []);

  const visibilityOptions = (enums?.visibility || []).map((enumItem) => ({
    label: (
      <Tag color={visibilitiesColors[enumItem]}>
        {t(`communication.documents.list.type.${enumItem}`)}
      </Tag>
    ),
    value: enumItem
  }));

  const groupOptions = (groups || []).map((group) => ({
    label: <Tag color={group?.tag_color}>{group.label}</Tag>,
    value: group._id
  }));

  return (
    <>
      <Form.Item
        name={['title']}
        label={t('communication.events.modal.event_title')}
        {...formItemLayout}
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={['date']}
        label={t('communication.events.modal.date')}
        {...formItemLayout}
        rules={[{ required: true }]}
      >
        <DatePicker format="DD/MM/YYYY" />
      </Form.Item>
      <Form.Item
        name={['hours']}
        label={t('communication.events.modal.hours')}
        {...formItemLayout}
        rules={[{ required: true }]}
      >
        <TimePicker.RangePicker format="HH:mm" />
      </Form.Item>
      <Form.Item
        name={['groups']}
        label={t('communication.events.modal.groups')}
        {...formItemLayout}
        rules={[{ required: true }]}
      >
        <Checkbox.Group options={groupOptions} />
      </Form.Item>
      <Form.Item
        name={['start_visibility']}
        label={t('communication.events.modal.start_visibility')}
        {...formItemLayout}
        rules={[{ required: true }]}
      >
        <DatePicker format="DD/MM/YYYY" />
      </Form.Item>
      <Form.Item
        name={['end_visibility']}
        label={t('communication.events.modal.end_visibility')}
        {...formItemLayout}
      >
        <DatePicker format="DD/MM/YYYY" />
      </Form.Item>
      <Form.Item
        name={['visibility']}
        label={t('communication.events.modal.visibility')}
        {...formItemLayout}
        rules={[{ required: true }]}
      >
        <Checkbox.Group options={visibilityOptions} />
      </Form.Item>
      <Form.Item
        name={['description']}
        label={t('communication.events.modal.description')}
        {...formItemLayout}
        rules={[{ required: true }]}
      >
        <TextArea />
      </Form.Item>
      <Form.Item
        name={['thumbnail']}
        label={t('communication.events.modal.thumbnail')}
        rules={[{ required: true }]}
      >
        <Upload
          {...draggerProps(
            'thumbnail',
            false,
            purpose,
            t,
            fileList,
            setFileList,
            message
          )}
        >
          <Button icon={<UploadOutlined />}>
            {t('communication.news.modal.thumbnail')}
          </Button>
        </Upload>
      </Form.Item>
      <Form.Item
        name={['thumbnail']}
        label={t('communication.events.modal.attachment')}
      >
        <Upload
          {...draggerProps(
            'attachment',
            false,
            purpose,
            t,
            fileList,
            setFileList,
            message
          )}
        >
          <Button icon={<UploadOutlined />}>
            {t('communication.events.modal.attachment')}
          </Button>
        </Upload>
      </Form.Item>
    </>
  );
};

EventsForm.propTypes = {
  enums: PropTypes.shape({
    visibility: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string)
    ])
  }),
  fileList: PropTypes.shape({}),
  setFileList: PropTypes.func,
  record: PropTypes.shape({
    content: PropTypes.string
  }),
  purpose: PropTypes.string
};

EventsForm.defaultProps = {
  enums: null,
  record: null,
  fileList: [],
  setFileList: null,
  purpose: undefined
};
