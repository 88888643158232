/**
 * Prints a specific HTML element to a new browser window and allows for printing.
 *
 * @function
 *
 * @example
 *
 * @return {null}
 * // Call this function to print the content of an element with the ID "printable-planning".
 * printPlanning();
 */
export const printPlanning = () => {
  const elemToPrint = document.getElementById('printable-planning');
  const printWindow = window.open('', '_blank');
  printWindow.document.write('<html><head><title>Print</title>');

  printWindow.document.write('<style>');
  printWindow.document.write(`
    * {
      font-family: 'Poppins', sans-serif;
      color: #221E1F;
    }
    table {
      border-collapse: collapse;
      width: 100%;
    }
    .hours-bar {
      background: #6cccf41a;
      display: flex;
      width: 100%;
      position: relative;
      border-radius: 18px;
      justify-content: center;
    }
    .hours-bar tr {
      display: flex;
      width: 100%;
      justify-content: space-around;
    }
    .hours-bar th {
      padding: 4px 0;
      text-align: center;
      margin: 0;
      border: none;
    }
    .hours-bar th span {
      color: #28B2EC;
      font-size: 0.8em;
    }
    .ant-row {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
    }
    .ant-col-2 {
      display: none;
    }
    .ant-col-4 {
      flex: 0 0 16%;
      width: 16%;
      max-width: 16%;
    }
    .ant-col-18 {
      flex: 0 0 84%;
      width: 84%;
      max-width: 84%;
    }
    .div-container {
      background-color: #f0f0f0;
      border-radius: 50px;
      display: flex;
      justify-content: space-between;
      width: 100%;
      overflow: visible;
    }
    .div-container > div {
      width: 10%;
      height: 24px;
    }
    .time-slot {
      height: 5px;
      position: relative;
      width: 10%;
    }
    .time-slot-over {
      height: 20px;
      position: relative;
      background-color: #f33c52;
      margin-top: 15px;
      width: 6%;
    }
    .time-slot-below {
      height: 20px;
      position: relative;
      background-color: #40b4ed;
      margin-top: 15px;
      width: 6%;
    }
    .time-slot-selected {
      position: absolute;
      top: 0;
      bottom: 0;
      background-color: $primaryColor;
      z-index: 1;
    }
    .time-slot-selected-minus {
      position: absolute;
      top: 0;
      bottom: 0;
      background-color: #8faf93;
      background-image: linear-gradient(
        -45deg,
        transparent 25%,
        black 5%,
        black 10%,
        transparent 50%,
        transparent 75%,
        black 25%,
        black 50%
      );
      background-size: 8px 8px;
      z-index: 1;
    }
    .time-slot-selected-plus {
      position: absolute;
      top: 0;
      bottom: 0;
      background-color: #df969f;
      z-index: 1;
    }
    .time-slot-selected-absences {
      position: absolute;
      top: 0;
      bottom: 0;
      background-color: #ce7eb4;
      z-index: 1;
    }
    .time-slot-unselected {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      z-index: 0;
    }
    .visible {
      width: 10%;
    }
    .hidden {
      width: 10%;
    }
    .card-selected {
      margin: 8px;
      padding: 8px;
      cursor: pointer;
      background-color: #6cccf4;
    }
    .card-unselected {
      margin: 8px;
      padding: 8px;
      cursor: pointer;
      background-color: white;
    }
    .prereg-planning {
      margin-bottom: 16px;
    }
    .prereg-planning table {
      display: flex;
      flex-direction: column;
      width: 800px;
      gap: 8px;
    }
    .prereg-planning thead.hours-bar {
      display: flex;
      width: 105%;
      position: relative;
      left: -2%;
    }
    .prereg-planning tr {
      display: flex;
      width: 100%;
      border-radius: 18px;
      background: rgba(108, 204, 244, 0.1);
      padding-left: 12px;
      padding-right: 10px;
      gap: 32px;
    }
    .prereg-planning th {
      margin: 0;
      padding: 0;
      text-align: center;
      font-size: 12px;
      display: block;
      color: $primaryColor;
    }
    .prereg-planning th:first-child {
      position: relative;
    }
    .prereg-planning .timerange-bar {
      display: flex;
      align-items: center;
      position: relative;
      margin-bottom: 16px;
    }
    .prereg-planning td:first-child {
      width: 80px;
      position: absolute;
      left: -80px;
    }
    .prereg-planning td:last-child {
      position: absolute;
      right: -48px;
    }
    .prereg-planning td:nth-child(2) {
      flex: 1 1 auto;
    }
  `);
  printWindow.document.write('</style>');

  printWindow.document.write('</head><body>');
  printWindow.document.write(elemToPrint.innerHTML);
  printWindow.document.write('</body></html>');

  printWindow.document.close();
  printWindow.focus();

  setTimeout(() => {
    printWindow.print();
    printWindow.close();
  }, 250);
};
