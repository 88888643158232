import { useTranslation } from 'react-i18next';
import { Tag, Button, Divider, Popover } from 'antd';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import {
  EyeOutlined,
  DownloadOutlined,
  FileAddOutlined,
  EditOutlined
} from '@ant-design/icons';
import {
  invoiceStatuses,
  invoiceTypes
} from '../../../utils/constants/tagColors';
import { routes, subRoutes } from '../../../utils/constants/adminRoutes';

const iconSize = 18;

/**
 * Use columns
 * @hook
 * @name useColumns
 * @param {object} enums Enums object - status and paymentChoices
 * @param {function} onPrintAction Print action function
 * @param {function} onOpenCreditModalAction Open credit modal action function
 * @param {boolean} isSpinLoading Is spin loading
 * @param {string} idInvoice Id invoice
 * @description Custom hook to get columns for invoices list
 * @returns {Array} Columns for invoices list
 */
export const useColumns = (
  enums,
  onPrintAction,
  onOpenCreditModalAction,
  isSpinLoading,
  idInvoice
) => {
  const { t } = useTranslation();

  return [
    {
      title: t('invoices.list.number'),
      key: 'number',
      dataIndex: 'number',
      sorter: true
    },
    {
      title: t('invoices.list.customerAccounts'),
      key: 'customer_account',
      dataIndex: 'customer_account',
      render: (customer_account) =>
        (customer_account && customer_account?.label) || '-'
    },
    {
      title: t('invoices.list.child'),
      key: 'child',
      dataIndex: 'child',
      render: (child) =>
        (child &&
          `${child?.first_name || 'N/A'} ${child.last_name || 'N/A'}`) ||
        '-'
    },
    {
      title: t('invoices.list.status'),
      key: 'status',
      dataIndex: 'status',
      render: (status) => (
        <Tag color={invoiceStatuses[status]}>
          {t(`invoices.tags.${status}`)}
        </Tag>
      ),
      filters: enums?.status?.map((s) => ({
        text: t(`invoices.tags.${s}`),
        value: s
      })),
      sorter: true
    },
    {
      title: t('invoices.list.type'),
      key: 'type',
      dataIndex: 'type',
      render: (type) => (
        <Tag color={invoiceTypes[!type ? 'CLASSIC' : type]}>
          {t(`invoices.tags.${!type ? 'CLASSIC' : type}`)}
        </Tag>
      ),
      filters: enums?.types?.map((s) => ({
        text: t(`invoices.tags.${s}`),
        value: s
      })),
      sorter: true
    },
    {
      title: t('invoices.list.due_date'),
      key: 'due_date',
      dataIndex: 'due_date',
      render: (due_date) => due_date && dayjs(due_date).format('DD/MM/YYYY'),
      sorter: true
    },
    {
      title: t('invoices.list.total_with_taxes'),
      key: 'total',
      dataIndex: 'total',
      render: (total) => total && `${total} €`,
      sorter: true
    },
    {
      title: t('invoices.list.to_pay'),
      key: 'to_pay',
      dataIndex: 'to_pay',
      render: (to_pay) => to_pay && `${to_pay} €`,
      sorter: true
    },
    {
      title: t('invoices.list.payment_method'),
      key: 'payment_method.method',
      dataIndex: 'payment_method',
      render: (payment_method) =>
        payment_method &&
        `${t(`contract.form.payment_methods.${payment_method.method}`)}`,
      filters: enums?.paymentChoices?.map((p) => ({
        text: t(`contract.form.payment_methods.${p}`),
        value: p
      })),
      sorter: true
    },
    {
      key: 'action',
      align: 'right',
      render: (record) => (
        <>
          <Popover content={t('buttons.download')} placement="top">
            <Button
              type="ghost"
              loading={isSpinLoading && idInvoice === record._id}
              onClick={() => onPrintAction(record._id)}
              style={{ width: 24 }}
              icon={<DownloadOutlined style={{ fontSize: iconSize }} />}
            />
          </Popover>
          <Divider type="vertical" />
          <Popover content={t('buttons.show')} placement="top">
            <Link to={`/invoicing/invoices/show/${record._id}`}>
              <EyeOutlined style={{ fontSize: iconSize }} />
            </Link>
          </Popover>
          {record.status === 'PAID' && (
            <>
              <Divider type="vertical" />
              <Popover content={t('buttons.credit')} placement="top">
                <Button
                  type="ghost"
                  onClick={() => onOpenCreditModalAction(record)}
                  icon={<FileAddOutlined style={{ fontSize: iconSize }} />}
                />
              </Popover>
            </>
          )}
          {record.type === 'FROM_SCRATCH' && record.status === 'CREATE' && (
            <>
              <Divider type="vertical" />
              <Link
                to={{
                  pathname: `${routes.INVOICING}${subRoutes.INVOICING.INVOICES}/edit/${record?._id}`
                }}
              >
                <EditOutlined style={{ fontSize: iconSize }} />
              </Link>
            </>
          )}
        </>
      )
    }
  ];
};
