import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';
import { EditOutlined } from '@ant-design/icons';
import { outOfNavRoutes } from '../../utils/constants/adminRoutes';
import { useAuthContext } from '../../contexts/AuthContext';
import listContent from './listContent';
import {
  ContentCustom,
  PageHeaderCustom,
  DescriptionList
} from '../../components';
import { useErrorMessage } from '../../utils/errorMessage';
import { NotificationsManagement } from './NotificationsManagement';
import { useHandleResize } from '../../utils/handleResize';

/**
 * Profile component
 * @component Profile component that display current connected user informations
 * @returns {JSX.Element} Profile component
 */
const Profile = () => {
  const {
    dispatchAPI,
    user: { _id },
    token
  } = useAuthContext();
  const { t } = useTranslation();
  const [user, setUser] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { message } = useErrorMessage();
  const [refresh, setRefresh] = useState(false);
  const { width } = useHandleResize();

  /**
   * Fetch user informations
   * @function
   * @async
   * @name fetchUser
   * @description Fiecth user informations from API
   * @returns {void | object} User informations
   */
  const fetchUser = async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/users/${_id}?populate=notification_management,photo`
      });
      setUser(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setIsLoading(false);
  };

  /**
   * Fetch parent informations if user is a parent
   * @function
   * @async
   * @name fetchParentInformations
   * @description Fiecth parent informations from API
   * @returns {void | object} Parent informations
   */
  const fetchParentInformations = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/parents/user/${_id}`
      });
      setUser({ ...user, ...data.code });
    } catch (error) {
      if (error.response) message(error.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      await fetchUser();
    })();
  }, [refresh]);

  useEffect(() => {
    if (user && user?.role && user?.role.includes('PARENT')) {
      fetchParentInformations();
    }
  }, []);

  return (
    <>
      <PageHeaderCustom
        title={t('profile.title')}
        extra={
          <>
            <Link to={{ pathname: `${outOfNavRoutes.PROFILE}/edit/${_id}` }}>
              <Button type="primary">
                {`${t('buttons.edit')} `}
                {width < 576 ? '' : <EditOutlined />}
              </Button>
            </Link>
            <Link to={`${outOfNavRoutes.PROFILE}/change-pwd`}>
              <Button type="primary">
                {width < 576
                  ? t('profile.settings.changePwd_short')
                  : t('profile.settings.changePwd')}
              </Button>
            </Link>
          </>
        }
      />
      <ContentCustom>
        <Skeleton loading={isLoading} active>
          <DescriptionList
            data={listContent(user, t, token)}
            translate
            layout="vertical"
          />
        </Skeleton>
        <NotificationsManagement
          user={user}
          refresh={refresh}
          setRefresh={setRefresh}
        />
      </ContentCustom>
    </>
  );
};

export default Profile;
