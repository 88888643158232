import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  DeleteOutlined,
  EyeOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import format from 'date-fns/format';
import { Popconfirm, Divider } from 'antd';
import numeral from 'numeral';

const iconSize = 18;

const useColumns = (deleteRejection, setForceRefresh) => {
  const { t } = useTranslation();

  const calculateTotalWithDebt = (total, paymentsInfo) => {
    let newTotal = total;
    if (paymentsInfo) {
      Object.entries(paymentsInfo).map((item) => {
        newTotal -= item[1].debt;
        return true;
      });
    }
    return newTotal;
  };

  return [
    {
      title: t('rejections.columns.month'),
      dataIndex: 'month',
      key: 'month',
      sorter: true
    },
    {
      title: t('rejections.columns.created_at'),
      dataIndex: 'created_at',
      key: 'created_at',
      sorter: true,
      render: (date) => format(new Date(date), 'dd/MM/yyyy')
    },
    {
      title: t('rejections.columns.levy_count'),
      dataIndex: 'levy_count',
      key: 'levy_count',
      sorter: true
    },
    {
      title: t('rejections.columns.total'),
      dataIndex: 'total',
      key: 'total',
      sorter: true,
      render: (total, { payments_info }) =>
        `${numeral(calculateTotalWithDebt(total, payments_info)).format(
          '0,0.00$'
        )}`
    },
    {
      key: 'action',
      align: 'right',
      render: (record) => (
        <>
          <Link
            to={{
              pathname: `/invoicing/rejections/show/${record?._id}`
            }}
          >
            <EyeOutlined style={{ fontSize: iconSize }} />
          </Link>
          <Divider type="vertical" />
          <Popconfirm
            title={t('datatable.column.action.delete.title')}
            okText={t('datatable.column.action.delete.ok')}
            okButtonProps={{ type: 'danger' }}
            cancelText={t('datatable.column.action.delete.cancel')}
            onConfirm={async () => {
              setForceRefresh(false);
              await deleteRejection(record?._id, () => setForceRefresh(true));
            }}
            icon={<WarningOutlined />}
          >
            {!record?.status !== 'DELETED' ? (
              <DeleteOutlined style={{ fontSize: iconSize }} type="delete" />
            ) : (
              []
            )}
          </Popconfirm>
        </>
      )
    }
  ];
};

export default useColumns;
