import React, { useState, createContext } from 'react';

export const ReferenceWeekContext = createContext();

export const ReferenceWeekProvider = ({ children }) => {
  const [referenceWeeks, setReferenceWeeks] = useState([
    [
      { day: 'monday', time_slots: [] },
      { day: 'tuesday', time_slots: [] },
      { day: 'wednesday', time_slots: [] },
      { day: 'thursday', time_slots: [] },
      { day: 'friday', time_slots: [] }
    ]
  ]);

  return (
    <ReferenceWeekContext.Provider
      value={{ referenceWeeks, setReferenceWeeks }}
    >
      {children}
    </ReferenceWeekContext.Provider>
  );
};
