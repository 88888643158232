import { Select, TimePicker } from 'antd';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

/**
 * A custom hook for generating form fields for activity creation or editing.
 *
 * This hook provides an array of form field configurations based on the given parameters.
 * It adapts the fields depending on whether the activity's origin is a group or a child.
 * The fields include selections for children, groups, dates, activity types, and sub-activities.
 * It uses the `useFollowUpContext` for sub-activities state management and `useTranslation` for field labels.
 *
 * @function
 * @param {Object[]} groups - Array of group objects.
 * @param {Object} activityConfiguration - Configuration object for activities.
 * @param {'child' | 'group'} origin - Origin type of the activity ('child' or 'group').
 * @param {Object[]} children - Array of child objects.
 * @param {string} purpose - The purpose of the form ('create' or 'edit').
 * @param {array} subActivities - Activities relative to their activity group.
 * @param {function} setSubActivities - Function to set subActivities state.
 * @returns {Object} An object containing an array of field configurations.
 *
 * @example
 * const { fields } = useFields(groups, activityConfiguration, 'child', children);
 */
export const useFields = (
  groups,
  activityConfiguration,
  origin,
  children,
  purpose,
  subActivities,
  setSubActivities
) => {
  const { t } = useTranslation();

  const activities = activityConfiguration
    ? Object.keys(activityConfiguration).filter(
        (activity) =>
          !['_id', '__v', 'created_at', 'updated_at'].includes(activity)
      )
    : [];

  const fields = [
    ...(origin === 'child'
      ? [
          ...(purpose === 'create'
            ? [
                {
                  name: ['children'],
                  input: (
                    <Select allowClear showSearch optionFilterProp="children">
                      {(children || []).map((child) => (
                        <Option key={child._id} value={child._id}>
                          {`${child?.first_name} ${child?.last_name}`}
                        </Option>
                      ))}
                    </Select>
                  )
                }
              ]
            : []),
          {
            name: ['groups'],
            hidden: true,
            input: (
              <Select mode="multiple" allowClear showSearch>
                {(groups || []).map((group) => (
                  <Option key={group._id} value={group._id}>
                    {group.label}
                  </Option>
                ))}
              </Select>
            )
          }
        ]
      : [
          {
            name: ['groups'],
            rules: [{ required: false }],
            input: (
              <Select mode="multiple">
                {(groups || []).map((group) => (
                  <Option key={group._id} value={group._id}>
                    {group.label}
                  </Option>
                ))}
              </Select>
            )
          }
        ]),
    {
      name: ['date'],
      rules: [{ required: true }],
      input: <TimePicker format="HH:mm" minuteStep={15} />
    },
    {
      name: ['activity', 'type'],
      rules: [{ required: true }],
      input: (
        <Select
          onChange={(value) => {
            const filteredActivities = activityConfiguration[value];
            setSubActivities(filteredActivities);
          }}
        >
          {(activities || []).map((type) => (
            <Option key={type} value={type}>
              {t(`activities.form.${type}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['activity', 'label'],
      rules: [{ required: true }],
      input: (
        <Select disabled={!subActivities?.length}>
          {(subActivities || []).map((group) => (
            <Option key={group?.label} value={group?.label}>
              {group?.label}
            </Option>
          ))}
        </Select>
      )
    }
  ];

  return {
    fields
  };
};
