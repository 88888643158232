import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Button, Popconfirm } from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { ContentCustom, PageHeaderCustom } from '../../../components';
import { CreateEditCommunicationModal } from './CreateEditCommunicationModal';
import { useShowRender } from '../useShowRender';

/**
 * React component to display detailed information about a specific resource.
 * Dynamically handles different types of resources based on URL parameters.
 * Allows for editing and deletion of the resource.
 *
 * @component
 * @example
 * return (
 *   <ShowResource />
 * )
 *
 * @returns {React.Component} A component that renders a filtered view of news items with a sidebar for topic selection and a section for most viewed news.
 */
export const ShowResource = () => {
  const { resourceName, id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { dispatchAPI, user } = useAuthContext();
  const { message } = useErrorMessage();
  const [resource, setResource] = useState();
  const [modal, setModal] = useState();
  const [refresh, setRefresh] = useState(false);

  const handleModal = () => {
    const modalKey = Date.now();
    setModal(
      <CreateEditCommunicationModal
        key={modalKey}
        refresh={refresh}
        purpose="edit"
        setRefresh={setRefresh}
        record={resource}
        resourceName={resourceName}
      />
    );
  };

  const getResource = async () => {
    try {
      const populate = () => {
        const string = '?populate=';
        if (resourceName === 'news') {
          return `${string}thumbnail,images,videos`;
        }
        if (resourceName === 'events') {
          return `${string}groups,thumbnail,attachment`;
        }
        return true;
      };
      const encodedURL = encodeURI(`${resourceName}/${id}${populate()}`);
      const { data } = await dispatchAPI('GET', {
        url: encodedURL
      });
      setResource(data);
    } catch (e) {
      message(e);
    }
  };

  const deleteResource = async () => {
    try {
      await dispatchAPI('DELETE', {
        url: `${resourceName}/${id}`
      });
      navigate(-1);
    } catch (e) {
      message(e);
    }
  };

  const deleteFileById = async (fileID) => {
    try {
      await dispatchAPI('DELETE', {
        url: `files/${fileID}`
      });
      setRefresh(!refresh);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      await getResource();
    })();
  }, [id, refresh]);

  useEffect(() => {
    if (resourceName === 'news') {
      (async () => {
        await dispatchAPI('PATCH', {
          url: `news/increment-views/${id}`
        });
      })();
    }
  }, [id]);

  const showRender = useShowRender(resource, deleteFileById);

  return (
    <>
      <PageHeaderCustom
        title={resource?.title}
        extra={
          !['guests:PARENT', 'users:TABLET'].includes(user?.role) && (
            <>
              <Button
                icon={<EditOutlined />}
                type="primary"
                onClick={handleModal}
              >
                {t('buttons.edit')}
              </Button>
              <Popconfirm
                title={t('datatable.column.action.delete.title')}
                okText={t('datatable.column.action.delete.ok')}
                okButtonProps={{ type: 'danger' }}
                cancelText={t('datatable.column.action.delete.cancel')}
                onConfirm={deleteResource}
                icon={<WarningOutlined />}
              >
                <Button danger>
                  <DeleteOutlined />
                  {t('buttons.delete')}
                </Button>
              </Popconfirm>
            </>
          )
        }
      />
      <ContentCustom>{showRender[resourceName]}</ContentCustom>
      {modal}
    </>
  );
};
