import dayjs from 'dayjs';
import { useState, useCallback, useEffect } from 'react';
import { Select, DatePicker, Radio, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import {
  contractStatus,
  contractTypes
} from '../../../utils/constants/tagColors';

const { Option } = Select;

/**
 * Custom hook for managing term and conditions fields in contract forms.
 * This hook fetches and provides the necessary fields for term and conditions in contract forms.
 *
 * @param {function} setIsFlexible - Function to set isFlexible state.
 * @param {Array} contracts - The list of contracts.
 * @param {string} purpose - Purpose to know if its create or update.
 *
 * @hook
 * @returns {Object} An object containing term and conditions fields and loading state.
 */
export const useTermAndConditionsFields = (
  setIsFlexible,
  contracts,
  purpose
) => {
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [statuses, setStatuses] = useState([]);
  const [planningTypes, setPlanningTypes] = useState([]);
  const [billingTypes, setBillingTypes] = useState([]);

  const disabledDate = (current) => {
    const activecontractEndDate = (contracts || []).find(
      (contract) => contract.status === 'ACTIVE'
    )?.end_date;
    return current <= dayjs(activecontractEndDate);
  };

  const getContractEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/contracts/enums'
      });
      setStatuses(data.status);
      setBillingTypes(data.billingTypes);
      setPlanningTypes(data.planningTypes);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getContractEnums();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  const fields = [
    ...(purpose === 'create'
      ? [
          {
            name: ['date'],
            rules: [{ required: true }],
            input: (
              <DatePicker.RangePicker
                format="DD/MM/YYYY"
                disabledDate={disabledDate}
              />
            )
          }
        ]
      : []),
    ...(purpose === 'edit'
      ? [
          {
            name: ['start_date'],
            rules: [{ required: true }],
            input: <DatePicker />
          },
          {
            name: ['end_date'],
            rules: [{ required: true }],
            input: <DatePicker />
          }
        ]
      : []),
    ...(purpose === 'edit'
      ? [
          {
            name: ['status'],
            rules: [{ required: true }],
            input: (
              <Select>
                {(statuses || [])
                  .filter((f) => f !== 'TERMINATED')
                  .map((status) => (
                    <Option key={status} value={status}>
                      <Tag color={contractStatus[status]}>
                        {t(`contracts.list.status.${status}`)}
                      </Tag>
                    </Option>
                  ))}
              </Select>
            )
          }
        ]
      : []),

    {
      name: ['billing_type'],
      rules: [{ required: true }],
      input: (
        <Select>
          {(billingTypes || []).map((type) => (
            <Option key={type} value={type}>
              <Tag color={contractTypes[type]}>
                {t(`contracts.list.types.${type}`)}
              </Tag>
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['planning_type'],
      rules: [{ required: true }],
      input: (
        <Select>
          {(planningTypes || []).map((type) => (
            <Option key={type} value={type}>
              {t(`contracts.list.types.${type}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['is_flexible_planning'],
      rules: [{ required: true }],
      input: (
        <Radio.Group onChange={(e) => setIsFlexible(e.target.value)}>
          <Radio value>{t('pre-registrations.form.contract.yes')}</Radio>
          <Radio value={false}>{t('pre-registrations.form.contract.no')}</Radio>
        </Radio.Group>
      )
    }
  ];

  return {
    fields,
    isFieldsLoading
  };
};
