import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Button, Card, Col, Row, Spin, Table } from 'antd';
import { useEffect, useState } from 'react';
import { EditOutlined } from '@ant-design/icons';
import { useErrorMessage } from '../../utils/errorMessage';
import { useAuthContext } from '../../contexts/AuthContext';
import { ContentCustom, PageHeaderCustom } from '../../components';
import { useColumns } from '../children/columns';
import { useListContent } from './ListContent';
import { EditChildModal } from '../children/EditChildModal';
import { EditParentModal } from './EditParentModal';

/**
 * ShowParent component displays the details of a parent's profile.
 * It fetches and renders information about the parent, including principal information,
 * contact details, professional and familial situation, and children.
 *
 * @component
 * @returns {JSX.Element} The rendered component.
 * @example
 * <ShowParent />
 */
export const ShowParent = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { dispatchAPI, daycare } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [parent, setParent] = useState({});
  const { principal_infos, coordonees, professional_and_familial_situation } =
    useListContent({ ...parent });
  const [childModal, setChildModal] = useState();
  const [parentModal, setParentModal] = useState();

  const handleParentModalBehaviour = () => {
    const modalKey = Date.now();
    setParentModal(
      <EditParentModal
        key={modalKey}
        refresh={refresh}
        setRefresh={setRefresh}
        record={parent}
      />
    );
  };

  const handleModalChildBehaviour = (record) => {
    const modalKey = Date.now();
    setChildModal(
      <EditChildModal
        key={modalKey}
        forceRefresh={refresh}
        setForceRefresh={setRefresh}
        record={record}
      />
    );
  };

  const columns = useColumns(refresh, setRefresh, handleModalChildBehaviour);
  const { message } = useErrorMessage();

  const getParent = async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/parents/show/${id}/${daycare}?populate=user`
      });
      setParent(data);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getParent();
  }, [refresh]);

  return (
    <>
      <PageHeaderCustom
        title={`${parent?.user?.first_name} ${parent?.user?.last_name}`}
        extra={
          <Button type="primary" onClick={handleParentModalBehaviour}>
            {`${t('buttons.edit')} `}
            <EditOutlined />
          </Button>
        }
      />
      <ContentCustom>
        <Row gutter={[16, 16]}>
          <Col
            xs={24}
            xl={12}
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '16px'
            }}
          >
            <Card title={t('parents.show.principal_infos')}>
              {isLoading ? (
                <Spin />
              ) : (
                <Row gutter={[16, 16]} className="ant-descriptions-row">
                  {principal_infos.map((item) => (
                    <Col
                      span={item.span}
                      key={item.content}
                      className="ant-descriptions-item"
                    >
                      <div className="ant-descriptions-item-label">
                        {t(item.label)}
                      </div>
                      <div className="ant-descriptions-item-content">
                        {item.content}
                      </div>
                    </Col>
                  ))}
                </Row>
              )}
            </Card>

            <Card title={t('parents.show.contact_details')}>
              {isLoading ? (
                <Spin />
              ) : (
                <Row gutter={[16, 16]} className="ant-descriptions-row">
                  {coordonees.map((item) => (
                    <Col
                      span={item.span}
                      key={item.content}
                      className="ant-descriptions-item"
                    >
                      <div className="ant-descriptions-item-label">
                        {t(item.label)}
                      </div>
                      <div className="ant-descriptions-item-content">
                        {item.content}
                      </div>
                    </Col>
                  ))}
                </Row>
              )}
            </Card>

            <Card title={t('parents.show.professional_and_familial_situation')}>
              {isLoading ? (
                <Spin />
              ) : (
                <Row gutter={[16, 16]} className="ant-descriptions-row">
                  {professional_and_familial_situation.map((item) => (
                    <Col
                      span={item.span}
                      key={item.content}
                      className="ant-descriptions-item"
                    >
                      <div className="ant-descriptions-item-label">
                        {t(item.label)}
                      </div>
                      <div className="ant-descriptions-item-content">
                        {item.content}
                      </div>
                    </Col>
                  ))}
                </Row>
              )}
            </Card>

            <Card title={t('parents.show.children')}>
              <Table
                columns={columns}
                dataSource={parent?.children}
                resourceModelName="Child"
                withCreateButton={false}
                scroll={{ x: 'max-content' }}
              />
            </Card>
          </Col>
          <Col xs={24} xl={12}>
            <Card style={{ height: '100%' }} />
          </Col>
        </Row>
      </ContentCustom>
      {childModal}
      {parentModal}
    </>
  );
};
