import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Divider } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';

const iconSize = 18;

/**
 * Columns for the datatable
 * @hook
 * @param {function} handleModalBehaviour - A function to handle modals.
 * @param {boolean} refresh - A boolean value indicating whether to force refresh the table.
 * @param {function} setRefresh - A function to set the forceRefresh value.
 *
 * @returns {Array} columns for the datatable
 */
export const useColumns = (handleModalBehaviour, refresh, setRefresh) => {
  const { t } = useTranslation();
  const { dispatchAPI, daycare, user } = useAuthContext();
  const { message } = useErrorMessage();

  const deleteResource = async (id) => {
    try {
      await dispatchAPI('DELETE', {
        url: `/daycares/holidays/${daycare}/${id}`
      });
      setRefresh(!refresh);
    } catch (e) {
      message(e);
    }
  };

  return [
    {
      title: t('daycares.form.bank_holyday_name'),
      key: 'bank_holyday_name',
      dataIndex: 'bank_holyday_name',
      width: 50
    },
    {
      title: t('daycares.form.date'),
      key: 'date',
      dataIndex: 'date',
      width: 50,
      sorter: (a, b) => dayjs(a.date).unix() - dayjs(b.date).unix(),
      render: (date) => (date ? dayjs(date).format('DD/MM/YYYY') : '-')
    },
    ...(user.role !== 'users:MANAGER'
      ? [
          {
            width: 50,
            align: 'right',
            render: (record) => (
              <>
                <Button
                  type="link"
                  className="no-padding"
                  onClick={() => handleModalBehaviour(record, 'edit')}
                >
                  <EditOutlined style={{ fontSize: iconSize }} />
                </Button>
                <Divider type="vertical" />
                <Button
                  type="link"
                  className="no-padding"
                  onClick={() => deleteResource(record._id, 'edit')}
                >
                  <DeleteOutlined style={{ fontSize: iconSize }} />
                </Button>
              </>
            )
          }
        ]
      : [])
  ];
};
