import { useTranslation } from 'react-i18next';
import { ConversationDisplay } from './ConversationDisplay';

/**
 * @hook
 * @name useTabContent
 * @description A custom hook for generating the tab content for the conversations page
 * @returns {Object} The tab content
 */
export const useTabContent = () => {
  const { t } = useTranslation();

  const generateTab = (filterKey) => ({
    label: t(`messages_and_notes.titles.${filterKey}`),
    key: filterKey,
    children: <ConversationDisplay filterKey={filterKey} />
  });

  const items = [
    generateTab('all'),
    generateTab('parents'),
    generateTab('employees')
  ];

  return { items };
};
