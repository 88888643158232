import { PlusCircleFilled } from '@ant-design/icons';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

/**
 * Generates menu items based on the provided event, openModal function, and user role.
 *
 * @hook
 *
 * @param {object} event - The event object containing event details.
 * @param {Function} openModal - The function to open a modal for creating reservations, absences, adaptations, or pickup times.
 * @param {object} user - The user object containing user details including role.
 * @returns {object[]} An array of menu items to be displayed based on the event and user role.
 */
export const useMenuItems = (event, openModal, user) => {
  const { t } = useTranslation();

  const reservations = event?.reservations || [];
  const refDay = (event?.reference_day || [])
    .filter((day) => Object.keys(day).some((key) => key === 'start_time'))
    .filter(Boolean);

  const projectionPlanningSize =
    event?.childSchedule?.planningProjections?.length || 0;
  const previsionalPresence =
    refDay?.length > 0 || event?.reservations?.length > 0;

  const previsionalUndeclaredPresence =
    (event?.absences?.length || 0) + (event?.pickup_times?.length || 0) <
    projectionPlanningSize;

  const eventAbsenceDeclaration =
    (event?.absences?.length || 0) === 0 &&
    (event?.pickup_times?.length || 0) === 0;

  const displayReservationAdaptationMenuItems = eventAbsenceDeclaration;

  const displayAbsenceTimeRecorderButtonsMenuItem =
    projectionPlanningSize > 0 &&
    previsionalUndeclaredPresence &&
    previsionalPresence;

  const reservationButton = displayReservationAdaptationMenuItems &&
    user?.role !== 'users:TABLET' && {
      key: 'reservations',
      label: (
        <Button
          type="link"
          onClick={() =>
            openModal(
              'reservations',
              event?.child,
              'create',
              undefined,
              undefined,
              refDay
            )
          }
        >
          <PlusCircleFilled />
          {t('plannings.children.dropdown.reservations')}
        </Button>
      )
    };
  const absenceButton = displayAbsenceTimeRecorderButtonsMenuItem &&
    user?.role !== 'users:TABLET' && {
      key: 'absences',
      label: (
        <Button
          type="link"
          onClick={() =>
            openModal(
              'absences',
              event?.child,
              'create',
              undefined,
              undefined,
              [...refDay, ...reservations]
            )
          }
        >
          <PlusCircleFilled />
          {t('plannings.children.dropdown.absences')}
        </Button>
      )
    };
  const adaptationButton = displayReservationAdaptationMenuItems &&
    user?.role !== 'users:TABLET' && {
      key: 'adaptations',
      label: (
        <Button
          type="link"
          onClick={() =>
            openModal(
              'adaptations',
              event?.child,
              'create',
              undefined,
              undefined,
              event?.reference_day
            )
          }
        >
          <PlusCircleFilled />
          {t('plannings.children.dropdown.adaptations')}
        </Button>
      )
    };
  const timeRecorderButton = displayAbsenceTimeRecorderButtonsMenuItem && {
    key: 'pickup_times',
    label: (
      <Button
        type="link"
        onClick={() =>
          openModal(
            'pickup_times',
            event?.child,
            'create',
            undefined,
            undefined,
            event?.reference_day
          )
        }
      >
        <PlusCircleFilled />
        {t('plannings.children.dropdown.pickup_times')}
      </Button>
    )
  };

  const items = [
    reservationButton,
    absenceButton,
    adaptationButton,
    timeRecorderButton
  ].filter(Boolean);

  return items;
};
