import PropTypes from 'prop-types';
import { Layout } from 'antd';
import { useHandleResize } from '../../utils/handleResize';

const { Content } = Layout;

export const ContentCustom = ({ children, style }) => {
  const { width } = useHandleResize();
  return (
    <Content
      style={{
        padding: width > 767 ? 20 : 10,
        background: 'var(--componentBackground)',
        minHeight: 'fit-content',
        margin: width > 767 ? '8px 24px 16px' : '8px 10px',
        borderRadius: 12,
        position: 'relative',
        ...style
      }}
    >
      {children}
    </Content>
  );
};

ContentCustom.propTypes = {
  style: PropTypes.shape({})
};

ContentCustom.defaultProps = {
  style: undefined
};
