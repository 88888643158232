/**
 * Handles filtering an array based on a translated value, updates the form, and sets the filtered data.
 *
 * @function
 *
 * @param {Array} dataSource - The data source array to filter.
 * @param {number} translatedValue - The value used for filtering the data.
 * @param {function} setFilteredData - The function to set the filtered data.
 *
 * @returns {void}
 */
export const handleArrayFilter = (
  dataSource,
  translatedValue,
  setFilteredData
) => {
  const filteredArray = dataSource
    .map((data) => {
      if (data.duration % translatedValue === 0) {
        return data;
      }
      return null;
    })
    .filter(Boolean)
    .sort((a, b) => a.duration - b.duration);

  return setFilteredData(filteredArray);
};

/**
 * Handles adding a new element to the filtered data and updating the data source and form.
 *
 * @function
 *
 * @param {Array} filteredData - The current filtered data array.
 * @param {number} translatedValue - The value used to determine the duration increment.
 * @param {function} setDataSource - The function to set the updated data source.
 * @param {string} target - The target key in the data source to update.
 * @param {object} form - The form object to set the field value.
 * @param {Array} dataSource - The original data source array.
 * @param {function} setRefreshFilter - The function to refresh the filter state.
 * @param {Number} extraFeeMultiplier - Extra fee multiplier
 *
 * @returns {void}
 */
export const handleAdd = (
  filteredData,
  translatedValue,
  setDataSource,
  target,
  form,
  dataSource,
  setRefreshFilter,
  extraFeeMultiplier
) => {
  const lastItem =
    filteredData.length > 0 ? filteredData[filteredData.length - 1] : null;
  const extraFee = 0.25 * extraFeeMultiplier;

  const newElement = {
    duration: lastItem ? lastItem.duration + translatedValue : translatedValue,
    extra_fee: lastItem ? lastItem.extra_fee + extraFee : extraFee
  };

  setDataSource((prev) => {
    const updatedState = { ...prev };
    updatedState[target].push(newElement);

    return updatedState;
  });

  form.setFieldValue({
    [target]: [...dataSource, newElement]
  });
  setRefreshFilter((prev) => !prev);
};
