import { Col, Row } from 'antd';
import { ResultsTable } from './ResultsTable';

/**
 * Determines if a graph should use a boolean switch based on the title of the graph card. This function is used to enable or disable
 * certain features or displays in the graph component, depending on the type of data being visualized.
 *
 * @function
 *
 * @param {Function} t - Translation function from 'i18next' for localization.
 * @param {string} title - Title of the graph card, used to identify the type of graph.
 * @returns {boolean} Returns true if the graph card title matches specific cases where a boolean switch is needed, otherwise false.
 */
const switchBool = (t, title) =>
  ![t('analysis.cards.occupation_rate_follow_up')].includes(title);

/**
 * Determines the purpose mode based on the provided title and translation function.
 *
 * @function
 *
 * @param {Function} t - The translation function.
 * @param {string} title - The title of the analysis card.
 * @returns {Object} The purpose mode object containing mode and subMode.
 */
const purpose = (t, title) => {
  if (
    [
      t('analysis.cards.carried_out_hours_follow_up'),
      t('analysis.cards.billed_hours_follow_up')
    ].includes(title)
  ) {
    return {
      mode: 'follow_up',
      subMode: 'hours_follow_up'
    };
  }
  if (title === t('analysis.cards.occupation_rate_follow_up')) {
    return {
      mode: 'follow_up',
      subMode: 'rate_follow_up'
    };
  }
  return { mode: 'evolution' };
};

/**
 * A mapping of keys to corresponding colors.
 * @type {Object.<string, string>}
 */
const colorMap = {
  'Objectif à atteindre': '#6CCCF4',
  'Constaté + prévision': '#EE3F68',
  Objectif: '#FCE200',
  Réél: '#CECECE',
  'CA Cumulé': '#EE3F68',
  Prévision: '#FCE102',
  Cumulé: '#6CCCF4'
};

/**
 * Prepares data for visualization based on the provided parameters.
 *
 * @function
 *
 * @param {Object} datas - The data object.
 * @param {Function} t - The translation function.
 * @param {Object} graphCardPurpose - The mode and submode for data preparation.
 * @param {boolean} isMonthlyMode - Flag indicating if the mode is monthly.
 * @returns {Object} An object containing prepared line and column data.
 */
export const prepareData = (datas, t, graphCardPurpose, isMonthlyMode) => {
  const lineData = [];
  const columnData = [];

  if (graphCardPurpose.mode === 'follow_up') {
    if (
      datas &&
      isMonthlyMode &&
      graphCardPurpose.subMode === 'hours_follow_up'
    ) {
      (datas?.month?.observed || []).forEach((data) =>
        lineData.push({
          ...data,
          type: t(`analysis.graph.month_${data.type}`)
        })
      );
      (datas?.month?.objective || []).forEach((data) =>
        lineData.push({
          ...data,
          type: t(`analysis.graph.month_${data.type}`)
        })
      );
    } else if (datas && graphCardPurpose.subMode === 'rate_follow_up') {
      (datas?.observed || []).forEach((data) =>
        lineData.push({
          ...data,
          type: t(`analysis.graph.month_${data.type}`)
        })
      );
      (datas?.objective || []).forEach((data) =>
        lineData.push({
          ...data,
          type: t(`analysis.graph.month_${data.type}`)
        })
      );
    } else {
      (datas?.month?.objective || []).forEach((data) =>
        columnData.push({
          ...data,
          type: t(`analysis.graph.cumulate_${data.type}`)
        })
      );
      (datas?.month?.observed || []).forEach((data) =>
        columnData.push({
          ...data,
          type: t(`analysis.graph.cumulate_${data.type}`)
        })
      );

      (datas?.cumulate || []).forEach((data) =>
        lineData.push({
          ...data,
          type: t(`analysis.graph.${data.type}`)
        })
      );
    }

    return {
      lineData,
      columnData
    };
  }
  if (datas && isMonthlyMode) {
    if (datas?.observed && Object.keys(datas?.observed)?.length > 0) {
      Object.entries(datas?.observed).forEach(([month, value]) => {
        if (typeof value === 'number') {
          lineData.push({
            month,
            value,
            type: t('analysis.graph.month_real')
          });
        }
      });
    }
    if (datas?.prevision && Object.keys(datas?.prevision)?.length > 0) {
      Object.entries(datas?.prevision).forEach(([month, value]) => {
        if (typeof value === 'number') {
          lineData.push({
            month,
            value,
            type: t('analysis.graph.prevision')
          });
        }
      });
    }
    return {
      lineData
    };
  }
  if (datas?.observed && Object.keys(datas?.observed)?.length > 0) {
    Object.entries(datas?.observed).forEach(([month, value]) => {
      if (typeof value === 'number') {
        columnData.push({
          month,
          value,
          type: t('analysis.graph.month_real')
        });
      }
    });
  }

  if (datas?.prevision && Object.keys(datas?.prevision)?.length > 0) {
    Object.entries(datas?.prevision).forEach(([month, value]) => {
      if (typeof value === 'number') {
        columnData.push({
          month,
          value,
          type: t('analysis.graph.prevision')
        });
      }
    });
  }

  if (datas?.cumulate && Object.keys(datas?.cumulate)?.length > 0) {
    Object.entries(datas?.cumulate).forEach(([month, value]) => {
      if (typeof value === 'number') {
        lineData.push({
          month,
          count: value,
          type: t('analysis.graph.cumulate_months')
        });
      }
    });
  }

  return {
    lineData,
    columnData
  };
};

/**
 * Generates labels for the Y-axis of a follow-up chart based on the provided parameters.
 *
 * @function
 *
 * @param {Function} t - The translation function.
 * @param {string} text - The text to be formatted for the Y-axis label.
 * @param {string} title - The title of the chart.
 * @returns {string|number} The formatted label for the Y-axis.
 */
const followUpLabelYAxis = (t, text, title) => {
  if (!text) {
    return 0;
  }
  if (
    [
      t('analysis.cards.carried_out_hours_follow_up'),
      t('analysis.cards.billed_hours_follow_up')
    ].includes(title)
  ) {
    return `${text}h`;
  }
  if (
    [
      t('analysis.cards.operating_incomes_evolution'),
      t('analysis.cards.operating_expenses_evolution'),
      t('analysis.cards.operating_results_evolution')
    ].includes(title)
  ) {
    return `${text}€`;
  }
  if (title === t('analysis.cards.occupation_rate_follow_up')) {
    return `${text}%`;
  }
  return text;
};

/**
 * Generates configuration object for line chart using provided data and parameters.
 *
 * @hook
 *
 * @param {Object} data - The data object containing lineData.
 * @param {Function} t - The translation function.
 * @param {string} title - The title of the chart.
 * @param {number} minYValue - The minimum value for the Y-axis.
 * @param {number} maxYValue - The maximum value for the Y-axis.
 * @returns {Object} The configuration object for line chart.
 */
const useLineDataConfig = (data, t, title) => ({
  data: data.lineData,
  xField: 'month',
  yField: 'value',
  yAxis: {
    label: {
      formatter: (text) => followUpLabelYAxis(t, text, title)
    }
  },
  legend: {
    position: 'bottom'
  },
  seriesField: 'type',
  color: ({ type }) => colorMap[type]
});

/**
 * Generates configuration object for dual axes chart using provided data.
 *
 * @hook
 *
 * @param {Object} data - The data object containing columnData and lineData.
 * @returns {Object} The configuration object for dual axes chart.
 */
const useDualAxesConfig = (data) => ({
  data: [data.columnData, data.lineData],
  xField: 'month',
  yField: ['value', 'count'],
  legend: {
    position: 'bottom'
  },
  geometryOptions: [
    {
      geometry: 'line',
      isGroup: true,
      seriesField: 'type',
      color: ({ type }) => colorMap[type]
    },
    {
      geometry: 'line',
      lineStyle: {
        lineWidth: 2
      },
      seriesField: 'type',
      color: '#6CCCF4'
    }
  ]
});

/**
 * Generates configuration object for a graph based on provided parameters and data.
 *
 * @function
 *
 * @param {Function} t - The translation function.
 * @param {string} title - The title of the graph.
 * @param {Object} datas - The data object.
 * @param {boolean} isMonthlyMode - Flag indicating if the mode is monthly.
 * @returns {Object} The configuration object for the graph.
 */
export const useGraphConfig = (t, title, datas, isMonthlyMode) => {
  const boolean = switchBool(t, title);
  const graphCardPurpose = purpose(t, title);
  const data = prepareData(datas, t, graphCardPurpose, isMonthlyMode);

  const lineDataConfig = useLineDataConfig(data, t, title);

  const dualAxesConfig = useDualAxesConfig(data);

  const graphConfig = isMonthlyMode ? lineDataConfig : dualAxesConfig;

  return {
    boolean,
    graphCardPurpose,
    graphConfig
  };
};

/**
 * Renders the specified component along with a results table if the conditions for the title and purpose match.
 * This function is designed to conditionally layout components based on the type of analysis being performed.
 *
 * @function
 *
 * @param {JSX.Element} component - The main component to be rendered.
 * @param {string} title - The title of the analysis card, which determines the rendering behavior.
 * @param {Array} datas - The data to be passed to the `ResultsTable` if rendered.
 * @param {Function} t - Translation function from 'react-i18next' used for internationalization.
 * @returns {JSX.Element} A row layout with the main component and, conditionally, a results table.
 */
export const render = (component, title, datas, t) => {
  const { mode } = purpose(t, title);
  const tableData =
    title === t('analysis.cards.operating_expenses_evolution')
      ? datas.expenses
      : datas.incomes;

  if (
    mode === 'evolution' &&
    [
      t('analysis.cards.operating_expenses_evolution'),
      t('analysis.cards.operating_incomes_evolution')
    ].includes(title)
  ) {
    const flexDirection =
      title === t('analysis.cards.operating_income_expenses')
        ? 'column'
        : 'row';
    const spanSize =
      title === t('analysis.cards.operating_expenses_evolution') ? 24 : 12;

    return (
      <Row style={{ flexDirection }}>
        <Col span={spanSize}>{component}</Col>
        <Col span={spanSize}>
          <ResultsTable datas={tableData} />
        </Col>
      </Row>
    );
  }
  return component;
};

export const generateTableData = (t, source, data = {}) => {
  const {
    facturation_rate,
    occupation_rate,
    PSU_hour_amount,
    CAF_participation_rate,
    PSU_amount,
    cost_price
  } = data;
  if (source === 'expenses') {
    return [
      {
        label: t('analysis.table.cost_price'),
        results_real: cost_price,
        results_prevision: 0
      }
    ];
  }
  if (source === 'incomes') {
    return [
      {
        label: t('analysis.table.occupation_rate'),
        results_real: occupation_rate,
        results_prevision: 0
      },
      {
        label: t('analysis.table.facturation_rate'),
        results_real: facturation_rate,
        results_prevision: 0
      },
      {
        label: t('analysis.table.PSU_hour_amount'),
        results_real: PSU_hour_amount,
        results_prevision: 0
      },
      {
        label: t('analysis.table.CAF_participation_rate'),
        results_real: CAF_participation_rate,
        results_prevision: 0
      },
      {
        label: t('analysis.table.PSU_amount'),
        results_real: PSU_amount,
        results_prevision: 0
      }
    ];
  }

  return [];
};
