import PropTypes from 'prop-types';
import React, { useState, useEffect, useContext } from 'react';
import dayjs from 'dayjs';
import { Button, Flex, Modal, TimePicker } from 'antd';
import { useTranslation } from 'react-i18next';
import { ClockCircleOutlined } from '@ant-design/icons';
import { hours } from '../../utils/days';
import { ReferenceWeekContext } from '../../contexts/ReferenceWeekContext';
import { disabledTime } from './utils/disabledTime';
import { getSelectedStyle } from './utils/getSelectedStyle';
import { isTimeSlotUnselected } from './utils/isTimeSlotUnselected';
import { getUpdateWeeks } from './utils/updateWeeks';

/**
 * @description
 * TimeRangeBar is a component that displays on a lign per day the time slots selected by the user
 * for each day of the week.
 *
 * @component
 * @param {string} day - The day of the week
 * @param {number} weekIndex - The index of the week
 * @param {Object} initialValues - The initial values of the form (for edit purpose)
 * @param {string} purpose - The purpose of the form (create or edit)
 * @returns {React.Component} TimeRangeBar component
 */
export const TimeRangeBar = ({ day, weekIndex, purpose }) => {
  const { t } = useTranslation();
  const { referenceWeeks, setReferenceWeeks } =
    useContext(ReferenceWeekContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedStartTime, setSelectedStartTime] = useState(null);
  const [selectedEndTime, setSelectedEndTime] = useState(null);
  const [selectedStartTime2, setSelectedStartTime2] = useState(null);
  const [selectedEndTime2, setSelectedEndTime2] = useState(null);
  const [componentLoading, setComponentLoading] = useState(true);

  const minuteStep = 5;
  const timeToString = (time) => (time ? time.format('HH:mm') : null);

  /**
   * @description
   * Set the data of the form when the purpose is edit
   * @returns {void}
   */
  const setData = () => {
    const currentWeek = referenceWeeks[weekIndex];
    let currentDay;
    if (currentWeek?.length > 0) {
      currentWeek.forEach((dayOfWeek) => {
        if (dayOfWeek.day === day) {
          currentDay = dayOfWeek;
        }
      });
    }

    if (currentDay) {
      setSelectedStartTime(
        currentDay?.time_slots[0]?.start_time
          ? dayjs(currentDay?.time_slots[0]?.start_time, 'HH:mm')
          : null
      );
      setSelectedEndTime(
        currentDay?.time_slots[0]?.end_time
          ? dayjs(currentDay?.time_slots[0]?.end_time, 'HH:mm')
          : null
      );
      setSelectedStartTime2(
        currentDay?.time_slots[1]?.start_time
          ? dayjs(currentDay?.time_slots[1]?.start_time, 'HH:mm')
          : null
      );
      setSelectedEndTime2(
        currentDay?.time_slots[1]?.end_time
          ? dayjs(currentDay?.time_slots[1]?.end_time, 'HH:mm')
          : null
      );
    }
  };

  useEffect(() => {
    if (componentLoading) {
      setComponentLoading(false);
      if (
        (selectedStartTime == null || selectedEndTime == null) &&
        (selectedStartTime2 == null || selectedEndTime2 == null)
      ) {
        return;
      }

      setReferenceWeeks((prev) => {
        const prevReferenceWeeks = [...prev];
        const updateWeeks = getUpdateWeeks(
          prevReferenceWeeks,
          weekIndex,
          day,
          timeToString,
          selectedEndTime,
          selectedEndTime2,
          selectedStartTime,
          selectedStartTime2
        );

        return updateWeeks;
      });
    }
  }, [
    selectedStartTime,
    selectedEndTime,
    selectedStartTime2,
    selectedEndTime2,
    weekIndex,
    day,
    setReferenceWeeks,
    timeToString
  ]);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setReferenceWeeks((prev) => {
      const prevReferenceWeeks = [...prev];
      const updateWeeks = getUpdateWeeks(
        prevReferenceWeeks,
        weekIndex,
        day,
        timeToString,
        selectedEndTime,
        selectedEndTime2,
        selectedStartTime,
        selectedStartTime2
      );

      return updateWeeks;
    });
    return setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (referenceWeeks) {
      setData();
    }
  }, [referenceWeeks, purpose]);

  return (
    <tr className="timerange-bar">
      <td>
        <span>{t(`pre-registrations.form.days.${day}`)}</span>
      </td>
      <td colSpan={hours.length}>
        <div className="div-container">
          {hours.map((hour) => (
            <div key={`${day}-${hour}`} className="time-slot">
              <div
                className="time-slot-selected"
                style={getSelectedStyle(
                  hour,
                  0,
                  selectedStartTime,
                  selectedStartTime2,
                  selectedEndTime,
                  selectedEndTime2
                )}
              />
              <div
                className={`time-slot-unselected ${
                  isTimeSlotUnselected(
                    hour,
                    0,
                    selectedStartTime,
                    selectedStartTime2,
                    selectedEndTime,
                    selectedEndTime2
                  )
                    ? 'visible'
                    : 'hidden'
                }`}
              />
              <div
                className="time-slot-selected"
                style={getSelectedStyle(
                  hour,
                  1,
                  selectedStartTime,
                  selectedStartTime2,
                  selectedEndTime,
                  selectedEndTime2
                )}
              />
              <div
                className={`time-slot-unselected ${
                  isTimeSlotUnselected(
                    hour,
                    1,
                    selectedStartTime,
                    selectedStartTime2,
                    selectedEndTime,
                    selectedEndTime2
                  )
                    ? 'visible'
                    : 'hidden'
                }`}
              />
            </div>
          ))}
        </div>
      </td>
      <td>
        {purpose !== null && (
          <Button type="primary" shape="circle" onClick={showModal}>
            +
          </Button>
        )}
        <Modal
          title={t('pre-registrations.form.contract.select_time')}
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <Flex vertical gap="small">
            <TimePicker.RangePicker
              minuteStep={minuteStep}
              suffixIcon={<ClockCircleOutlined />}
              format="HH:mm"
              disabledTime={() => disabledTime(6, 21)}
              value={[selectedStartTime, selectedEndTime]}
              onChange={(timeRange) => {
                setSelectedStartTime(
                  timeRange && timeRange[0] ? dayjs(timeRange[0]) : null
                );
                setSelectedEndTime(
                  timeRange && timeRange[1] ? dayjs(timeRange[1]) : null
                );
              }}
            />
            <TimePicker.RangePicker
              minuteStep={minuteStep}
              suffixIcon={<ClockCircleOutlined />}
              format="HH:mm"
              value={[selectedStartTime2, selectedEndTime2]}
              disabledTime={() => disabledTime(6, 21, selectedEndTime)}
              onChange={(timeRange) => {
                setSelectedStartTime2(
                  timeRange && timeRange[0] ? dayjs(timeRange[0]) : null
                );
                setSelectedEndTime2(
                  timeRange && timeRange[1] ? dayjs(timeRange[1]) : null
                );
              }}
            />
          </Flex>
        </Modal>
      </td>
    </tr>
  );
};

TimeRangeBar.propTypes = {
  day: PropTypes.string.isRequired,
  weekIndex: PropTypes.number.isRequired,
  initialValues: PropTypes.shape({
    contract: PropTypes.shape({
      reference_weeks: PropTypes.arrayOf(PropTypes.shape({}))
    })
  }),
  purpose: PropTypes.string.isRequired
};

TimeRangeBar.defaultProps = {
  initialValues: {}
};
