import dashboardIcon from '../../assets/images/icons/noto_bar-chart.svg';
import userIcon from '../../assets/images/icons/noto_technologist-medium-skin-tone.svg';
import childrenIcon from '../../assets/images/icons/noto_baby-medium-skin-tone.svg';
import parentsIcon from '../../assets/images/icons/noto_person-feeding-baby-medium-skin-tone.svg';
import teamIcon from '../../assets/images/icons/noto_busts-in-silhouette.svg';
import preregistrationIcon from '../../assets/images/icons/noto_bookmark-tabs.svg';
import messageIcon from '../../assets/images/icons/noto_closed-mailbox-with-lowered-flag.svg';
import configurationIcon from '../../assets/images/icons/noto_gear.svg';
import parametersIcon from '../../assets/images/icons/noto_wrench.svg';
import invoicingIcon from '../../assets/images/icons/noto_credit-card.svg';
import planningIcon from '../../assets/images/icons/noto_calendar.svg';
import attendanceIcon from '../../assets/images/icons/noto_bellhop-bell.svg';
import followUpIcon from '../../assets/images/icons/timeLineIcon.svg';
import mealIcon from '../../assets/images/icons/noto_sandwich.svg';
import communicationIcon from '../../assets/images/icons/noto_mobile-phone.svg';
import analysisIcon from '../../assets/images/icons/analysis_icon.svg';
import documentsIcon from '../../assets/images/icons/documentsIcon.svg';
import clipboardIcon from '../../assets/images/icons/noto_clipboard.svg';

const navMenuLogos = {
  HOME: (
    <img src={dashboardIcon} alt="dashboard-icon" style={{ marginRight: 8 }} />
  ),
  ANALYSIS: (
    <img src={analysisIcon} alt="analysis-icon" style={{ marginRight: 8 }} />
  ),
  PLANNING: <img src={planningIcon} alt="planning-icon" />,
  USERS: <img src={userIcon} alt="user-icon" style={{ marginRight: 8 }} />,
  CHILDREN: (
    <img src={childrenIcon} alt="children-icon" style={{ marginRight: 8 }} />
  ),
  PARENTS: (
    <img src={parentsIcon} alt="parents-icon" style={{ marginRight: 8 }} />
  ),
  EMPLOYEES: <img src={teamIcon} alt="team-icon" style={{ marginRight: 8 }} />,
  INVOICING: <img src={invoicingIcon} alt="invoicing-icon" />,
  WORKFLOWS: (
    <img src={dashboardIcon} alt="dashboard-icon" style={{ marginRight: 8 }} />
  ),
  CONFIGURATIONS: (
    <img
      src={configurationIcon}
      alt="configuration-icon"
      style={{ marginRight: 8 }}
    />
  ),
  PRE_REGISTRATION: (
    <img
      src={preregistrationIcon}
      alt="preregistration-icon"
      style={{ marginRight: 8 }}
    />
  ),
  MESSAGES_AND_NOTES: (
    <img src={messageIcon} alt="message-icon" style={{ marginRight: 8 }} />
  ),
  MEALS: <img src={mealIcon} alt="meal-icon" style={{ marginRight: 8 }} />,
  COMMUNICATION: (
    <img
      src={communicationIcon}
      alt="communication-icon"
      style={{ marginRight: 8 }}
    />
  ),
  GLOBAL_SETTINGS: (
    <img
      src={parametersIcon}
      alt="parameters-icon"
      style={{ marginRight: 8 }}
    />
  ),
  ATTENDANCE_CHECK: (
    <img
      src={attendanceIcon}
      alt="attendance-icon"
      style={{ marginRight: 8 }}
    />
  ),
  CHILDREN_FOLLOW_UP: (
    <img src={followUpIcon} alt="attendance-icon" style={{ marginRight: 8 }} />
  ),
  INVOICES: (
    <img src={invoicingIcon} alt="contracts-icon" style={{ marginRight: 8 }} />
  ),
  DOCUMENTS: (
    <img src={documentsIcon} alt="documents-icon" style={{ marginRight: 8 }} />
  ),
  FOLLOW_UP: (
    <img src={clipboardIcon} alt="clipboard-icon" style={{ marginRight: 8 }} />
  )
};

export default navMenuLogos;
