import React from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Flex, Card, List, DatePicker, Tag } from 'antd';
import { useHomeContext } from '../../../contexts/HomeContext';

import IsWalking from '../../../assets/images/icons/mingcute_walk-fill.svg';
import DontWalk from '../../../assets/images/icons/healthicons_baby-0306m.svg';

/**
 * @component ChildrenCard
 * @description Component that renders the pickup times errors card. Used in the home page.
 * @returns {JSX} Component JSX
 */
export const ChildrenCard = () => {
  const { t } = useTranslation();
  const {
    childrenCardLoading,
    selectedDayChildrenCard,
    setSelectedDayChildrenCard,
    dateFormat,
    pickupTimesErrors
  } = useHomeContext();

  const lignContent = (child, error, referenceDay, color) => (
    <Flex justify="space-between">
      <span>
        {child.first_name} {child.last_name}{' '}
        <Tag color={child?.group?.tag_color}>{child?.group?.label}</Tag>
        {child?.is_walking ? (
          <span>
            <img src={IsWalking} alt="is walking" />{' '}
            {t(`home.children_card.is_walking.${child?.is_walking}`)}
          </span>
        ) : (
          <span>
            <img src={DontWalk} alt="is not walking" />{' '}
            {t(`home.children_card.is_walking.${child?.is_walking}`)}
          </span>
        )}
      </span>
      <span>
        {t(`home.children_card.errors.${error}`)}{' '}
        <Tag color={color}>{dayjs(referenceDay).format('HH:mm')}</Tag>
      </span>
    </Flex>
  );

  return (
    <Card
      title={
        <Flex justify="space-between" align="baseline">
          <span>{t('home.titles.children')}</span>
          <DatePicker
            allowClear={false}
            format={dateFormat}
            defaultValue={selectedDayChildrenCard}
            onChange={(value) => setSelectedDayChildrenCard(value)}
          />
        </Flex>
      }
      style={{ width: '100%', height: '100%' }}
    >
      <List
        bordered={false}
        loading={childrenCardLoading}
        dataSource={pickupTimesErrors}
        renderItem={({ child, error, referenceDay, color }) => (
          <List.Item>
            <List.Item.Meta
              title={lignContent(child, error, referenceDay, color)}
            />
          </List.Item>
        )}
      />
    </Card>
  );
};
