import { useEffect, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Form, Row, Spin, Collapse, message as Message } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { ContentCustom } from '../../../components/ContentCustom/ContentCustom';
import { useGenerateFormItem } from '../../../utils/generateFormItem';
import {
  formItemLayout,
  noLabelLayout
} from '../../../utils/constants/formLayout';

const { Panel } = Collapse;
const { success: successMessage } = Message;

/**
 * A dynamic form container with collapsible sections for creating or updating invoicing configuration for the selected daycare.
 * @component
 * @param {Object} props - The component's properties.
 * @param {Array<Object>} props.collapses - An array of collapsible sections for the form.
 * @param {string} [props.id] - The id of the daycare
 * @param {Function} [props.setRefresh] - A function to refresh the form.
 * @param {Function} [props.setIsSubmitting] - A function to set the submitting state.
 * @param {boolean} [props.isSubmitting] - Whether the form is submitting.
 * @param {Object} [props.data] - The data of the resource to update.
 * @param {boolean} [props.isLoading] - Whether the resource is loading.
 * @returns {JSX.Element} The rendered component.
 */
export const InvoicingCreateUpdateContainer = forwardRef(
  (
    {
      collapses,
      id,
      setRefresh,
      setIsSubmitting,
      isSubmitting,
      data,
      isLoading,
      form
    },
    ref
  ) => {
    const { t } = useTranslation();
    const { message } = useErrorMessage();
    const { dispatchAPI, setRefreshCompletedDaycare, configurationComplete } =
      useAuthContext();
    const generateFields = useGenerateFormItem();

    useEffect(() => {
      form.setFieldsValue(data);
    }, [data]);

    const updateResource = async (body) => {
      setIsSubmitting(true);

      try {
        await dispatchAPI('PATCH', {
          url: `/invoices/configuration/${id}`,
          body
        });
        setRefresh((prev) => !prev);
        successMessage(t('success.messages.configuration_updated'));
        if (!configurationComplete) {
          setRefreshCompletedDaycare((prev) => !prev);
        }
      } catch (e) {
        message(e);
      } finally {
        setIsSubmitting(false);
      }
    };

    return (
      <ContentCustom>
        <Spin spinning={isLoading}>
          <Form
            ref={ref}
            {...formItemLayout}
            onFinish={updateResource}
            form={form}
          >
            {collapses?.map(
              ({
                title,
                fields,
                secondPartFields,
                thirdPartFields,
                extra,
                extraSecondPart
              }) => (
                <Collapse key={title}>
                  <Panel header={title} key="1">
                    {fields.map((field) =>
                      generateFields('configurations.invoicing', field)
                    )}
                    {extra}
                    {secondPartFields?.map((field) =>
                      generateFields('configurations.invoicing', field)
                    )}
                    {extraSecondPart}
                    {thirdPartFields?.map((field) =>
                      generateFields('configurations.invoicing', field)
                    )}
                  </Panel>
                </Collapse>
              )
            )}
            <Form.Item {...noLabelLayout}>
              <Row justify="center">
                <Button type="primary" htmlType="submit" loading={isSubmitting}>
                  {t('buttons.save')}
                  <CheckOutlined />
                </Button>
              </Row>
            </Form.Item>
          </Form>
        </Spin>
      </ContentCustom>
    );
  }
);

InvoicingCreateUpdateContainer.propTypes = {
  collapses: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      fields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
      extra: PropTypes.element
    })
  ).isRequired,
  id: PropTypes.string,
  setRefresh: PropTypes.func,
  setIsSubmitting: PropTypes.func,
  isSubmitting: PropTypes.bool,
  data: PropTypes.shape({
    regular_extra_fees: PropTypes.shape({}),
    occasional_extra_fees: PropTypes.shape({})
  }),
  isLoading: PropTypes.bool,
  form: PropTypes.shape({
    setFieldsValue: PropTypes.func
  })
};

InvoicingCreateUpdateContainer.defaultProps = {
  id: null,
  isLoading: false,
  isSubmitting: false,
  data: {},
  setRefresh: () => {},
  setIsSubmitting: () => {},
  form: null
};
