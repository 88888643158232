import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Row, Col, Flex, Button } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { PageHeader } from '@ant-design/pro-components';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { ContentCustom } from '../../../components';
import { InformationsCard } from './components/InformationsCard/InformationsCard';
import { ContactsCard } from './components/ContactsCard/ContactsCard';
import { AdressesCard } from './components/AdressesCard/AdressesCard';
import { InvoicesCard } from './components/InvoicesCard/InvoicesCard';
import { AmountsCard } from './components/AmountsCard/AmountsCard';
import { PaymentsCard } from './components/PaymentsCard/PaymentsCard';
import CustomerAccountModal from './CustomerAccountModal';
import { routes, subRoutes } from '../../../utils/constants/adminRoutes';

/**
 * Component for displaying details of a customer account.
 *
 * @component
 *
 * @returns {JSX.Element} The JSX element representing the customer account details.
 */
export const ShowCustomerAccount = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { dispatchAPI, daycare } = useAuthContext();
  const { message } = useErrorMessage();
  const [customerAccount, setCustomerAccount] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [enums, setEnums] = useState({});
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModal = () => {
    setIsModalOpen((prev) => !prev);
  };

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/customer-accounts/enums'
      });
      setEnums(data);
    } catch (error) {
      message(error);
    }
  };

  const getData = useCallback(async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/customer-accounts/with-linked-children/${id}/${daycare}`
      });
      setCustomerAccount(data);
    } catch (error) {
      message(error);
    }
  }, [id]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      await getEnums();
      setIsLoading(false);
    })();
  }, [daycare]);

  useEffect(() => {
    (async () => {
      await getData();
    })();
  }, [getData, refresh]);

  return (
    <>
      <PageHeader
        title={t('customer-account.show.title', {
          label: customerAccount?.label
        })}
        onBack={() =>
          navigate(
            `${routes.INVOICING}${subRoutes.INVOICING['CUSTOMERS-ACCOUNTS']}`
          )
        }
        extra={
          <Button
            icon={<EditOutlined />}
            onClick={handleModal}
            disabled={isLoading}
            type="primary"
          >
            {t('buttons.edit')}
          </Button>
        }
      />
      <ContentCustom>
        <Flex gap="middle" vertical>
          <Row gutter={[16, 16]}>
            <Col xs={24} xl={12}>
              <InformationsCard data={customerAccount} />
            </Col>
            <Col xs={24} xl={12}>
              <AmountsCard amount={customerAccount?.amount || [{}]} />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col xs={24} xl={12}>
              <AdressesCard data={customerAccount} setRefresh={setRefresh} />
            </Col>
            <Col xs={24} xl={12}>
              <ContactsCard data={customerAccount} setRefresh={setRefresh} />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col xs={24} xl={12}>
              <InvoicesCard />
            </Col>
            <Col xs={24} xl={12}>
              <PaymentsCard id={id} />
            </Col>
          </Row>
        </Flex>
      </ContentCustom>
      {isModalOpen && (
        <CustomerAccountModal
          isModalOpen={isModalOpen}
          enums={enums}
          closeModal={handleModal}
          customerAccountId={id}
          setRefresh={setRefresh}
          purpose="edit"
        />
      )}
    </>
  );
};
