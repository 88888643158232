import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import { ListResource } from '../../../../components';
import { useColumns } from './columns';
import { routes } from '../../../../utils/constants/adminRoutes';

/**
 * A component to display a list of contracts associated with a child.
 *
 * @component
 *
 * @param {Object} props - The component's properties.
 * @param {Object} props.child - The child object with an _id property.
 * @returns {JSX.Element} The rendered ContractsList component.
 */
export const ContractsList = ({ child }) => {
  const [refresh, setRefresh] = useState(false);
  const navigate = useNavigate();
  const columns = useColumns(refresh, setRefresh, child?._id);
  const { id } = useParams();

  return (
    <ListResource
      resourceName="contracts"
      columns={columns}
      forceRefresh={refresh}
      setRefresh={setRefresh}
      resourceModelName="Contract"
      extraQuery={`children=${child?._id}`}
      withPageHeader={false}
      duplicateAction
      withCreateButton={{
        path: `${routes.CHILDREN}/contracts/create/${id}`
      }}
      onDoubleClickAction={{
        action: (record) =>
          navigate(`${routes.CHILDREN}/show/${id}/contracts/show/${record._id}`)
      }}
      customActionColumn
    />
  );
};
ContractsList.propTypes = {
  child: PropTypes.shape({
    _id: PropTypes.string
  })
};

ContractsList.defaultProps = {
  child: null
};
