import { useTranslation } from 'react-i18next';
import { Button, Row, Col } from 'antd';
import { useGeneralSettingsFields } from './Fields/generalSettingsFields';
import { useDefaultSettingsFields } from './Fields/defaultSettingsFields';
import { usePricesAndCalculMethodsFields } from './Fields/pricesAndCalculMethods';
import { useAdditionnalFees } from './Fields/additionnalFees';
import { useContractFeesFields } from './Fields/contractFeesFields';
import { useBankAccountFields } from './Fields/bankAccountFields';
import { AppliedEffortRateTable } from './Fields/Extras/AppliedEffortRate/AppliedEffortRateTable';
import { ExtraHoursTable } from './Fields/Extras/ExtraHours/ExtraHoursTable';

/**
 * The useCollapses hook.
 * @hook
 * @param {object} dataSource - The data source object containing the necessary data for the fields.
 * @param {function} setDataSource - Function to set the data source state.
 * @param {function} setIsSubmitting - Function to set the submitting state.
 * @param {function} setRefresh - Function to trigger a refresh.
 * @param {function} setIsLoading - Function to set the loading state.
 * @param {string} daycare - The daycare ID.
 * @param {object} enums - The enums object containing various enumeration values.
 * @param {object} form - The form instance for managing form state.
 * @param {string} arrayFilter - State of the array filter for rounding up rules.
 * @param {Function} setArrayFilter - Function to set the array filter state.
 * @param {function} openModal - Function to open a modal.
 *
 * @returns {Array} The collapses array, each object containing a title, fields, and optionally extra components.
 */
export const useCollapses = (
  dataSource,
  setDataSource,
  setIsSubmitting,
  setRefresh,
  setIsLoading,
  daycare,
  enums,
  form,
  arrayFilter,
  setArrayFilter,
  openModal
) => {
  const { t } = useTranslation();
  const generalSettingsFields = useGeneralSettingsFields(enums);
  const defaultSettingsFields = useDefaultSettingsFields(enums);
  const pricesAndCalculMethodsFields = usePricesAndCalculMethodsFields();
  const contractFeesFields = useContractFeesFields();
  const bankAccountFields = useBankAccountFields();
  const {
    additionnalFeesFields,
    secondPartAdditionnalFeesFields,
    thirdPartAdditionnalFeesFields
  } = useAdditionnalFees(
    enums,
    {
      regularExcess:
        dataSource?.bill_excess_on_regular_care_take_account_of_contract_rate,
      occasionalExcess:
        dataSource?.bill_excess_on_regular_care_take_account_of_contract_rate
    },
    setArrayFilter
  );

  return [
    {
      title: t('configurations.invoicing.general_settings'),
      fields: generalSettingsFields
    },
    {
      title: t('configurations.invoicing.default_settings'),
      fields: defaultSettingsFields
    },
    {
      title: t('configurations.invoicing.prices_calcul_methods'),
      fields: pricesAndCalculMethodsFields,
      extra: (
        <Row>
          <Col span={24}>
            <AppliedEffortRateTable
              dataSource={dataSource?.applied_effort_rates}
              id={daycare}
              setIsSubmitting={setIsSubmitting}
              setRefresh={setRefresh}
            />
          </Col>
          <Col span={24} style={{ textAlign: 'right', marginTop: '16px' }}>
            <Button type="primary" onClick={openModal}>
              {t(
                'configurations.invoicing.button.modify_prices_calcul_methods'
              )}
            </Button>
          </Col>
        </Row>
      )
    },
    {
      title: t('configurations.invoicing.contract_fees'),
      fields: contractFeesFields
    },
    {
      title: t('configurations.invoicing.additionnal_fees'),
      fields: additionnalFeesFields,
      secondPartFields: secondPartAdditionnalFeesFields,
      thirdPartFields: thirdPartAdditionnalFeesFields,
      extra: (
        <ExtraHoursTable
          dataSource={dataSource?.regular_extra_fees}
          setDataSource={setDataSource}
          id={daycare}
          setRefresh={setRefresh}
          setIsLoading={setIsLoading}
          setIsSubmitting={setIsSubmitting}
          target="regular_extra_fees"
          form={form}
          arrayFilter={arrayFilter}
        />
      ),
      extraSecondPart: (
        <ExtraHoursTable
          dataSource={dataSource?.occasional_extra_fees}
          setDataSource={setDataSource}
          id={daycare}
          setRefresh={setRefresh}
          setIsLoading={setIsLoading}
          setIsSubmitting={setIsSubmitting}
          target="occasional_extra_fees"
          form={form}
          arrayFilter={arrayFilter}
        />
      )
    },
    {
      title: t('configurations.invoicing.bank_accounts'),
      fields: bankAccountFields
    }
  ];
};
