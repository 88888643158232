import { useState, useEffect } from 'react';
import {
  Input,
  DatePicker,
  TimePicker,
  Select,
  Switch,
  Form,
  Space,
  Button,
  Radio
} from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { disabledTime } from '../../components/TimeRangeBar/utils/disabledTime';
import { createTimesLimits } from './createTimesLimit';
import {
  generateFilteredPrevisionalPlanning,
  generateRadioOptions,
  generateReservationAdaptationOptions
} from './utils';

const { TextArea } = Input;
const { Option } = Select;
const formatDate = 'DD/MM/YYYY';

/**
 * @hook useFields
 * @description useFields hook. Handles the fields for the EventModal component
 * @param {Object} child The child object
 * @param {Object} selectedDay The selected day
 * @param {String} purpose The purpose of the modal
 * @param {String} type The type of the event
 * @param {String} eventId The event id
 * @param {String} daycare The daycare id
 * @param {String} previsionalPlanning The previsional planning.
 * @param {Number} relatedEvent Related event when creating absences to create disabledTime values.
 * @param {Function} setRelatedEvent Function to set relatedEvent state.
 * @param {Object} form Form instance.
 * @returns {Object} The fields
 */
const useFields = (
  child,
  selectedDay,
  purpose,
  type,
  eventId,
  daycare,
  previsionalPlanning,
  relatedEvent,
  setRelatedEvent,
  form
) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [absencesReason, setAbsencesReason] = useState([]);
  const [reservationsReason, setReservationsReason] = useState([]);

  const filteredPrevisionalPlanning =
    generateFilteredPrevisionalPlanning(previsionalPlanning);

  const getAbsencesReason = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/occasional-reservation-reason'
      });
      setReservationsReason(data);
    } catch (e) {
      message(e);
    }
  };

  const getReservationsReason = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/absence-reason' });
      setAbsencesReason(data);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      await getAbsencesReason();
      await getReservationsReason();
      setIsFieldsLoading(false);
    })();
  }, []);

  const radioOptions = generateRadioOptions(t);
  const reservationAdaptationOption = generateReservationAdaptationOptions(t);

  const radioAbsenceOptions = (filteredPrevisionalPlanning || []).map(
    ({ start_time, end_time }, index) => ({
      label: `${dayjs.utc(start_time).format('HH:mm')} - ${dayjs
        .utc(end_time)
        .format('HH:mm')}`,
      value: index,
      key: index
    })
  );

  const { hourStart, hourEnd, minuteStart, minuteEnd } = createTimesLimits(
    type,
    filteredPrevisionalPlanning,
    relatedEvent
  );

  const handlePrevisionalPick = (value) => {
    setRelatedEvent(value);
    form.setFields([
      {
        name: 'times',
        value: []
      }
    ]);
  };

  const fields = [
    {
      name: ['type'],
      initialValue: type,
      hidden: true,
      rules: [{ required: true }]
    },
    {
      name: ['daycare'],
      initialValue: daycare,
      hidden: true,
      rules: [{ required: true }]
    },
    ...(purpose === 'edit' && purpose !== 'calendar'
      ? [
          {
            name: ['eventId'],
            initialValue: eventId,
            hidden: true,
            rules: [{ required: true }]
          }
        ]
      : []),
    {
      name: ['child'],
      initialValue: child?._id,
      hidden: true,
      rules: [{ required: true }]
    },
    {
      name: ['date'],
      input: (
        <DatePicker
          allowClear={false}
          defaultValue={selectedDay}
          format={formatDate}
          disabled={purpose === 'edit'}
        />
      )
    },
    ...(type === 'absences' && filteredPrevisionalPlanning.length > 0
      ? [
          {
            name: ['related_previsional'],
            rules: [{ required: true }],
            input: (
              <Radio.Group
                options={radioAbsenceOptions}
                onChange={(e) => handlePrevisionalPick(e.target.value)}
              />
            )
          }
        ]
      : []),
    ...(['pickup_times'].includes(type)
      ? [
          {
            name: ['pickUpTimes'],
            input: (
              <Form.List name="pickUpTimes">
                {(Fields, { add, remove }) => (
                  <>
                    {Fields.map(({ key, name, ...restField }) => (
                      <Space
                        key={key}
                        style={{ display: 'flex', marginBottom: 8 }}
                        align="baseline"
                      >
                        <Form.Item
                          {...restField}
                          name={[name, 'eventId']}
                          hidden
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item {...restField} name={[name, 'times']}>
                          <TimePicker.RangePicker
                            format="HH:mm"
                            disabledTime={() => disabledTime(6, 20)}
                          />
                        </Form.Item>
                        <MinusCircleOutlined onClick={() => remove(name)} />
                      </Space>
                    ))}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined />}
                        disabled={
                          Fields.length >=
                          (type === 'absences'
                            ? filteredPrevisionalPlanning.length
                            : 2)
                        }
                      >
                        Ajouter un créneau
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            )
          }
        ]
      : [
          {
            name: ['times'],
            rules: [{ required: true }],
            input: (
              <TimePicker.RangePicker
                format="HH:mm"
                disabled={
                  type === 'absences' &&
                  purpose === 'create' &&
                  relatedEvent === undefined
                }
                disabledTime={(current) =>
                  disabledTime(
                    hourStart,
                    hourEnd,
                    current && current[0] ? current[0].hour() : null,
                    current && current[1] ? current[1].hour() : null,
                    minuteStart,
                    minuteEnd,
                    filteredPrevisionalPlanning
                  )
                }
              />
            )
          }
        ]),
    ...(type === 'absences'
      ? [
          {
            name: ['reason'],
            rules: [{ required: true }],
            input: (
              <Select loading={isFieldsLoading}>
                {(absencesReason || []).map((reason) => (
                  <Option key={reason.name} value={reason._id}>
                    {t(`${reason.name}`)}
                  </Option>
                ))}
              </Select>
            )
          }
        ]
      : []),
    ...(type === 'reservations'
      ? [
          {
            name: ['reason'],
            rules: [{ required: true }],
            input: (
              <Select loading={isFieldsLoading}>
                {(reservationsReason || []).map((reason) => (
                  <Option key={reason.label} value={reason._id}>
                    {t(`${reason.label}`)}
                  </Option>
                ))}
              </Select>
            )
          }
        ]
      : []),
    ...(type !== 'pickup_times' && type !== 'reference_day'
      ? [
          {
            name: ['comment'],
            input: <TextArea rows={5} />
          }
        ]
      : []),
    ...(type === 'reservations' || type === 'adaptations'
      ? [
          {
            name: ['billing'],
            rules: [{ required: true }],
            input: (
              <Radio.Group>
                <Space direction="vertical">
                  {reservationAdaptationOption.map(({ label, value }) => (
                    <Radio value={value} key={value}>
                      {label}
                    </Radio>
                  ))}
                </Space>
              </Radio.Group>
            )
          }
        ]
      : []),
    ...(type === 'absences'
      ? [
          {
            name: ['absence_deduction'],
            rules: [{ required: true }],
            input: (
              <Radio.Group>
                <Space direction="vertical">
                  {radioOptions.map(({ label, value }) => (
                    <Radio value={value} key={value}>
                      {label}
                    </Radio>
                  ))}
                </Space>
              </Radio.Group>
            )
          },
          {
            name: ['take_vacation'],
            input: (
              <Switch checkedChildren={t('yes')} unCheckedChildren={t('no')} />
            )
          }
        ]
      : [])
  ];

  return {
    fields
  };
};

export default useFields;
