import {
  DeleteOutlined,
  EditOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { Button, Divider, Popconfirm } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';

const iconSize = 18;

/**
 * Columns for the datatable
 * @hook
 * @param {function} handleModalBehaviour - A function to handle modals.
 * @param {boolean} refresh - A boolean value indicating whether to force refresh the table.
 * @param {function} setRefresh - A function to set the forceRefresh value.
 * @param {string} resourceName - Name of the resource displayed.
 *
 * @returns {Array} columns for the datatable
 */
export const useColumns = (
  handleModalBehaviour,
  refresh,
  setRefresh,
  resourceName
) => {
  const { t } = useTranslation();
  const { dispatchAPI, daycare } = useAuthContext();
  const { message } = useErrorMessage();

  const deleteResource = async (id) => {
    try {
      await dispatchAPI('DELETE', {
        url: `/daycares/activities/${resourceName}/${daycare}/${id}`
      });
      setRefresh(!refresh);
    } catch (e) {
      message(e);
    }
  };

  const sortDirections = ['ascend', 'descend', 'ascend'];

  return [
    {
      title: t('daycares.form.label'),
      key: 'label',
      dataIndex: 'label',
      sortDirections,
      sorter: (a, b) => {
        if (a.label < b.label) {
          return 1;
        }
        if (a.label > b.label) {
          return -1;
        }
        return 0;
      }
    },
    {
      align: 'right',
      render: (record) => (
        <>
          <Button
            onClick={() => handleModalBehaviour(record, 'edit')}
            type="link"
            style={{ padding: 0 }}
          >
            <EditOutlined style={{ fontSize: iconSize }} />
          </Button>
          <Divider type="vertical" />
          <Popconfirm
            title={t('datatable.column.action.delete.title')}
            okText={t('datatable.column.action.delete.ok')}
            okButtonProps={{ type: 'danger' }}
            cancelText={t('datatable.column.action.delete.cancel')}
            onConfirm={() => deleteResource(record?._id)}
            icon={<WarningOutlined />}
          >
            <DeleteOutlined
              style={{ color: 'var(--errorColor', fontSize: iconSize }}
              type="delete"
            />
          </Popconfirm>
        </>
      )
    }
  ];
};
