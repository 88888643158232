import PropTypes from 'prop-types';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Form, Input, Button } from 'antd';

const EditableContext = React.createContext(null);
export const EditableRow = ({ ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

/**
 * EditableCell component for rendering editable table cells.
 *
 * @component
 *
 * @param {Object} props - Component props.
 * @param {string} props.title - The title of the cell.
 * @param {number} props.index - The index of the cell.
 * @param {boolean} props.editable - Indicates if the cell is editable.
 * @param {React.ReactNode} props.children - Child nodes.
 * @param {string} props.dataIndex - The key of the data item.
 * @param {Object} props.record - The data record for the row.
 * @param {boolean} [props.disabled=false] - Indicates if the cell is disabled.
 * @param {Function} props.setDataSource - Function to set the data source.
 * @param {Array} props.filteredData - The filtered data.
 * @param {string} props.target - The target key in the data source.
 * @param {Function} props.setRefreshFilter - Function to refresh the filter.
 * @param {Object} restProps - Additional props.
 *
 * @returns {JSX.Element} Editable cell component.
 */
export const EditableCell = ({
  title,
  index,
  editable,
  children,
  dataIndex,
  record,
  disabled,
  setDataSource,
  filteredData,
  target,
  setRefreshFilter,
  ...restProps
}) => {
  const inputRef = useRef(null);
  const { setFieldsValue } = useContext(EditableContext);
  const [editing, setEditing] = useState(false);
  const [inputValue, setInputValue] = useState(record?.[dataIndex]);
  let childNode = children;

  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);

    setFieldsValue({
      [dataIndex]: record[dataIndex]
    });
  };

  const save = () => {
    toggleEdit();
    setDataSource((prev) => {
      const updatedState = { ...prev };
      const dataSourceIndex = updatedState?.[target]?.findIndex(
        (el) => el.duration === filteredData[index].duration
      );
      const elementToUpdate = updatedState[target][dataSourceIndex];
      elementToUpdate[dataIndex] = parseFloat(inputValue); // Update with the captured input value
      return updatedState;
    });
    setRefreshFilter((prev) => !prev);
  };

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0
        }}
        name={dataIndex}
        rules={[{ required: true }]}
      >
        <Input
          type="number"
          ref={inputRef}
          onChange={(e) => setInputValue(e.target.value)}
          onPressEnter={save}
          onBlur={save}
          disabled={disabled}
          step="0.1"
        />
      </Form.Item>
    ) : (
      <Button
        type="text"
        className="editable-cell-value-wrap"
        onClick={toggleEdit}
      >
        {children}
      </Button>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

EditableCell.propTypes = {
  title: PropTypes.string.isRequired,
  editable: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  dataIndex: PropTypes.string.isRequired,
  record: PropTypes.shape({}).isRequired,
  handleSave: PropTypes.func.isRequired,
  index: PropTypes.number,
  setDataSource: PropTypes.func,
  filteredData: PropTypes.arrayOf(
    PropTypes.shape({ duration: PropTypes.number })
  ),
  target: PropTypes.string,
  setRefreshFilter: PropTypes.func
};

EditableCell.defaultProps = {
  disabled: false,
  index: undefined,
  setDataSource: null,
  filteredData: null,
  target: undefined,
  setRefreshFilter: null
};
