import { Button, Divider, Form, Row, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useParentsFields } from './fields';
import { useGenerateFormItem } from '../../../../utils/generateFormItem';
import { tailFormItemLayout } from '../../../../utils/constants/formLayout';
import { useAuthContext } from '../../../../contexts/AuthContext';

/**
 * CreateAddParentModalContainer is a component for creating or adding a parent to a child's profile.
 * It provides a form for entering parent information and authorizations.
 *
 * @component
 * @param {boolean} isSubmitting - A boolean indicating if the form is in the process of submission.
 * @param {function} setIsSubmitting - A function to set the submission state.
 * @param {function} closeModal - A function to handle the modal's visibility.
 * @returns {JSX.Element} The rendered component.
 * @example
 * // Usage example:
 * <CreateAddParentModalContainer
 *   isSubmitting={false}
 *   setIsSubmitting={setIsSubmittingFunction}
 *   handleModal={handleModalFunction}
 * />
 */
export const CreateAddParentModalContainer = ({
  isSubmitting,
  setIsSubmitting,
  closeModal,
  refresh,
  setRefresh
}) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { id } = useParams();
  const [form] = Form.useForm();
  const { isExistingParentFields, authorizationFields } = useParentsFields();
  const generateFields = useGenerateFormItem();

  const postParentInChild = async (body) => {
    setIsSubmitting(true);
    try {
      await dispatchAPI('PATCH', {
        url: `children/post-parent/existing/${id}`,
        body
      });
      setIsSubmitting(false);
      closeModal();
      setRefresh(!refresh);
    } catch (e) {
      setIsSubmitting(false);
      message.error(e);
    }
  };

  return (
    <Form form={form} onFinish={postParentInChild}>
      {isExistingParentFields.map((field) => generateFields('parents', field))}
      <Divider orientation="left">
        {t('children.tabsTitle.authorizations')}
      </Divider>
      {authorizationFields.map((field) => generateFields('parents', field))}
      <Form.Item {...tailFormItemLayout}>
        <Row justify="end">
          <Button
            style={{ margin: '0 10px' }}
            type="link"
            danger
            onClick={closeModal}
          >
            {`${t('buttons.cancel')} `}
            <CloseOutlined />
          </Button>
          <Button type="primary" htmlType="submit" loading={isSubmitting}>
            {`${t('buttons.save')} `}
            <CheckOutlined />
          </Button>
        </Row>
      </Form.Item>
    </Form>
  );
};

CreateAddParentModalContainer.propTypes = {
  closeModal: PropTypes.func,
  isSubmitting: PropTypes.bool,
  setIsSubmitting: PropTypes.func,
  refresh: PropTypes.bool,
  setRefresh: PropTypes.func
};

CreateAddParentModalContainer.defaultProps = {
  closeModal: null,
  isSubmitting: false,
  setIsSubmitting: null,
  refresh: false,
  setRefresh: null
};
