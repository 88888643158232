import dayjs from 'dayjs';

/**
 * Creates time limits based on the event type and reference day data.
 *
 * @function
 *
 * @param {string} type - The type of event (e.g., 'absences').
 * @param {Array} reference_day - An array containing reference day data.
 * @param {number} index - The index of the reference day in the array.
 * @returns {object} An object containing time limits for start and end hours and minutes.
 */
export const createTimesLimits = (type, reference_day, index = 0) => {
  let hourStart = 6;
  let hourEnd = 21;
  let minuteStart = 0;
  let minuteEnd = 60;

  const excludedTypes = [
    'absences',
    'regular_absences',
    'irregular_absences',
    'catch_up_days',
    'holidays'
  ];

  if (
    !reference_day ||
    !excludedTypes.includes(type) ||
    reference_day.length === 0 ||
    !reference_day[index]
  ) {
    return {
      hourStart: Number(hourStart),
      hourEnd: Number(hourEnd),
      minuteStart: Number(minuteStart),
      minuteEnd: Number(minuteEnd)
    };
  }

  if (reference_day[index] && Object.keys(reference_day[index]).length >= 2) {
    hourStart = dayjs.utc(reference_day[index].start_time).hour();
    hourEnd = dayjs.utc(reference_day[index].end_time).hour();
    minuteStart = dayjs.utc(reference_day[index].start_time).minute();
    minuteEnd = dayjs.utc(reference_day[index].end_time).minute();
  }

  return {
    hourStart: Number(hourStart),
    hourEnd: Number(hourEnd),
    minuteStart: Number(minuteStart),
    minuteEnd: minuteEnd === 0 ? 59 : Number(minuteEnd)
  };
};
