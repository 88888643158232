import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { WeekSwitcher } from '../../children/Show/GeneralInfos/WeekSwitcher';
import { Week } from '../../children/Show/GeneralInfos/Week';
import { HoursBar } from '../../../components/HoursBar/HoursBar';
import { planningTypeFiltersArray } from '../../children/Show/GeneralInfos/utils';
import { CustomSpin } from '../../../components/CustomSpin/CustomSpin';

/**
 * ParentPlanning Component
 *
 * This component displays the planning for a parent, including week view and event details.
 *
 * @component
 * @param {Object} props - The component's props.
 * @param {Object} props.child - The child for whom the planning is displayed.
 * @param {function} props.setIsPlanningLoading - A function to set the loading state of the planning.
 * @param {boolean} props.isPlanningLoading - A boolean indicating whether the planning is in a loading state.
 * @returns {JSX.Element} The rendered ParentPlanning component.
 */
export const ParentPlanning = ({
  child,
  setIsPlanningLoading,
  isPlanningLoading
}) => {
  const { t } = useTranslation();
  const { dispatchAPI, daycare } = useAuthContext();
  const { message } = useErrorMessage();
  const [events, setEvents] = useState([]);
  const [selectedDay, setSelectedDay] = useState(
    dayjs().add(2, 'hour').toISOString()
  );

  const eventTypes = planningTypeFiltersArray(t).map((filter) => filter.value);

  const getChildEvents = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `time-recorder/child-events/${child._id}?daycare=${daycare}&status=ACTIVE&archived=false&date=${selectedDay}&populate=contracts&planning_type=${eventTypes}&planning_mode=week`
      });
      setEvents(data);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    if (child) {
      (async () => {
        await getChildEvents();
        setIsPlanningLoading(false);
      })();
    }
  }, [child, selectedDay]);

  return (
    <>
      <WeekSwitcher selectedDay={selectedDay} setSelectedDay={setSelectedDay} />
      <Row>
        <Col span={6} />
        <Col span={18}>
          <table>
            <HoursBar />
          </table>
        </Col>
      </Row>
      {isPlanningLoading ? (
        <CustomSpin />
      ) : (
        <>
          <Row style={{ marginTop: 10 }} />
          {(events.eventsResult || []).map((eventResult) => (
            <Week
              key={eventResult[0]?._id}
              events={eventResult}
              isParentView
              openModal={null}
              deleteEvent={null}
              planningMode="week"
            />
          ))}
        </>
      )}
    </>
  );
};

ParentPlanning.propTypes = {
  child: PropTypes.shape({
    _id: PropTypes.string
  }),
  setIsPlanningLoading: PropTypes.func,
  isPlanningLoading: PropTypes.bool
};

ParentPlanning.defaultProps = {
  child: null,
  setIsPlanningLoading: null,
  isPlanningLoading: false
};
