import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Tabs, Space, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { EditOutlined } from '@ant-design/icons';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { ContractsList } from './Contracts/ContractsList';
import { useHealthListContent } from './Health/listContent';
import { useDietListContent } from './Diet/listContent';
import { CreateUpdateAuthorization } from './Authorizations/CreateUpdateAuthorization';
import { ContentCustom, PageHeaderCustom } from '../../../components';
import { DocumentsContainer } from './Documents/DocumentsContainer';
import infosIcon from '../../../assets/images/icons/infosIcon.svg';
import timeLineIcon from '../../../assets/images/icons/timeLineIcon.svg';
import parentsIcon from '../../../assets/images/icons/noto_person-feeding-baby-medium-skin-tone.svg';
import healthIcon from '../../../assets/images/icons/healthIcon.svg';
import contractsIcon from '../../../assets/images/icons/contractsIcon.svg';
import dietIcon from '../../../assets/images/icons/dietIcon.svg';
import documentsIcon from '../../../assets/images/icons/documentsIcon.svg';
import authorizationsIcon from '../../../assets/images/icons/authorizationsIcon.svg';
import { DietTab } from './Diet/DietTab';
import { ParentsTab } from './Parents/ParentsTab';
import { HealthTab } from './Health/HealthTab';
import { formattedVaccines } from '../utils/formatVaccinesDate';
import { GeneralInfosTab } from './GeneralInfos/GeneralInfosTab';
import { EditChildModal } from '../EditChildModal';
import { FollowUp } from './Follow-up/FollowUp';

/**
 * Component that displays detailed information about a child.
 *
 * Fetches and showcases various aspects about a child's profile such as their general information, parents,
 * contracts, health, diet, authorizations, and documents.
 *
 * @component
 * @returns {JSX.Element} Returns the main view for the child's details.
 */
export const ShowChild = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [child, setChild] = useState({});
  const { personalized_care, note } = useHealthListContent(child?.health || {});
  const { eatingHabits } = useDietListContent(child?.diet || {});
  const [refresh, setRefresh] = useState();
  const [modal, setModal] = useState();
  const [activeKey, setActiveKey] = useState('1');

  const onChangeActiveTab = (key) => {
    setActiveKey(key);
  };

  const handleModalBehaviour = (record) => {
    const modalKey = Date.now();
    setModal(
      <EditChildModal
        key={modalKey}
        forceRefresh={refresh}
        setForceRefresh={setRefresh}
        record={record}
      />
    );
  };

  const getChild = async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/children/${id}?populate=parents.parent,contracts,diet,authorizations,documents.file,group,diet.allergies,siblings.group`
      });
      const formattedVaccinesData = formattedVaccines(data);
      const formattedData = {
        ...data,
        health: {
          ...data.health,
          vaccines: formattedVaccinesData
        }
      };
      setChild(formattedData);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    (async () => {
      await getChild();
    })();
  }, [refresh, id]);

  const activeContract = (child.contracts || []).find(
    (contract) => contract.status === 'ACTIVE'
  );

  const tabContent = () => [
    {
      key: '1',
      label: (
        <Space>
          <img src={infosIcon} alt="infos-icon" />
          {t('children.tabsTitle.mainInfo')}
        </Space>
      ),
      children: (
        <GeneralInfosTab
          child={child}
          refresh={refresh}
          setRefresh={setRefresh}
          isLoading={isLoading}
        />
      )
    },
    {
      key: '2',
      label: (
        <Space>
          <img src={timeLineIcon} alt="timeLine-icon" />
          {t('children.tabsTitle.timeLine')}
        </Space>
      ),
      children: <FollowUp child={child} />
    },
    {
      key: '3',
      label: (
        <Space>
          <img src={parentsIcon} alt="parents-icon" />
          {t('children.tabsTitle.parents')}
        </Space>
      ),
      children: (
        <ParentsTab child={child} refresh={refresh} setRefresh={setRefresh} />
      )
    },
    {
      key: '4',
      label: (
        <Space>
          <img src={contractsIcon} alt="contracts-icon" />
          {t('children.tabsTitle.contract')}
        </Space>
      ),
      children: <ContractsList child={child} setActiveKey={setActiveKey} />
    },
    {
      key: '5',
      label: (
        <Space>
          <img src={healthIcon} alt="health-icon" />
          {t('children.tabsTitle.health')}
        </Space>
      ),
      children: (
        <HealthTab
          isLoading={isLoading}
          childName={`${child?.first_name} ${child?.last_name}`}
          health={child?.health}
          personalized_care={personalized_care}
          note={note}
          refresh={refresh}
          setRefresh={setRefresh}
        />
      )
    },
    {
      key: '6',
      label: (
        <Space>
          <img src={dietIcon} alt="diet-icon" />
          {t('children.tabsTitle.diet')}
        </Space>
      ),
      children: (
        <DietTab
          isLoading={isLoading}
          childName={`${child?.first_name} ${child?.last_name}`}
          diet={child?.diet}
          eatingHabits={eatingHabits}
          refresh={refresh}
          setRefresh={setRefresh}
        />
      )
    },
    {
      key: '7',
      label: (
        <Space>
          <img src={authorizationsIcon} alt="authorizations-icon" />
          {t('children.tabsTitle.authorizations')}
        </Space>
      ),
      children: (
        <CreateUpdateAuthorization
          auth_data={child?.authorizations}
          refresh={refresh}
          setRefresh={setRefresh}
        />
      )
    },
    {
      key: '8',
      label: (
        <Space>
          <img src={documentsIcon} alt="documents-icon" />
          {t('children.tabsTitle.documents')}
        </Space>
      ),
      children: (
        <DocumentsContainer
          childName={`${child.first_name} ${child.last_name}`}
          documents={child?.documents}
          refresh={refresh}
          setRefresh={setRefresh}
          RIB={!!activeContract?.rib}
          contractsInfos={{
            start_date: activeContract?.start_date,
            end_date: activeContract?.end_date
          }}
        />
      )
    }
  ];

  return (
    <>
      <PageHeaderCustom
        withoutBreadCrums
        title={`${child?.first_name} ${child?.last_name}`}
        extra={
          <Button onClick={() => handleModalBehaviour(child)} type="primary">
            <EditOutlined />
            {t('buttons.edit')}
          </Button>
        }
      />
      <ContentCustom>
        <Tabs
          defaultActiveKey={activeKey}
          items={tabContent()}
          type="card"
          onChange={onChangeActiveTab}
        />
      </ContentCustom>
      {modal}
    </>
  );
};
