import { useTranslation } from 'react-i18next';
import { Button, Flex, Typography } from 'antd';
import GuadeloupeIcon from '../../../assets/images/icons/Guadeloupe.png';
import GuyaneIcon from '../../../assets/images/icons/Guyane.png';
import { usePreregistrationContext } from '../../../contexts/PreregistrationContext';

const { Title } = Typography;

/**
 * Component for selecting a department in a pre-registration form.
 *
 * @component
 *
 * @returns {JSX.Element} The DepartmentForm component.
 */
export const DepartmentForm = () => {
  const { t } = useTranslation();
  const { setDepartmentFilter, setSwitchToForm } = usePreregistrationContext();

  const handleChoice = (value) => {
    setDepartmentFilter(value);
    setSwitchToForm(true);
  };

  return (
    <Flex vertical align="center" gap="large">
      <Title className="prereg-title">{t('pre-registrations.title')}</Title>
      <Title className="prereg-choose-location">
        {t('pre-registrations.form.select_department')}
      </Title>
      <Flex gap="large">
        <Button type="link" onClick={() => handleChoice('Guadeloupe')}>
          <img src={GuadeloupeIcon} alt="gwadada-icon" />
        </Button>
        <Button type="link" onClick={() => handleChoice('Guyane')}>
          <img src={GuyaneIcon} alt="guyane-icon" />
        </Button>
      </Flex>
    </Flex>
  );
};
