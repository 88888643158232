import dayjs from 'dayjs';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';

/**
 * Custom hook for handling signatures methods.
 *
 * @hook
 * @returns {Object} An object containing the methods for handling signatures.
 */
export const useSignaturesMethods = () => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();

  /**
   * Retrieves a file from the server.
   * It is a POST method because the endpoint is a without token endpoint.
   *
   * @function
   * @async
   * @param {Object} file - The file object.
   * @returns {Promise<Object|null>} A promise that resolves to the file object with the URL property, or null if an error occurs.
   */
  const getFile = async (file) => {
    try {
      const { data } = await dispatchAPI('POST', {
        url: '/files/get-file-without-token',
        body: { id: file._id },
        responseType: 'blob'
      });

      const blob = new Blob([data], { type: 'application/pdf' });

      const url = window.URL.createObjectURL(blob);

      return { ...file, url };
    } catch (error) {
      message(error);
    }
    return null;
  };

  /**
   * Retrieves the documents associated with a contract.
   * It is a POST method because the endpoint is a without token endpoint.
   *
   * @function
   * @async
   * @param {string} token - The token of the contract.
   * @returns {Promise<Object>} A promise that resolves to an object containing the files, the contract number and the start date, or an error object if an error occurs.
   */
  const getDocuments = async (token) => {
    try {
      const { data } = await dispatchAPI('POST', {
        url: '/contracts/signatures/get-contract',
        body: { token }
      });

      const promises = data.signatures.map(({ file }) => getFile(file));

      const files = await Promise.all(promises);

      if (!files.length) {
        return { error: true };
      }

      return {
        files,
        contract_number: data?.number,
        start_date: dayjs(data?.start_date).format('DD/MM/YYYY'),
        status: data?.status
      };
    } catch (error) {
      return { error: true };
    }
  };

  /**
   * Posts the signatures for a contract.
   *
   * @function
   * @async
   * @param {string} token - The token of the contract.
   * @param {Array<Object>} files - An array of file objects containing the files data and the signatures base64 for each file.
   * @returns {Promise<boolean>} A promise that resolves to true if the signatures are successfully posted, or false if an error occurs.
   */
  const postSignatures = async (token, files) => {
    try {
      await dispatchAPI('POST', {
        url: '/contracts/signatures',
        body: { token, files }
      });

      return true;
    } catch (error) {
      message(error);
      return false;
    }
  };

  return { getDocuments, postSignatures };
};
