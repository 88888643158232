import PropTypes from 'prop-types';
import { useState } from 'react';
import { Form, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { CreateUpdateContainerModal } from '../../../components/CreateUpdateContainer/CreateUpdateContainerModal';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { useDocumentFields } from './documentFields';
import { useVaccinesFields } from './vaccinesFields';

/**
 * Display a modal to create or update a closure time.
 * @component
 * @param {string} purpose The purpose of the modal.
 * @param {Object} record The record to update.
 * @param {function} setRefresh The function to refresh the table.
 * @param {string} resourceName The name of the resource. (documents or vaccines)
 * @returns {JSX.Element} The rendered component.
 */
export const CreateUpdateDynamicList = ({
  purpose,
  record,
  setRefresh,
  resourceName
}) => {
  const { t } = useTranslation();
  const { dispatchAPI, daycare } = useAuthContext();
  const { message } = useErrorMessage();
  const { fields, isFieldsLoading } = useDocumentFields(purpose);
  const { vaccinesFields } = useVaccinesFields();
  const [form] = Form.useForm();
  const [isModalOpenedLocaly, setIsModalOpenedLocaly] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const config = {
    onGetResource: {
      setFields: (data) => ({
        ...data
      })
    },
    onGetResourceFromModal: {
      setFields: (data) => ({
        ...data
      })
    },
    onUpdateResource: {
      setBody: (data) => ({
        ...data
      })
    }
  };

  const customSubmit = async (_, body) => {
    try {
      setIsSubmitting(true);

      if (purpose === 'edit') {
        await dispatchAPI('PATCH', {
          url: `/daycares/dynamic_list/${daycare}/${record._id}/${resourceName}`,
          body
        });
      } else {
        await dispatchAPI('PATCH', {
          url: `/daycares/dynamic_list/${daycare}/${resourceName}`,
          body
        });
      }
      setIsModalOpenedLocaly(false);
      form.resetFields();
      setRefresh((prev) => !prev);
    } catch (error) {
      message(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal
      title={t(
        `configurations.titles.${
          purpose === 'edit' ? 'edit' : 'add'
        }_${resourceName}`
      )}
      open={isModalOpenedLocaly}
      onCancel={() => {
        setIsModalOpenedLocaly(false);
      }}
      footer={null}
      width={800}
    >
      <CreateUpdateContainerModal
        fields={resourceName === 'documents' ? fields : vaccinesFields}
        loadingFields={isFieldsLoading}
        purpose="edit"
        resource="daycares"
        config={config}
        customFormInstance={form}
        customSubmit={customSubmit}
        isCustomSubmitting={isSubmitting}
        withoutPageHeaderCustom
        closeModal={() => setIsModalOpenedLocaly(false)}
        record={record}
        withGetResource={false}
      />
    </Modal>
  );
};

CreateUpdateDynamicList.propTypes = {
  purpose: PropTypes.string,
  record: PropTypes.shape({
    _id: PropTypes.string.isRequired
  }),
  setRefresh: PropTypes.func,
  resourceName: PropTypes.string.isRequired
};

CreateUpdateDynamicList.defaultProps = {
  purpose: '',
  record: null,
  setRefresh: () => {}
};
