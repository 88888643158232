import {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback
} from 'react';
import { useAuthContext } from './AuthContext';
import { useSocketContext } from './SocketContext';
import { useErrorMessage } from '../utils/errorMessage';

const ConversationContext = createContext();

/**
 * @description Conversation context provider
 * @param {Object} props Props
 * @param {React.ReactNode} props.children Children
 * @returns {React.ReactNode} Conversation context provider
 */
export const ConversationContextProvider = ({ children }) => {
  const { user, token, daycare, dispatchAPI } = useAuthContext();
  const { socket } = useSocketContext();
  const { message } = useErrorMessage();
  const [conversations, setConversations] = useState([]);
  const [selectedConversation, setSelectedConversation] = useState({});
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState('all');
  const [refreshConversation, setRefreshConversation] = useState(false);

  const getConversations = useCallback(async () => {
    try {
      const { _id } = user;
      const limit = conversations.length + 10;

      let url = `/conversations?daycare=${daycare}&users=${_id}&limit=${limit}&sort=-last_message_date`;

      if (filter === 'parents') {
        url += '&category=PARENTS';
      } else if (filter === 'employees') {
        url += '&category=EMPLOYEES';
      }

      const { data } = await dispatchAPI('GET', { url });

      setConversations(data);
      if (user.role !== 'guests:PARENT') {
        setSelectedConversation(data[0] || {});
      }
    } catch (error) {
      message(error);
    } finally {
      setLoading(false);
    }
  }, [refreshConversation]);

  useEffect(() => {
    if (socket && token) {
      socket.on('newMessage', async () => {
        await getConversations();
      });
    }
  }, [socket, token]);

  useEffect(() => {
    (async () => {
      await getConversations();
    })();
  }, [daycare, filter]);

  const patchToReadConversation = async (id) => {
    try {
      await dispatchAPI('PATCH', {
        url: `conversations/unread/${id}`
      });
      setRefreshConversation(!refreshConversation);
    } catch (e) {
      message(e);
    }
  };

  return (
    <ConversationContext.Provider
      value={{
        conversations,
        setConversations,
        selectedConversation,
        setSelectedConversation,
        loading,
        setLoading,
        getConversations,
        filter,
        setFilter,
        refreshConversation,
        setRefreshConversation,
        patchToReadConversation
      }}
    >
      {children}
    </ConversationContext.Provider>
  );
};

export const useConversationContext = () => {
  const context = useContext(ConversationContext);
  if (!context) {
    throw new Error(
      'useConversationContext must be used within a ConversationProvider'
    );
  }
  return context;
};
