import { Table } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useColumns } from './columns';

/**
 * Renders a table displaying results, both real and previsional, using the Ant Design `Table` component.
 * It is designed to show a list of data, with columns for first name, real results, and previsional results.
 *
 * @component
 * This table component is used within the analysis context to display various results. It is flexible enough to be used
 * in different scenarios where a tabular display of data is needed.
 *
 * @param {Object} props - Component properties.
 * @param {Array} props.datas - Array of data objects to be displayed in the table. Each object represents a row in the table.
 * @returns {JSX.Element} A table component displaying the specified data.
 */
export const ResultsTable = ({ datas }) => {
  const { t } = useTranslation();
  const columns = useColumns(t);

  return (
    <Table dataSource={datas || []} columns={columns} scroll={{ x: 1000 }} />
  );
};

ResultsTable.propTypes = {
  datas: PropTypes.arrayOf(PropTypes.shape({}))
};

ResultsTable.defaultProps = {
  datas: null
};
