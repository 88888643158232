import React, { useState, createContext, useContext, useEffect } from 'react';
import dayjs from 'dayjs';
import { useAuthContext } from './AuthContext';
import { useErrorMessage } from '../utils/errorMessage';

export const FollowUpContext = createContext();

/**
 * Provides a context for managing and accessing follow-up activities, groups, and related data.
 *
 * This context provider fetches and provides state management for activities, groups,
 * children, and other related data for a specific daycare. It manages state such as
 * activities, groups, date selection, and more. It also handles API calls to fetch
 * and update this data.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {React.ReactNode} props.children - The child components that can access the context.
 * @returns {JSX.Element} The JSX rendering of the context provider.
 *
 * @example
 * return (
 *   <FollowUpContextProvider>
 *     {children}
 *   </FollowUpContextProvider>
 * )
 */
export const FollowUpContextProvider = ({ children }) => {
  const { dispatchAPI, daycare, user } = useAuthContext();
  const { message } = useErrorMessage();
  const [activities, setActivities] = useState([]);
  const [groups, setGroups] = useState([]);
  const [refresh, setRefresh] = useState();
  const [date, setDate] = useState(dayjs().format('YYYY-MM-DD'));
  const [activityConfiguration, setActivityConfiguration] = useState();
  const [daysChildren, setDaysChildren] = useState([]);
  const [group, setGroup] = useState();
  const [child, setChild] = useState({});
  const [origin, setOrigin] = useState('group');
  const [subActivities, setSubActivities] = useState([]);

  const getActivitiesConfiguration = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `daycare/${daycare}?select=activities&populate=activities`
      });
      setActivityConfiguration(data.activities);
    } catch (e) {
      message(e);
    }
  };

  const getActivitiesByDay = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `follow-ups?date=${date}&daycare=${daycare}&group=${group}`
      });
      setActivities(data);
    } catch (e) {
      message(e);
    }
  };

  const getChildrenGroups = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: 'children-groups'
      });
      setGroup(data[0]._id);
      setGroups(data);
    } catch (e) {
      message();
    }
  };

  const getDaysChildren = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `follow-ups/children?date=${date}&daycare=${daycare}`
      });
      setDaysChildren(data);
      if (user.role === 'guests:PARENT') {
        setChild(data[0]);
      }
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      await getChildrenGroups();
      await getActivitiesConfiguration();
      await getDaysChildren();
    })();
  }, [date]);

  useEffect(() => {
    (async () => {
      await getActivitiesByDay();
    })();
  }, [refresh, group, date]);

  return (
    <FollowUpContext.Provider
      value={{
        activities,
        groups,
        date,
        setDate,
        child,
        setChild,
        refresh,
        setRefresh,
        daysChildren,
        activityConfiguration,
        group,
        setGroup,
        subActivities,
        setSubActivities,
        origin,
        setOrigin
      }}
    >
      {children}
    </FollowUpContext.Provider>
  );
};

/**
 * A custom hook for accessing the FollowUpContext.
 *
 * This hook allows components to access and interact with the state managed by
 * FollowUpContextProvider, such as activities, groups, date selection, and more.
 * It should be used within components that are children of FollowUpContextProvider.
 *
 * @function
 * @returns {Object} The context values for managing follow-up activities and related state.
 * @throws {Error} Throws an error if used outside of FollowUpContextProvider.
 *
 * @example
 * const { activities, setDate } = useFollowUpContext();
 */
export const useFollowUpContext = () => {
  const context = useContext(FollowUpContext);
  if (context === undefined)
    throw new Error('Context must be used within a context provider');
  return context;
};
